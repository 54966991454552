import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import PrivacyPolicy from "../../Pages/Desktop/CmsPage/PrivacyPolicy";
import MPrivacyPolicy from "../../Pages/Mobile/CmsPagesMobile/MPrivacyPolicy";

const PrivacyPolicyContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MPrivacyPolicy /> : <PrivacyPolicy />}</>;
};

export default PrivacyPolicyContainer;
