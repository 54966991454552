import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import "./MHomepage.scss";

function MHomepage() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
      slidesToSlide: 1,
    },
  };
  const testimonialResponsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 768 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 767, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const awards = [
    {
      img_url: "./images/award_img1.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by ABP News BFSI
          Awards in 2018.
        </>
      ),
    },
    {
      img_url: "./images/award_img2.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> at Golden Star
          Awards in 2018.
        </>
      ),
    },
    {
      img_url: "./images/award_img3.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by ABP News BFSI
          Awards in 2015.
        </>
      ),
    },
    {
      img_url: "./images/award_img4.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by IPE BFSI Awards
          in 2013.
        </>
      ),
    },
  ];

  const testimonials = [
    {
      review_class_type: "excellent",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
    {
      review_class_type: "good",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
    {
      review_class_type: "happy",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
    {
      review_class_type: "good",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
    {
      review_class_type: "excellent",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
    {
      review_class_type: "superb",
      reviewer_img: "./images/avatar.jpg",
      reviewer_review:
        'Always available, extremely knowledgeable, and a tremendous "can-do" attitude. Our company was on a tight timeline',
    },
  ];

  const slides = Math.ceil(testimonials.length / 2);

  // Divide testimonials into groups of two for each slide
  const groupedTestimonials = Array.from({ length: slides }, (_, index) =>
    testimonials.slice(index * 2, index * 2 + 2)
  );

  return (
    <Box className="homePageWrapper_m">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3} className="header">
        <Grid xs={12}>
          <h1>
            The secret of getting ahead<span>is getting started.</span>
          </h1>
        </Grid>
        <Grid xs={6} className="pe-6">
          <>
            <Grid container spacing={3} className="px-0 py-0">
              <Grid xs={12} className="px-0 py-0">
                <Link
                  className="product_outer"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                  }}
                >
                  <div className="product_sec health">
                    <h5>
                      Health <br /> Insurance
                    </h5>
                  </div>
                </Link>
              </Grid>
              <Grid xs={12} className="px-0 pb-0">
                <Link
                  className="product_outer"
                  onClick={() => {
                    window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                  }}
                >
                  <div className="product_sec term">
                    <h5>
                      Term <br /> Insurance
                    </h5>
                  </div>
                </Link>
              </Grid>
            </Grid>
          </>
        </Grid>
        <Grid xs={6} className="py-0 ps-6">
          <Link
            className="product_outer"
            onClick={() => {
              window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
            }}
          >
            <div className="car_product_sec">
              <h5>
                Car <br /> Insurance
              </h5>
            </div>
          </Link>
        </Grid>
        <Grid xs={12}>
          <Link
            className="product_outer"
            onClick={() => {
              window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
            }}
          >
            <div className="bike_product_sec">
              <h5>
                Bike <br /> Insurance
              </h5>
            </div>
          </Link>
        </Grid>
        <Grid xs={6} className="py-0 pe-6">
          <Link className="product_outer disabled" onClick={() => {}}>
            <div className="product_sec investment">
              <h5>
                Investment <br /> Plan
              </h5>
            </div>
          </Link>
        </Grid>
        <Grid xs={6} className="py-0 ps-6">
          <Link className="product_outer disabled" onClick={() => {}}>
            <div className="product_sec travel">
              <h5 className="font-color">
                Travel <br /> Insurance
              </h5>
            </div>
          </Link>
        </Grid>
      </Grid>

      {/* factsheet sction */}
      <Grid container spacing={3} className="factsheet_sec">
        <Grid xs={12} className="mb-2 text-center">
          <h2>Fact Sheet</h2>
          <p>Customer Delight, Every Time.</p>
        </Grid>
        <Grid xs={6} className="pe-6">
          <div className="counter_sec">
            <h4>
              <span className="counter">24</span>+
            </h4>
            <p className="mb-0">Years of Experience</p>
          </div>
        </Grid>
        <Grid xs={6} className="ps-6">
          <div className="counter_sec">
            <h4>
              <span className="counter">19000</span>+
            </h4>
            <p className="mb-0">Satisfied Clients</p>
          </div>
        </Grid>
        <Grid xs={6} className="pe-6">
          <div className="counter_sec">
            <h4>
              <span className="counter">11</span>+
            </h4>
            <p className="mb-0">Presence across cities</p>
          </div>
        </Grid>
        <Grid xs={6} className="ps-6">
          <div className="counter_sec">
            <h4>
              <span className="counter">10</span>Bn+
            </h4>
            <p className="mb-0">Premium Handling (Inr Crs).</p>
          </div>
        </Grid>
      </Grid>

      {/* featurs section */}
      <Grid container spacing={3} className="feature_sec">
        <Grid xs={12}>
          <h2>
            It's not just what we do, it's how we do it that sets us apart.
          </h2>
        </Grid>
        <Grid xs={12} className="mb-6">
          <div className="divider"></div>
        </Grid>
        <Grid xs={12} className="text-center mb-6">
          <img
            src="./images/feature_sec_img_mobile.svg"
            alt=""
            className="img-fluid"
          ></img>
        </Grid>
        <Grid xs={6} className="text-center pe-6 mb-6">
          <div className="feature_desc">
            <img src="./images/customer_img_mobile.svg" alt="img" height="46" />
            <h5>Customer Satisfaction</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </p>
          </div>
        </Grid>
        <Grid xs={6} className="text-center ps-6 mb-6">
          <div className="feature_desc">
            <img
              src="./images/compare_plans_img_mobile.svg"
              alt=""
              height="46"
            ></img>
            <h5>Compare Plans</h5>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor
            </p>
          </div>
        </Grid>
        <Grid xs={12} className="text-center">
          <div className="feature_desc new">
            <div className="inner">
              <img
                src="./images/claim_assistance_img_mobile.svg"
                alt=""
                height="46"
              ></img>
              <h5>24/7 Claim Assistance</h5>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor
              </p>
            </div>
          </div>
        </Grid>
      </Grid>

      {/*  awards section */}
      <div className="awards_sec">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <h3>Awards & Recognition</h3>
            <p>
              UNISON was started with the belief and conviction, that there are
              always better options available in the market and the client
              should be aware of the same. UNISON has been working to create the
              right conditions for these changes to take place, right from
              inception of the firm till date.
            </p>
          </Grid>
          <Grid xs={12}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              arrows={false}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
            >
              {awards.map((data, index) => (
                <div key={index}>
                  <Grid container columnSpacing={0}>
                    <Grid xs={12}>
                      <div className="choose-sec">
                        <img src={data.img_url} alt="" />
                        <p>{data.award_desc}</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </div>

      {/* customer review section */}
      <Grid container spacing={3} className="customer_review">
        <Grid xs={12} className="top-heading">
          <h2>What customer say about us?</h2>
        </Grid>
        <Grid xs={12} padding={"0"}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            responsive={testimonialResponsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            {groupedTestimonials.map((testimonialsGroup, index) => (
              <div key={index}>
                <Grid container columnSpacing={0}>
                  <Grid xs={12} padding="12px">
                    {testimonialsGroup.map((data, innerIndex) => (
                      <div
                        key={innerIndex}
                        className={`${data.review_class_type} textismonial-sec`}
                      >
                        <span>
                          <img src={data.reviewer_img} alt="" />
                        </span>
                        <p>{data.reviewer_review}</p>
                      </div>
                    ))}
                  </Grid>
                </Grid>
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>

      {/* our partner section */}
      <Grid container spacing={3} className="our-partners-section">
        <Grid xs={12} className="our_partners_inner">
          <h2>Our Partners</h2>
        </Grid>
        <Grid xs={12} padding={"0"}>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            arrows={false}
            responsive={testimonialResponsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            {testimonials.map((data, index) => (
              <div key={index}>
                <Grid container columnSpacing={0}>
                  <Grid xs={12} padding="12px">
                    <ul className="partnersLogo">
                      <li>
                        <img
                          src="images/insurerlogo/Reliance-General-Insurance-Co-Ltd.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/the_new_india_insurance_co_ltd.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/aditya_birla_capital.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg"
                          alt="insurer"
                        />
                      </li>
                      <li>
                        <img
                          src="images/insurerlogo/Max-Bupa-Health-Insurance-Company-Ltd.svg"
                          alt="insurer"
                        />
                      </li>
                    </ul>
                  </Grid>
                </Grid>
              </div>
            ))}
          </Carousel>
        </Grid>
      </Grid>
      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MHomepage;
