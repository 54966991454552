import React, { useState } from "react";
import { Box, Button, IconButton, Modal } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import SearchDropdown from "../../../../../Components/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Components/InputFields/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Components/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../../Components/InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../../Components/InputFields/DatePicker/DatePicker";

const MAddVehiclePopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  const [quoteFields, setQuoteFields] = useState<{
    regnoField: { value: string; warning: boolean };
    vehicleType: { value: string; warning: boolean };
    makeAndModel: { value: string; warning: boolean };
    variant: { value: string; warning: boolean };
    fuelType: { value: string; warning: boolean };
    regDate: { value: Date | null; warning: boolean };
    previousInsurer: { value: string; warning: boolean };
    policy_expiry: { value: Date | null; warning: boolean };
  }>({
    regnoField: { value: "DL01A2345", warning: false },
    vehicleType: { value: "", warning: false },
    makeAndModel: { value: "", warning: false },
    variant: { value: "", warning: false },
    fuelType: { value: "", warning: false },
    regDate: { value: null, warning: false },
    previousInsurer: { value: "", warning: false },
    policy_expiry: { value: null, warning: false },
  });
  const fueltype_data = [
    { label: "Petrol", value: "Petrol" },
    { label: "Diesel", value: "Diesel" },
    { label: "Electric", value: "Electric" },
  ];
  const claimed_data = [
    { label: "yes", value: "Yes" },
    { label: "no", value: "No" },
  ];

  const makeModel_data = [
    { label: "Maruti Suzuki", value: "Maruti Suzuki" },
    { label: "Hyundai", value: "Hyundai" },
    { label: "Mahindra", value: "Mahindra" },
    { label: "Tata", value: "Tata" },
    { label: "Skoda", value: "Skoda" },
  ];
  const variant_data = [
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
  ];
  const previousInsurer_data = [
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
    { label: "Alto 800 LXI", value: "Alto 800 LXI" },
  ];
  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Modal
      open={open}
      aria-labelledby="Health Insurance Add New Policy"
      className="modalWrapper"
    >
      <div className="modalInner mobile_form_padding">
        <Box className="modalContent modalContent_mobile">
          <Grid container columnSpacing={0} alignItems="center">
            <Grid xs={12} className="textRight">
              <IconButton
                className="close-button"
                onClick={() => {
                  setOpen(false);
                }}
              ></IconButton>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <h2 className="title_lg" style={{justifyContent:"center",marginBottom:"20px"}}>Add Your Vehicle</h2>
            </Grid>
          </Grid>

          <Grid container columnSpacing={0}>
            <Grid xs={12} marginBottom={"20px"}>
              <RKTextField
                class_name="inputField vehicleNumberField"
                title={""}
                value={quoteFields.regnoField.value}
                attrName={["regnoField", "value", quoteFields, setQuoteFields]}
                value_update={fieldsUpdateState}
                warn_status={quoteFields.regnoField.warning}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <SelectDropdown
                class_name="inputField "
                title="Vehicle Type"
                value={quoteFields.fuelType.value}
                attrName={["vehicleType", "value", quoteFields, setQuoteFields]}
                value_update={fieldsUpdateState}
                data={fueltype_data}
                warn_status={quoteFields.vehicleType.warning}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <SearchDropdown
                class_name="inputField "
                title="Make and Model"
                value={quoteFields.makeAndModel.value}
                attrName={[
                  "makeAndModel",
                  "value",
                  quoteFields,
                  setQuoteFields,
                ]}
                value_update={fieldsUpdateState}
                data={makeModel_data}
                warn_status={quoteFields.makeAndModel.warning}
                error_message={""}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <SelectDropdown
                class_name="inputField "
                title="Fuel Type"
                value={quoteFields.fuelType.value}
                attrName={["fuelType", "value", quoteFields, setQuoteFields]}
                value_update={fieldsUpdateState}
                data={fueltype_data}
                warn_status={quoteFields.fuelType.warning}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <SearchDropdown
                class_name="inputField "
                title="Variant"
                value={quoteFields.variant.value}
                attrName={["variant", "value", quoteFields, setQuoteFields]}
                value_update={fieldsUpdateState}
                data={variant_data}
                warn_status={quoteFields.variant.warning}
                error_message={""}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <DatePicker
                class_name="inputField"
                title={"Reg. Date"}
                value={quoteFields.regDate.value}
                attrName={["regDate", "value", quoteFields, setQuoteFields]}
                value_update={fieldsUpdateState}
                error_message=""
                warn_status={quoteFields.regDate.warning}
                min_date={15 * 12}
                max_date={3}
                date_validation_type="MONTHS"
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <SearchDropdown
                class_name="inputField "
                title="Previous Insurer"
                value={quoteFields.previousInsurer.value}
                attrName={[
                  "previousInsurer",
                  "value",
                  quoteFields,
                  setQuoteFields,
                ]}
                value_update={fieldsUpdateState}
                data={previousInsurer_data}
                warn_status={quoteFields.previousInsurer.warning}
                error_message={""}
              />
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <DatePicker
                class_name="inputField"
                title={"Policy Expiry"}
                value={quoteFields.policy_expiry.value}
                attrName={"policy_expiry"}
                value_update={fieldsUpdateState}
                warn_status={quoteFields.policy_expiry.warning}
                error_message={"Select Policy Expiry Date"}
                min_date={15 * 12}
                max_date={3}
                date_validation_type="MONTHS"
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid xs={12} textAlign="center">
              <CustomButton
                className="primaryBtn"
                text={"Submit Details"}
                variant={"text"}
                fullWidth={false}
                onClick={() => {}}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Modal>
  );
};

export default MAddVehiclePopup;
