import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Career from "../../Pages/Desktop/CmsPage/Career";
import MCareer from "../../Pages/Mobile/CmsPagesMobile/MCareer";

const CareerContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MCareer /> : <Career />}</>;
};

export default CareerContainer;
