import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Faq from "../../Pages/Desktop/CmsPage/FaqPage";
import MFaq from "../../Pages/Mobile/CmsPagesMobile/MFaqPage";

const FAQContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MFaq /> : <Faq />}</>;
};

export default FAQContainer;
