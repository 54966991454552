import Complaint from "../../Pages/Desktop/CmsPage/Complaint";
import MComplaint from "../../Pages/Mobile/CmsPagesMobile/MComplaint";
import useIsMobile from "../../SupportingFiles/MobileProvider";

const ComplaintContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MComplaint /> : <Complaint />}</>;
};

export default ComplaintContainer;
