import { Box, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../Components/InputFields/DatePicker/DatePicker";
import NativeSelectDropdown from "../../../../Components/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import RKTextField from "../../../../Components/InputFields/RKTextField/RKTextField";
import "../MMyAccount.scss";
import TextArea from "../../../../Components/InputFields/TextArea/TextArea";

const MFeedback = () => {
  const [formFields, setFormFields] = useState<{
    feedbackDesc: { value: string; warn_status: boolean };
  }>({
    feedbackDesc: { value: "", warn_status: false },
  });

  const updateFieldsState = (attrName: string, value: string) => {
    setFormFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="mfeedbackTab">
      <Grid container columnSpacing={3}>
        <Grid xs={12}>
          <Box className="mfeedback-header">
            {/* <img src="./images/feedback_girl.svg" alt="" /> */}
            <h4
              style={{
                fontSize: "18px",
                lineHeight: "26px",
                marginBottom: "12px",
                fontWeight: 500,
              }}
            >
              How was your experience
            </h4>
            <Rating
              name="size-large"
              defaultValue={0}
              size="large"
              sx={{ marginBottom: "22px" }}
            />
            <Box maxWidth={"438px"}>
              <TextArea
                class_name="inputField mb-5"
                title={"Write your experince here..."}
                value={formFields.feedbackDesc.value}
                attrName={"feedbackDesc"}
                value_update={updateFieldsState}
                warn_status={formFields.feedbackDesc.warn_status}
                placeholder="Tell us what is in your mind..."
              />
            </Box>
            <CustomButton
              className="primaryBtn"
              text={"Update Details"}
              onClick={() => {}}
              fullWidth={false}
              variant="contained"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MFeedback;
