import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "../../Store/Store/hooks";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import { THealthAddForm } from "../../Type/Health/THealthSlice/THealthSlice";
import {
  calculateAge,
  isEmpty,
  validateFullName,
  validateMobileNumber,
  validatePincode,
} from "../../SupportingFiles/HelpingFunction";
import { HealthSlice } from "../../Store/Reducer/Health/HealthSlice";
import { FRONTEND_DOMAIN } from "../../Routing/CommonURLs/Domain";
import { HEALTH_ROUTE } from "../../Routing/Path/HealthRoutes";
// import { HEALTH_FORM_SERVICES } from "../../Services/Health/HealthServices";
import { COMMON_ROUTES } from "../../Routing/Path/CommonRoutes";
import HealthProductFrom from "../../ProductFormPage/Health/HealthProductFrom";
import { HEALTH_FORM_SERVICES } from "../../Services/HealthServices/HealthFormServices";
function HealthProductContainer() {
  const { Health } = useAppSelector((state: any) => state);
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const dispatch = useAppDispatch();
  const [healthFormSteps, setHealthFormSteps] = useState<"1" | "2">("1");
  const [pageStatus, setPageStatus] = useState<"1" | "2">("1");
  const { ADD_FORM } = useAppSelector((state: any) => state.Health);
  const [formData, setFormData] = useState<THealthAddForm>(ADD_FORM);
  const [showLoader, setShowLoader] = useState(false);

  const [memberStatus, setMemberstatus] = useState({
    oneAdult: true,
    twoAdults: false,
    son: false,
    daughter: false,
  });
  const relationship_data = [
    { label: "Spouse", value: "Spouse" },
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
  ];

  const validate_form = () => {
    let hasError = false;
    let data: any = { ...formData };

    if (pageStatus === "1") {
      if (formData.self_status) {
        data = {
          ...data,
          self_dob: {
            ...data.self_dob,
            warning: isEmpty(data.self_dob.value),
          },
          gender: {
            ...data.gender,
            warning: isEmpty(data.gender.value),
          },
        };

        if (data.self_dob.warning || data.gender.warning) {
          hasError = true;
        }
      }

      if (formData.spouse_status) {
        data = {
          ...data,
          spouse_dob: {
            ...data.spouse_dob,
            warning: isEmpty(data.spouse_dob.value),
          },
          spouse_relationship: {
            ...data.spouse_relationship,
            warning: isEmpty(data.spouse_relationship.value),
          },
        };

        if (data.spouse_dob.warning || data.spouse_relationship.warning) {
          hasError = true;
        }
      }

      if (
        (formData.son_status || formData.daughter_status) &&
        (formData.son_count.value > 0 || formData.daughter_count.value > 0)
      ) {
        const childDobFields = [
          "child_one_dob",
          "child_two_dob",
          "child_three_dob",
          "child_four_dob",
        ];
        const sonCount = parseInt(formData.son_count.value, 10);
        const daughterCount = parseInt(formData.daughter_count.value, 10);
        const totalChildren = sonCount + daughterCount;

        for (let i = 0; i < totalChildren; i++) {
          const fieldName = childDobFields[i];
          data = {
            ...data,
            [fieldName]: {
              ...data[fieldName],
              warning: isEmpty(data[fieldName].value),
            },
          };
          hasError = hasError || data[fieldName].warning;
        }
      }

      if (!hasError) {
        setPageStatus("2");
      }
    } else {

      data = {
        ...data,
        name: {
          ...data.name,
          warning: !validateFullName(data.name.value),
        },
        mobile: {
          ...data.mobile,
          warning: !validateMobileNumber(data.mobile.value),
        },
        pincode: {
          ...data.pincode,
          warning: !validatePincode(data.pincode.value),
        },
      };

      if (data.mobile.warning || data.pincode.warning || data.name.warning) {
        hasError = true;
      }

      if (!hasError) {
        ADD_FORM_DETAILS(data);
      }
    }

    setFormData({ ...data });

    if (
      data.self_dob.warning ||
      data.gender.warning ||
      data.spouse_dob.warning ||
      data.spouse_relationship.warning ||
      data.child_one_dob.warning ||
      data.child_two_dob.warning ||
      data.child_three_dob.warning ||
      data.child_four_dob.warning
    ) {
      hasError = true;
    }

    if (!hasError) {
      setPageStatus("2");
    }
  };

  useEffect(() => {
    setFormData((prev: any) => ({
      ...prev,
      spouse_relationship: { value: "", warning: false },
      age: { value: "", warning: false },
      children: "",
      gender: { value: "", warning: false },
      pincode: { value: "", warning: false },
      whom_to_insure: "One Adult",
      mobile: { value: "", warning: false },
      name: { value: "", warning: false },
      son_count: { value: "0", warning: false },
      daughter_count: { value: "0", warning: false },
      self_dob: { value: "", warning: false },
      spouse_dob: { value: "", warning: false },
      son_status: false,
      daughter_status: false,
      self_status: true,
      spouse_status: false,
      child_one_dob: { value: "", warning: false },
      child_two_dob: { value: "", warning: false },
      child_three_dob: { value: "", warning: false },
      child_four_dob: { value: "", warning: false },
    }));
  }, []);

  const ADD_FORM_DETAILS = (data: THealthAddForm) => {
    const onSuccess = (res: any) => {
      setShowLoader(false);
      const results = res.response;
      const error = res.error;

      if (!error) {
        let dataResponse: any = {
          quote_no: results?.quote_no,
          sum_insured: results.sum_insured,
          module: results?.module,
          insurance_type: results?.insurance_type,
          term: "1",
          cover: results?.cover,
          city: results?.city,
          state: results?.state,
          child: results?.child,
          adult: results?.adult,
          mobile: results?.mobile,
          pincode: results?.pincode,
        };
        dispatch(
          HealthSlice.actions.BULK_UPLOAD({
            ...Health,
            ADD_FORM: data,
            ADD_FORM_RESPONSE: dataResponse,
          })
        );
        window.location.href = `${FRONTEND_DOMAIN}${HEALTH_ROUTE.QOUTE_PAGE}?type=HP&quote_no=${dataResponse.quote_no}`;
      } else {
        toast.error("Something went wrong");
      }
    };
    const onError = (error: any) => {
      setShowLoader(false);
    };

    // Define the parameters outside the JSX return statement
    const { son_count, daughter_count } = data;
    const total_sons = parseInt(son_count.value);
    const total_daughters = parseInt(daughter_count.value);

    let params: any = {
      healthSumInsured: "500000",
      healthCover: `${data?.spouse_status ? "2A" : "1A"}${
        total_sons + total_daughters > 0
          ? `${total_sons + total_daughters}C`
          : ""
      }`,
      healthAdult: `${data?.spouse_status ? "2" : "1"}`,
      children: `${total_sons + total_daughters}`,
      healthAge: data.self_dob.value,
      healthRelation: "self",
      healthGender: `${data.gender.value}`==="Male" ? "M" : "F",
      health2Age: data.spouse_dob.value,
      health2Relation: data.spouse_relationship.value,
      healthName: "",
      healthMobile: data.mobile.value,
      healthPincode: data.pincode.value,
      childgender_1: total_sons >= 1 ? "M" : total_daughters >= 1 ? "F" : "",
      childrelation_1:
        total_sons >= 1 ? "son" : total_daughters >= 1 ? "daughter" : "",
      childage_1: data.child_one_dob.value,
      childgender_2:
        total_sons + total_daughters >= 2 ? (total_sons >= 2 ? "M" : "F") : "",
      childrelation_2:
        total_sons + total_daughters >= 2
          ? total_sons >= 2
            ? "son"
            : "daughter"
          : "",
      childage_2:
        total_sons + total_daughters >= 2 ? data.child_two_dob.value : "",
      childgender_3:
        total_sons + total_daughters >= 3 ? (total_sons >= 3 ? "M" : "F") : "",
      childrelation_3:
        total_sons + total_daughters >= 3
          ? total_sons >= 3
            ? "son"
            : "daughter"
          : "",
      childage_3:
        total_sons + total_daughters >= 3 ? data.child_three_dob.value : "",
      childgender_4:
        total_sons + total_daughters >= 4 ? (total_sons >= 4 ? "M" : "F") : "",
      childrelation_4:
        total_sons + total_daughters >= 4
          ? total_sons >= 4
            ? "son"
            : "daughter"
          : "",
      childage_4:
        total_sons + total_daughters >= 4 ? data.child_four_dob.value : "",
    };

    HEALTH_FORM_SERVICES.ADD_HEALTH_FORM(onSuccess, onError, params);
  };

  const removeDobValuesIfNeeded = (data: THealthAddForm) => {
    const updatedData = { ...data };
    const totalChildrenCount =
      (updatedData.son_status ? parseInt(updatedData.son_count.value) : 0) +
      (updatedData.daughter_status
        ? parseInt(updatedData.daughter_count.value)
        : 0);
    // Clear DOB fields for children exceeding the total count
    if (totalChildrenCount < 4)
      updatedData.child_four_dob = { value: "", warning: false };
    if (totalChildrenCount < 3)
      updatedData.child_three_dob = { value: "", warning: false };
    if (totalChildrenCount < 2)
      updatedData.child_two_dob = { value: "", warning: false };
    if (totalChildrenCount < 1)
      updatedData.child_one_dob = { value: "", warning: false };

    return updatedData;
  };

  const updateMasterState = (attrName: any, value: any) => {
    setFormData((prev: any) => {
      let updatedData = { ...prev };

      switch (attrName) {
        case "whom_to_insure":
        case "children":
        case "self":
          updatedData[attrName] = value;
          break;

        case "son_status":
          if (value === true) {
            if (parseInt(prev.daughter_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.son_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.son_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;
        case "name":
          updatedData[attrName] = {
            value: value,
            warning: !validateFullName(value),
          };
          break;
        case "spouse_status":
          if (value === true) {
            updatedData[attrName] = value;
          } else {
            updatedData[attrName] = value;
            updatedData.spouse_dob = { value: "", warning: false };
            updatedData.spouse_relationship = { value: "", warning: false };
          }
          break;

        case "daughter_status":
          if (value === true) {
            if (parseInt(prev.son_count.value) < 4) {
              updatedData[attrName] = value;
              updatedData.daughter_count = { value: "1", warning: false };
            }
          } else {
            updatedData[attrName] = value;
            updatedData.daughter_count = { value: "0", warning: false };
            updatedData = removeDobValuesIfNeeded(updatedData);
          }
          break;

        case "pincode":
          updatedData[attrName] = {
            value: value,
            warning: !validatePincode(value),
          };
          break;

        case "mobile":
          updatedData[attrName] = {
            value: value,
            warning: !validateMobileNumber(value),
          };
          break;

        case "son_subtract":
          const newSonCount = Math.max(parseInt(prev.son_count.value) - 1, 0);
          if (newSonCount === 0) {
            updatedData.son_status = false;
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          } else {
            updatedData.son_count = {
              value: newSonCount.toString(),
              warning: false,
            };
          }

          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_subtract":
          const newDaughterCount = Math.max(
            parseInt(prev.daughter_count.value) - 1,
            0
          );
          if (newDaughterCount === 0) {
            updatedData.daughter_status = false;
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          } else {
            updatedData.daughter_count = {
              value: newDaughterCount.toString(),
              warning: false,
            };
          }
          updatedData = removeDobValuesIfNeeded(updatedData);
          break;

        case "daughter_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.daughter_count = {
              value: (parseInt(prev.daughter_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        case "son_add":
          if (
            (prev.son_status ? parseInt(prev.son_count.value) : 0) +
              (prev.daughter_status ? parseInt(prev.daughter_count.value) : 0) <
            4
          ) {
            updatedData.son_count = {
              value: (parseInt(prev.son_count.value) + 1).toString(),
              warning: false,
            };
          }
          break;

        default:
          updatedData[attrName] = { value: value, warning: isEmpty(value) };
      }

      return updatedData;
    });
  };

  const backFunction = () => {
    if (pageStatus === "2") {
      setPageStatus("1");
    } else {
      navigate(COMMON_ROUTES.HOME);
    }
  };

  return (
    <HealthProductFrom
      formData={formData}
      pageStatus={pageStatus}
      updateMasterState={updateMasterState}
      validateForm={validate_form}
      backFunction={backFunction}
      loading={showLoader}
      relationship_data={relationship_data}
      ADD_FORM_DETAILS={ADD_FORM_DETAILS}
    />
  );
}

export default HealthProductContainer;
