import { GetAPI, PostAPI } from "../../Axios/AxiosInstanceToken";
import { ADD_TW_DTO } from "../../DTO/TWDTO/Form/AddTWDTO";
import { TW_URLS } from "../../Routing/URLS/TW/TwUrls";

const GET_RTO_LIST = (onSuccess: Function, onError: Function) => {
  GetAPI.call(TW_URLS.GET_RTO_LIST, (res: any) => onSuccess(res.data), onError);
};

const GET_MAKE_LIST = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    TW_URLS.GET_MAKE_LIST,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_MODEL_LIST = (
  onSuccess: Function,
  onError: Function,
  make: string
) => {
  GetAPI.call(
    `${TW_URLS.GET_MODEL_LIST}${make}`,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_FUEL_TYPE_LIST = (
  onSuccess: Function,
  onError: Function,
  make_model: string
) => {
  GetAPI.call(
    `${TW_URLS.GET_FUEL_TYPE_LIST}${make_model}`,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_VARIANT_LIST = (
  onSuccess: Function,
  onError: Function,
  make_model: string,
  fuel_type: string
) => {
  GetAPI.call(
    `${TW_URLS.GET_VARIANT_LIST}${make_model}&fuel_type=${fuel_type}`,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_PREVIOUS_INSURER_LIST = (onSuccess: Function, onError: Function) => {
  GetAPI.call(
    TW_URLS.GET_PREVIOUS_INSURER_LIST,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const ADD_TW_DATA = (
  onSuccess: Function,
  onError: Function,
  dto: ADD_TW_DTO
) => {
  PostAPI.call(
    TW_URLS.ADD_FORM_DATA,
    dto,
    (res: any) => onSuccess(res.data),
    onError
  );
};

const GET_VEHICLE_NO_INFO = (
  onSuccess: Function,
  onError: Function,
  reg_no: string | Date | null
) => {
  GetAPI.call(
    `${TW_URLS.GET_VEHICLE_NO_INFO}${reg_no}`,
    (res: any) => onSuccess(res),
    (res: any) => {
      onError(res);
    },
    () => {
      onError();
    }
  );
};

const GENERATE_QUOTE_NO = (
  onSuccess: Function,
  onError: Function,
  data: ADD_TW_DTO
) => {
  PostAPI.call(
    TW_URLS.ADD_FORM_DATA,
    data,
    (res: any) => onSuccess(res.data),
    onError
  );
};

export const TW_ADD_FORM_SERVICES = {
  ADD_TW_DATA,
  GET_PREVIOUS_INSURER_LIST,
  GET_VARIANT_LIST,
  GET_FUEL_TYPE_LIST,
  GET_MODEL_LIST,
  GET_MAKE_LIST,
  GET_RTO_LIST,
  GENERATE_QUOTE_NO,
  GET_VEHICLE_NO_INFO,
};
