import { Box, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Footer from "../../../Components/Desktop/Footer/Footer";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import "./Homepage.scss";
import { FRONTEND_DOMAIN } from "../../../Routing/CommonURLs/Domain";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function Homepage() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1150 },
      items: 4,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1149, min: 464 },
      items: 3,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const awards = [
    {
      img_url: "./images/award_img1.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by ABP News BFSI
          Awards in 2018.
        </>
      ),
    },
    {
      img_url: "./images/award_img2.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> at Golden Star
          Awards in 2018.
        </>
      ),
    },
    {
      img_url: "./images/award_img3.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by ABP News BFSI
          Awards in 2015.
        </>
      ),
    },
    {
      img_url: "./images/award_img4.svg",
      award_desc: (
        <>
          Awarded Insurance <span>"Broker of the Year"</span> by IPE BFSI Awards
          in 2013.
        </>
      ),
    },
  ];

  return (
    <Box className="homePageWrapper">
      <Navbar />
      <Box className="header_outer">
        <Box className="header">
          <Grid container>
            <Grid xs={12}>
              <h1>
                The secret of getting ahead <span>is getting started.</span>
              </h1>
            </Grid>
          </Grid>
          <Grid container>
            <Grid xs={6}>
              <Box>
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <Link
                      className="product_outer"
                      onClick={() => {
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.HEALTH_FORM}`;
                      }}>
                      <div className="product_sec health">
                        <h5>
                          Health
                          <br /> Insurance
                        </h5>
                      </div>
                    </Link>
                  </Grid>
                  <Grid xs={6}>
                    <Link
                      className="product_outer"
                      onClick={() => {
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TERM_FORM}`;
                      }}>
                      <div className="product_sec term">
                        <h5>
                          Term
                          <br /> Insurance
                        </h5>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
                <Grid container spacing={3}>
                  <Grid xs={12} className="pt-6">
                    <Link className="product_outer disabled" onClick={() => {}}>
                      <div className="travel_product_sec">
                        <h5>
                          Travel
                          <br /> Insurance
                        </h5>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid xs={6}>
              <>
                <Grid container spacing={3}>
                  <Grid xs={6}>
                    <Link
                      className="product_outer"
                      onClick={() => {
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.CAR_FORM}`;
                      }}>
                      <div className="car_product_sec">
                        <h5>
                          Car
                          <br /> Insurance
                        </h5>
                      </div>
                    </Link>
                  </Grid>
                  <Grid xs={6}>
                    <Link
                      className="product_outer"
                      onClick={() => {
                        window.location.href = `${FRONTEND_DOMAIN}${COMMON_ROUTES.TW_FORM}`;
                      }}>
                      <div className="product_sec bike">
                        <h5>
                          Bike
                          <br /> Insurance
                        </h5>
                      </div>
                    </Link>
                    <Link className="product_outer disabled" onClick={() => {}}>
                      <div className="product_sec investment  mt-6">
                        <h5>
                          Investment
                          <br /> Plans
                        </h5>
                      </div>
                    </Link>
                  </Grid>
                </Grid>
              </>
            </Grid>
          </Grid>
        </Box>
      </Box>

      <Box className="factsheet">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="mb-10" textAlign={"center"}>
            <h2>Fact Sheet</h2>
            <p>Customer Delight, Every Time.</p>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid xs={3}>
            <div className="counter_sec">
              <h4>
                <span className="counter">24</span>+
              </h4>
              <p className="mb-0">Years of Experience</p>
            </div>
          </Grid>
          <Grid xs={3}>
            <div className="counter_sec">
              <h4>
                <span className="counter">19000</span>+
              </h4>
              <p className="mb-0">Satisfied Clients</p>
            </div>
          </Grid>
          <Grid xs={3}>
            <div className="counter_sec">
              <h4>
                <span className="counter">11</span>+
              </h4>
              <p className="mb-0">Presence Across Cities </p>
            </div>
          </Grid>
          <Grid xs={3}>
            <div className="counter_sec">
              <h4>
                <span className="counter">10</span>Bn+
              </h4>
              <p className="mb-0">Premium Handling (INR Crs)</p>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Box className="awards_sec_outer">
        <Grid container columnSpacing={3} className="mb-5">
          <Grid xs={3}>
            <h3>
              Awards &<br /> Recognition{" "}
            </h3>
          </Grid>
          <Grid xs={9}>
            <p>
              UNISON was started with the belief and conviction, that there are
              always better options available in the market and the client
              should be aware of the same. UNISON has been working to create the
              right conditions for these changes to take place, right from
              inception of the firm till date.
            </p>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Box className="divider"></Box>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              arrows={false}
              responsive={responsive}
              infinite={true}
              autoPlay={true}
              autoPlaySpeed={3000} // Adjust the speed as needed (in milliseconds)
            >
              {awards.map((data, index) => (
                <div key={index}>
                  <Grid container spacing={3}>
                    <Grid xs={12}>
                      <div className="choose-sec">
                        <img src={data.img_url} alt="" />
                        <p>{data.award_desc}</p>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              ))}
            </Carousel>
          </Grid>
        </Grid>
      </Box>

      <Box className="feature_sec">
        <Grid container spacing={3}>
          <Grid xs={3}>
            <img
              src="./images/feature_sec_img.svg"
              alt=""
              className="img-fluid"
            />
          </Grid>
          <Grid xs={9}>
            <h2>
              It's not just what we do, it's how <br /> we do it that sets us
              apart.
            </h2>
            <>
              <Grid container spacing={0}>
                <Grid xs={12} className="divider mb-4" padding={0}></Grid>
              </Grid>
            </>
            <>
              <Grid container spacing={0}>
                <Grid xs={4}>
                  <div className="feature_desc">
                    <span className="icon1"></span>
                    <h5>
                      Customer <br />
                      Satisfaction
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor
                    </p>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div className="feature_desc">
                    <span className="icon2"></span>
                    <h5>
                      Compare
                      <br />
                      Plans
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor
                    </p>
                  </div>
                </Grid>
                <Grid xs={4}>
                  <div className="feature_desc">
                    <span className="icon3"></span>
                    <h5>
                      24/7 Claim <br />
                      Assistance
                    </h5>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor
                    </p>
                  </div>
                </Grid>
              </Grid>
            </>
          </Grid>
        </Grid>
      </Box>

      <Box className="customer_review">
        <Grid container columnSpacing={3} className="mb-10">
          <Grid xs={12}>
            <div className="top-heading">
              <h2>
                What customer say
                <br /> about us
              </h2>
            </div>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <ul className="testimonial_list">
              <li>
                <div className="review_div good">
                  <span>
                    <img src="./images/cust_review_icon1.svg" alt="" />
                  </span>
                  <p>
                    Always available, extremely knowledgeable, and a tremendous
                    "can-do" attitude. Our company was on a tight timeline
                  </p>
                </div>
              </li>
              <li>
                <div className="review_div excellent mt-60">
                  <span>
                    <img src="./images/cust_review_icon2.svg" alt="" />
                  </span>
                  <p>
                    Always available to answer any questions. Very knowledgeable
                  </p>
                </div>
              </li>
              <li>
                <div className="review_div happy ">
                  <span>
                    <img src="./images/cust_review_icon3.svg" alt="" />
                  </span>
                  <p>
                    Always available, extremely knowledgeable, and a tremendous
                    "can-do" attitude. Our company was on a tight timeline
                  </p>
                </div>
              </li>
              <li>
                <div className="review_div superb mt-60">
                  <span>
                    <img src="./images/cust_review_icon3.svg" alt="" />
                  </span>
                  <p>
                    Always available to answer any questions. Very knowledgeable
                  </p>
                </div>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>

      <Box className="partners_section">
        <Grid container columnSpacing={3}>
          <Grid xs={12} className="mb-10">
            <h2>Our Partners</h2>
          </Grid>
        </Grid>
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <ul className="partnersLogo ">
              <li>
                <img
                  src="images/insurerlogo/Reliance-General-Insurance-Co-Ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/the_new_india_insurance_co_ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                  alt="insurer"
                />
              </li>
            </ul>
            <ul className="partnersLogo">
              <li>
                <img
                  src="images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/aditya_birla_capital.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/HDFC-Standard-Life-Insurance-Co-Ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Max-Bupa-Health-Insurance-Company-Ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Religare-Health-Insurance-Company-Limited.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Tata-AIA-Life-Insurance.svg"
                  alt="insurer"
                />
              </li>
            </ul>
            <ul className="partnersLogo">
              <li>
                <img
                  src="images/insurerlogo/the_new_india_insurance_co_ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img src="images/insurerlogo/Appolo.svg" alt="insurer" />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Universal-Sompo-General-Insurance-Co-Ltd.svg"
                  alt="insurer"
                />
              </li>
              <li>
                <img
                  src="images/insurerlogo/Royal_Sundaram.svg"
                  alt="insurer"
                />
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </Box>
  );
}

export default Homepage;
