import { Box, Rating, Link } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import MAddVehiclePopup from "./MAddVehiclePopup/MAddVehiclePopup";

const MAddVehicle = () => {
  const [openAddVehiclePopup, setOpenAddVehiclePopup] = useState(false);
  return (
    <Box className="maddVehicleTab">
      <MAddVehiclePopup
        open={openAddVehiclePopup}
        setOpen={setOpenAddVehiclePopup}
      />
      <Grid container columnSpacing={3}>
        <Grid xs={12}>
          <Box className="maddvehicle-header">
            <h4
              style={{
                fontSize: "18px",
                lineHeight: "26px",
                marginBottom: "5px",
                fontWeight: 500,
              }}
            >
              Add Your Vehicle
            </h4>
            <p className="mb-4" style={{ color: "rgba(96, 96, 96, 1)" }}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempo.
            </p>
            <CustomButton
              className="primaryBtn"
              text={"Add Vehicle"}
              onClick={() => setOpenAddVehiclePopup(true)}
              fullWidth={false}
              variant="contained"
            />
          </Box>
        </Grid>

        {/* my vehicles section */}
        <Grid xs={12}>
          <Box className="myVehicles-section">
            <h4 className="mb-5" style={{ textAlign: "center" }}>
              My Vehicles
            </h4>
            <Box className="myVehicles-inner">
              {/* car box */}
              <Box className="myvehicle_box_m">
                <Grid container columnSpacing={3} alignItems={"center"}>
                  <Grid xs={12} marginBottom={"20px"}>
                    <img
                      src="./images/car_icon.svg"
                      alt=""
                      className="ins_logo"
                    />
                    <p className="vehicleType">Four Wheeler</p>
                  </Grid>
                  <Grid xs={12} marginBottom={"20px"}>
                    <p className="p1" style={{ marginBottom: "10px" }}>
                      Vehicles Details{" "}
                      <span>Hyundai Creta (sx()Diesel,1600cc)</span>
                    </p>
                    <p className="p1">
                      Vehicle Number <span>HR08B7654</span>
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <CustomButton
                      className="primaryBtn small"
                      text={
                        <>
                          <ReplayCircleFilledIcon /> Renew Policy
                        </>
                      }
                      onClick={() => {}}
                      fullWidth={false}
                      variant="contained"
                    />
                    <p className="policy_status">
                      Status <span className="expired">expired</span>
                    </p>
                    <Box
                      className="mt-5"
                      display={"flex"}
                      gap={"8px"}
                      justifyContent={"center"}
                    >
                      <Link className="grey_link">
                        <DeleteOutlineIcon />
                        Delete
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>

              {/* tw box */}
              <Box className="myvehicle_box_m">
                <Grid container columnSpacing={3} alignItems={"center"}>
                  <Grid xs={12} marginBottom={"20px"}>
                    <img
                      src="./images/bike_icon.svg"
                      alt=""
                      className="ins_logo"
                    />
                    <p className="vehicleType">Two Wheeler</p>
                  </Grid>
                  <Grid xs={12} marginBottom={"20px"}>
                    <p className="p1" style={{ marginBottom: "10px" }}>
                      Vehicles Details <span>Honda Activa, 125cc</span>
                    </p>
                    <p className="p1">
                      Vehicle Number <span>HR08B7654</span>
                    </p>
                  </Grid>
                  <Grid xs={12}>
                    <CustomButton
                      className="primaryBtn small"
                      text={
                        <>
                          <ReplayCircleFilledIcon /> Renew Policy
                        </>
                      }
                      onClick={() => {}}
                      fullWidth={false}
                      variant="contained"
                    />
                    <p className="policy_status">
                      Status <span className="active">Active</span>(128 Days
                      left)
                    </p>
                    <Box
                      className="mt-5"
                      display={"flex"}
                      gap={"8px"}
                      justifyContent={"center"}
                    >
                      <Link className="grey_link">
                        <DeleteOutlineIcon />
                        Delete
                      </Link>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MAddVehicle;
