import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { COMMON_ROUTES } from "../Path/CommonRoutes";
import HomeContainer from "../../Containers/HomeContainer/HomeContainer";
import AboutusContainer from "../../Containers/AboutusContainer/AboutusContainer";
import ComplaintContainer from "../../Containers/ComplaintContainer/ComplaintContainer";
import ClaimContainer from "../../Containers/ClaimContainer/ClaimContainer";
import ContactContainer from "../../Containers/ContactContainer/ContactContainer";
import DisclaimerContainer from "../../Containers/DisclaimerContainer/DisclaimerContainer";
import RefundCancellationContainer from "../../Containers/RefundCancellationContainer/RefundCancellationContainer";
import TermsConditionsContainer from "../../Containers/TermsConditionsContainer/TermsConditionsContainer";
import PrivacyPolicyContainer from "../../Containers/PrivacyPolicyContainer/PrivacyPolicyContainer";
import FAQContainer from "../../Containers/FAQContainer/FAQContainer";
import HealthProductPage from "../../Pages/Desktop/HealthProductPage/HealthProductPage";
import CarProductPage from "../../Pages/Desktop/CarProductPage/CarProductPage";
import TWProductPage from "../../Pages/Desktop/TWProductPage/TWProductPage";
import MyAccount from "../../Pages/Desktop/MyAccount/MyAccount";
import MMyAccount from "../../Pages/Mobile/MMyAccount/MMyAccount";
import Career from "../../Pages/Desktop/CmsPage/Career";
import CareerContainer from "../../Containers/CareerContainer/CareerContainer";

function CommonRoutes() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="App">
      <Routes>
        <Route path={COMMON_ROUTES.HOME} element={<HomeContainer />} />
        <Route path={COMMON_ROUTES.ABOUT_US} element={<AboutusContainer />} />
        <Route
          path={COMMON_ROUTES.COMPLAINT}
          element={<ComplaintContainer />}
        />
        <Route path={COMMON_ROUTES.CLAIM} element={<ClaimContainer />} />
        <Route path={COMMON_ROUTES.CONTACT} element={<ContactContainer />} />
        <Route path={COMMON_ROUTES.CAREER} element={<CareerContainer />} />
        <Route
          path={COMMON_ROUTES.DISCLAIMER}
          element={<DisclaimerContainer />}
        />
        <Route
          path={COMMON_ROUTES.REFUND_CANCELLATION}
          element={<RefundCancellationContainer />}
        />
        <Route
          path={COMMON_ROUTES.TERM_AND_CONDTION}
          element={<TermsConditionsContainer />}
        />
        <Route
          path={COMMON_ROUTES.PRIVACY_POLICY}
          element={<PrivacyPolicyContainer />}
        />
        <Route path={COMMON_ROUTES.FAQ} element={<FAQContainer />} />
        <Route
          path={COMMON_ROUTES.PRODUCT_HEALTH}
          element={<HealthProductPage />}
        />
        <Route path={COMMON_ROUTES.PRODUCT_CAR} element={<CarProductPage />} />
        <Route path={COMMON_ROUTES.PRODUCT_TW} element={<TWProductPage />} />
        <Route path={COMMON_ROUTES.MY_ACCOUNT} element={<MMyAccount />} />
      </Routes>
    </div>
  );
}

export default CommonRoutes;
