import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";
import { COLORS } from "../../../SupportingFiles/colors";

function MPrivacyPolicy() {
  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="cms_header text-center">
            <h2 className="mb-3">Privacy Policy</h2>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="cms_content_sec">
            <h5 className="mb-2">1.0 PURPOSE</h5>
            <p className="content_p mb-5">
              This privacy policy describes the management of personal
              information that UNISON Insurance Brokers Limited (Here after
              refers as UNISON) or its representative collects from or about the
              employee/customer/vendor and describes how it is used. The company
              collects and uses personal data in a reasonable and lawful manner
              to provide a best service to our client and customer. The Company
              may amend these rules from time to time.
            </p>

            <h5 className="mb-2">2.0 SCOPE</h5>
            <p className="content_p mb-5">
              <span>2.1</span> UNISON does collect your personal information for
              a variety of regulatory and business purposes. These include, but
              are not limited to: -
            </p>
            <ol type="1" className="mb-5">
              <li>Verify your identity.</li>
              <li>Complete transaction of Products and Services.</li>
              <li>Respond to the request for any service/assistance.</li>
              <li>
                Perform market analysis, market research, business, and
                operational analysis.
              </li>
              <li>Provide, maintain and improve our Product and Services.</li>
              <li>
                Anticipate and resolve issues and concerns with our Product and
                Services.
              </li>
              <li>
                Ensure adherence to legal and regulatory requirements for
                prevention and detections of frauds and crimes.
              </li>
              <li>
                We are committed to keep all collected in secure manner and
                confidentiality.
              </li>
              <li>
                UNISON will not share any one person's personal data to third
                parties.
              </li>
            </ol>

            <h5 className="mb-2">3.0 DEFINITIONS</h5>
            <p className="content_p mb-5">
              <span>3.1 </span>
              Personal Information-- The term 'Personal Information' applies to
              any information that is used to identify/contact an
              employee/customer/vendor for business purposes. This includes an
              individual's name, fathers name, mothers name, spouse name,
              children’s name current and previous address, date of birth,
              contact details, occupation, professional memberships, yearly and
              monthly income etc not limited to any information as required by
              the Company. In the normal course of business activities, the
              company will collect personal information but under certain
              exceptional circumstances Company may collect Sensitive Personnel
              Information (SPI) also. UNISON and its authorized third parties
              may collect, store, process following types of Sensitive Personal
              Information such as password, financial information (details of
              Bank account, credit card, debit card, or other payment instrument
              details), biometric information, physiological information for
              providing our products, services and for use of our website. We
              may also hold information related to your utilization of our
              services which may include your call details, your browsing
              history on our website, location details and additional
              information provided by you while using our services.
            </p>
            <p className="content_p mb-5">
              <span>3.2 </span>Information Technology (Reasonable security
              practices and procedures and sensitive personal data or
              information) Rules, 2011 defines Sensitive Personal Information.
              Sensitive Personal data or information of a person means such
              personal information which consist of any information relating to:
            </p>
            <ol type="1" className="mb-5">
              <li>Password.</li>
              <li>
                Financial information such as Bank account or credit card or
                debit card or other payment instrument details.
              </li>
              <li>Physical or physiological and mental health condition.</li>
              <li>Sexual orientation.</li>
              <li>Provide, maintain and improve our Product and Services.</li>
              <li>Medical records and history.</li>
              <li>Biometric information.</li>
              <li>
                Any detail relating to the above clauses as provided to body
                corporate for providing service.
              </li>
              <li>
                Any of the information received under above clauses by body
                corporate for processing, stored or processed under lawful
                contract or otherwise. provided that, any information that is
                freely available or accessible in public domain or furnished
                under Right to Information Act, 2005 or any other law for the
                time being in force shall not be regarded as sensitive personal
                data or information for the purpose of these rules.
              </li>
            </ol>

            <h5 className="mb-2">4.0 POLICY</h5>
            <p className="content_p mb-5">
              <span>4.1</span> Collection of Personal Information.
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.1.1</span> UNISON may collect diverse types of personal
                information about an employee/client/vendor under various
                circumstances. They are collected from.
              </p>
              <ol type="1" className="mb-5">
                <li>
                  Process application and alumni related requests, more details
                  about which are available on corresponding portals of this web
                  site to identify you once you register on our website.
                </li>
                <li>Contact you and respond to your questions or requests.</li>
                <li>References and interview notes.</li>
                <li>Proof of Identity (photo ID cards, Passport etc).</li>
                <li>Identify you once you register on our website.</li>
                <li>
                  Process job application and alumni related requests, more
                  details about which are available on corresponding portals of
                  this website
                </li>
                <li>
                  Certificates of educational/ professional qualifications.
                </li>
                <li>Photographs and video.</li>
                <li>Letters of offer and acceptance of employment.</li>
                <li>Mandatory policy acknowledgment sign-off sheets.</li>
                <li>
                  Information relating to payroll, pay cheque deposit
                  information, and other related information.
                </li>
                <li>
                  Forms relating to the application for, or in respect of
                  changes to, employee health and Welfare benefits; including,
                  short- and long-term disability, medical and dental care
                </li>
                <li>Beneficiary and emergency contact information.</li>
                <li>
                  List of Dependency- age, relation, and their medical history.
                </li>
              </ol>
              <p className="content_p mb-5">
                <span>4.1.2 </span> In addition personal information also
                includes name, address, date of birth, phone number, mailing
                address, credit card information, and any other relevant
                information necessary to company’s business purposes. The
                company may also collect personal information of
                employees/clients from third parties, if required for business.
              </p>
              <p className="content_p mb-5">
                <span>4.1.3 </span>UNISON may at its discretion employ,
                contract, or include third parties external to itself for
                strategic, tactical, and operational purposes. Such agencies
                though external to UNISON, will always be entities which are
                covered by contractual agreements. These agreements in turn
                include UNISON`s guidelines to the management, treatment, and
                secrecy of personal information. We may transfer your personal
                information or other information collected, stored, processed by
                us to any other entity or organization located in India only in
                case it is necessary for providing our best services to you or
                if you have consented (at the time of collection of information)
                to the same. This may also include sharing of aggregated
                information with them in order for them to understand our
                environment and consequently, provide you with better services.
                While sharing your personal information with third parties,
                adequate measures shall be taken to ensure that reasonable
                security practices are followed at the third party. We may
                obtain your consent for sharing your personal information in
                several ways, such as in writing, online, through
                "click-through" agreements; orally, including through
                interactive voice response; or when your consent is part of the
                terms and conditions pursuant to which we provide you service.
                We however assure you that UNISON does not disclose your
                personal information to unaffiliated third parties (parties
                outside UNISON corporate network and its Strategic and Business
                Partners) which could lead to invasion of your privacy.
              </p>
              <p className="content_p mb-5">
                <span>4.1.4 </span> UNISON may also share your personal
                information with Government agencies or other authorized law
                enforcement agencies (LEAs) mandated under law to obtain such
                information for the purpose of verification of identity or for
                prevention, detection, investigation including but not limited
                to cyber incidents, prosecution, and punishment of offences.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.2</span> Use of Personal Information.
            </p>
            <p className="content_p mb-5">
              The Personal Information and Sensitive personal Data or
              Information that we collect from you is held in accordance with
              applicable laws and regulations in India. It may be used by us for
              several lawful purposes connected with our business operations and
              functions, which include but not limited to:
            </p>
            <ol type="1" className="mb-5">
              <li>
                Verification of your identity as per prevalent laws and
                regulation;
              </li>
              <li>
                Processing your request and providing you with products and/or
                services requested or administering your account;
              </li>
              <li>
                Settling accounts with those who provide related services to us;
              </li>
              <li>
                Dealing with requests, enquiries or complaints and other
                customer care related activities; and all other general
                administrative and business purposes;
              </li>
              <li>
                Carrying out market and product analysis and marketing our
                companies’ products and services and communicate updates, offers
                and promotion;
              </li>
              <li>
                Customer analytics on usage pattern and deliver customized
                content and advertising that may be of interest to you;
              </li>
              <li>Provide you with the best customer experience possible;</li>
              <li>Address network integrity and security issues;</li>
              <li>
                Carrying out any activity in connection with a legal,
                governmental or regulatory requirement , for the purpose of
                compliance of a legal obligation, in connection with legal
                proceedings, in connection with prevention, detection ,
                investigation including cyber incidents, prosecution, and
                punishment of offences protect and defend our rights or property
                or act in an emergency to protect someone's safety or to help
                investigations, monitor or prevent or take action regarding
                unlawful and illegal activities, suspected fraud, potential
                threat to the safety or security of any person.
              </li>
              <li>
                Carrying out activities connected with the running of our
                business such as personnel training, quality control, network
                monitoring, testing and maintenance of computer and other
                systems and in connection with the transfer of any part of our
                business in respect of which you are a customer or a potential
                customer;
              </li>
            </ol>

            <p className="content_p mb-5">
              <span>4.3</span> Disclosure of Personal Information.
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.3.1</span> Personal Information may be shared or
                disclosed by the company either internally or externally with
                third parties/Clients for the purpose for which it was
                collected. An individual can withdraw consent at any time,
                subject to legal and contractual restrictions. UNISON will
                inform the implications of such withdrawal.
              </p>
              <p className="content_p mb-5">
                <span>4.3.2 </span> Further, personal information may be
                disclosed under the following circumstances:
              </p>
              <ol type="1" className="mb-5">
                <li>Where required by applicable law or legal processes</li>
                <li>Where permitted by law without individual's consent.</li>
                <li>
                  Where any government authority needs to conduct a compliance
                  audit (If mandatory)
                </li>
                <li>
                  Where it is necessary to protect the rights and property
                  (including intellectual property) and all other confidential
                  information of the company.
                </li>
                <li>
                  Where situation arise to protect the safety of an individual
                  or a group.
                </li>
                <li>
                  Where it forms part of the daily reporting activities to
                  employees and other persons.
                </li>
              </ol>
            </Box>

            <p className="content_p mb-5">
              <span>4.4</span> Security of Personal Information.
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.4.1</span> The company is responsible to secure the
                whole stakeholder (employee /client /guest /vendor /visitor)
                personal information it holds regardless of the form in which
                the information is stored. The company has taken appropriate
                measures to prevent any loss, misuse, unauthorized access,
                disclosure, or modification of Personal Information. As per our
                security practices and procedures we limit access to personal
                information on need only and related department basis or
                approval basis. Further, our employees are bound by
                Confidentiality Policies which obligate them to protect the
                confidentiality of personal information. We take adequate steps
                to ensure that our third parties adopt reasonable level of
                security practices and procedures to ensure security of personal
                information. We may retain your personal information for as long
                as required to provide you with services or if otherwise
                required under any law. When we dispose of your personal
                information, we use reasonable procedures to erase it or render
                it unreadable (for example, shredding documents and wiping
                electronic media).
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.5</span> Reasonable Security Practices and Procedures.
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.5.1</span> As part of implementing Reasonable Security
                Practices and Procedures, the company is in the process of
                acquiring ISO 27000:20013 certification as per standards
                applicable.
              </p>
              <p className="content_p mb-5">
                <span>4.5.2</span> We have put in place appropriate technical,
                organizational and security measures to prevent your personal
                data from being accidentally lost, used, or accessed in an
                unauthorized way, altered, or disclosed. In addition, we limit
                access to your personal data to those employees, agents,
                contractors and other third parties who have a business need to
                know. They will only process your personal data on our
                instructions, and they are subject to a duty of confidentiality.
              </p>
              <p className="content_p mb-5">
                <span>4.5.3</span> We have put in place procedures to deal with
                any suspected data security breach and will notify you and any
                applicable regulator of a suspected breach where we are legally
                required to do so.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.6</span> Right of Access to Personal Information.
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.6.1</span> The company allows employees/clients to
                access personal information only on their requests. If any
                personal information needs to be updated or verified, it shall
                be informed to the HR Manager/CS.
              </p>
              <p className="content_p mb-5">
                <span>4.6.2</span> In cases where an employee/client is
                restricted access to personal information; the company will
                explain the reasons for the lack of access, except were
                prohibited by law. Additionally, personal information may be
                destroyed or erased, in accordance with the company's record
                retention policies.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.7</span> Retention
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.7.1</span> Personal data is kept in active files or
                systems only as long as needed to meet the purposes for which it
                was collected or as required by contractual agreement, by law or
                regulation, or, where applicable for the appropriate statute of
                limitations period. Records will be periodically reviewed and
                archived or properly disposed of according to the company’s
                record retention policy. Personal data may be archived to meet
                legal requirements, for research purposes or to facilitate
                long-term storage.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.8</span> Disclaimer
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.8.1</span> Every effort has been made to ensure that the
                information stated by the individual is correct and up to date,
                however, Company does not provide any warranty as to the
                completeness or accuracy of the information and, subject to
                applicable laws, does not accept any liability for damages of
                any kind resulting from any unauthorized access or for any
                advertisement published regarding employee/customers Personnel
                information.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>4.9</span> Terms of Use
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>4.9.1</span> Understand that in case of your
                non-compliance (intentional or otherwise) with the Privacy
                Policy, Terms of Use and other Legal Policies for access or
                usage of the Site / Mobile Application, UNISON reserves the
                right to terminate your access to the Site / Mobile Application
                and/or your account (“collectively referred to as Access”) or
                remove such non-compliant information (if any) provided by you,
                or both.
              </p>
            </Box>

            <h5 className="mb-2">5.0 Changes in this Privacy Policy</h5>
            <p className="content_p mb-5">
              <span>5.1 </span> UNISON reserves the right to change this policy
              from time to time without to prior notice digitally or print media
              as per nationally or internationally level changes are required,
              or any important circular issued by Government of INDIA at its
              sole discretion. We may update this privacy policy to reflect
              changes to our information practices. We encourage you to
              periodically review.
            </p>

            <p className="content_p mb-5">
              <span>5.2 </span> Complaint Resolution
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>5.2.1 </span> UNISON endeavour to provide information
                under its control or possession, or assistance to any Government
                agency which is lawfully authorised to investigate or protect
                cybersecurity activities, for the purposes of verification of
                identity, or for the prevention, detection, investigation, or
                prosecution, of offences under any law for the time being in
                force, or for cyber security incidents as soon as possible, but
                not later than seventy two hours of the receipt of an order or
                as per the timelines specified in any government or regulatory
                order. Accordingly, our Grievance officer and our legal team is
                authorized to receive and acknowledge any order, notice or
                direction issued by the Appropriate Government, any competent
                authority, or a court of competent authority.
              </p>
            </Box>

            <p className="content_p mb-5">
              <span>5.3 </span> Complaint Resolution
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>5.3.1 </span> Any employee/customer who has a concern
                about the collection, use or disclosure of the individual’s
                personal data can directly file a complaint before the HR
                Manager /Head of Customer service cell. The company will
                investigate and ensure appropriate resolution. The company will
                make reasonable efforts to maintain confidentiality regarding
                the employee/customer’s concern.
              </p>
              <p className="content_p mb-5">
                Customers shall bring the issue to the attention of the
                Grievance Officer whose details are as mentioned below.
              </p>
              <table
                className="detail_table mb-5"
                style={{
                  borderCollapse: "collapse",
                  width: "100%",
                  border: "1px solid black",
                }}
              >
                <tbody>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Role designated for Complaint Resolution
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Head of Customer service cell
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Contact Number
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      1800-123-624276
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      E-mail
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      <a
                        style={{ color: COLORS.black }}
                        href="mailto:complaints@unisoninsurance.ne"
                      >
                        Complaints@unisoninsurance.net
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Working Days / Hours
                    </td>
                    <td style={{ border: "1px solid black", padding: "8px" }}>
                      Monday to Friday / 9:00 am to 6:00 pm
                    </td>
                  </tr>
                </tbody>
              </table>
            </Box>

            <p className="content_p mb-5">
              <span>5.4 </span> Data Protection Officer
            </p>
            <Box paddingLeft={"12px"}>
              <p className="content_p mb-5">
                <span>5.4.1 </span> UNISON has appointed data owner for the Data
                Privacy Policy shall be the Nodal/Data Protection Officer. The
                Nodal/Data Protection Officer shall be responsible for
                maintenance and accuracy of this policy. Any queries regarding
                the implementation of this policy shall be directed to the
                Nodal/Data Protection Officer (email at
                ciso@unisoninsurance.net). If any query received from CRM desk,
                the same shall route to CISO & the responsibility of resolution
                belongs to CISO/IT red team.
              </p>
              <p className="content_p mb-5">
                <span>5.4.2 </span> This policy shall be reviewed for updates by
                Nodal/Data Protection Officer on an annual basis. Additionally,
                the data privacy policy shall be updated in-line with any major
                changes within the organization’s operating environment, on
                recommendations provided by internal/ external auditors and/or
                on any changes brought in by regulatory/statutory amendments.
              </p>
            </Box>

            <h5 className="mb-2">6.0 Do Not Track</h5>
            <p className="content_p mb-5">
              Do Not Track (DNT) is a privacy preference that users can set in
              some web browsers, allowing users to opt out of tracking by
              websites and online services. At the present time, the World Wide
              Web Consortium (W3C) has not yet established universal standards
              for recognizable DNT signals and therefore, Infosys Limited and
              the Site do not recognize DNT.
            </p>

            <h5 className="mb-2">7.0 Children’s Privacy</h5>
            <p className="content_p mb-5">
              The Site does not knowingly solicit or collect personally
              identifiable information online from children under the age of 13
              without prior verifiable parental consent. If UNISON brokers
              Private Limited learns that a child under the age of 13 has
              submitted personally identifiable information online without
              parental consent, it will take all reasonable measures to delete
              such information from its databases and to not use such
              information for any purpose (except where necessary to protect the
              safety of the child or others as required or allowed by law). If
              you become aware of any personally identifiable information, we
              have collected from children under 13, please call us at +91 63574
              43617 or email us at complaints@unisoninsurance.net Minors under
              18 years of age may have the personal information that they
              provide to us deleted by sending an email to
              complaints@unisoninsurance.net requesting deletion. Please note
              that, while we make reasonable efforts to comply with such
              requests, deletion of your personal information does not ensure
              complete and comprehensive removal of that data from all systems.
            </p>

            <h5 className="mb-2">8.0 Your Rights:</h5>
            <p className="content_p mb-5">
              Subject to the laws of your country, you have the right to access,
              correct, delete or transfer your Personal Information that we
              hold, including your profile and preferences. You also have the
              right to object to certain processing and, where we have asked for
              your consent to process your Personal Information, to withdraw
              this consent. Where we process your Personal Information because
              we have a legitimate interest in doing so (as explained above),
              you also have a right to object to this. These rights may be
              limited in some situations – for example, where we can demonstrate
              that we have a legal requirement to process your Personal
              Information. If you are Brazilian user, you have additional rights
              to take confirmation on existence of your personal information,
              portability of your personal information to another service
              provider if applicable, anonymization or blocking of unnecessary
              information, information about public and private entities with
              which we have shared your data. You can assert your rights in the
              corresponding sections where such information was provided or by
              contacting us at retailhub@unisoninsurance.net
            </p>
            <p className="content_p">
              UNISON Insurance Brokers Private Limited Composite Insurance
              <br />
              Broker Validity- 15/04/2021 to 14/04/2024
              <br /> IBAI Membership No: 35149
              <br /> IRDAI Certificate No. 149
            </p>
          </div>
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MPrivacyPolicy;
