import { BrowserRouter, useNavigate } from "react-router-dom";
import { Strapi } from "strapi-frontend";
import { config } from "./strapiConfig";
import CommonRoutes from "./Routing/Routes/CommonRoutes";

function App() {
  Strapi.setConfig(config);
  return (
    <>
      <BrowserRouter>
        <CommonRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
