import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import Footer from "../../../Components/Desktop/Footer/Footer";

function TermsConditions() {
  return (
    <Box className="CmsPagesWrapper">
      <Navbar />

      <div className="cms_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="cms_header">
              <h2 className="mb-5">Terms of Use</h2>
            </div>
          </Grid>
          <Grid xs={12}>
            <div className="cms_content_sec">
              <h4 className="mb-6">Terms of Use Agreement</h4>
              <p className="content_p mb-5">
                By using the website, mobile app, or purchasing any kind of
                services/products, you authorize us to call or send promotional
                messages information using emails/WhatsApp and or any other
                online/offline or electronic medium for solicitation and
                procurement of various insurance products, or for cross-sell,
                and for services of Unison Insurance Broking Services Pvt Ltd.
              </p>

              <hr />
              <h4 className="mb-6">Acceptance of the terms</h4>
              <p className="content_p mb-5">
                While visiting this website, I acknowledge that all the terms
                and conditions of this website are acceptable to me, and I have
                even carefully read and understood all the terms and conditions
                of this website. And I agree that the terms and conditions of
                the website may be revised at any time.
              </p>

              <hr />
              <h4 className="mb-6">Limitation of liability</h4>
              <p className="content_p mb-5">
                Unison Insurance Broking Services Pvt Ltd does not guarantee
                that the contents of this website are 100% accurate. However, we
                have tried our best so that our website users can get the right
                guidance. In case if a virus attack occurs on your computer
                while using our website then we are not responsible for it.
                Because we do not take guarantee of the third-party content
                available on our website.
              </p>

              <hr />
              <h4 className="mb-6">Restriction on use of materials</h4>
              <p className="content_p mb-5">
                This website is owned by Unison Insurance Broking Services Pvt
                Ltd and No one has permission to distribute, modify or reuse the
                content of this website for public or commercial purposes.
              </p>

              <hr />
              <h4 className="mb-6">Foreign Jurisdiction</h4>
              <p className="content_p mb-5">
                This website can be accessed by any user whether it is from
                India or any foreign country but only Indian Laws will be
                applicable.
              </p>

              <hr />
              <h4 className="mb-6">Links and reference website</h4>
              <p className="content_p mb-5">
                This website may contain several third-party links that will
                redirect you to other websites, but we do not take any
                responsibility for the content of these third-party websites.
                These third-party referral links are provided only to give you
                additional information, but we do not take a guarantee of their
                content at all.
              </p>

              <hr />
              <h4 className="mb-6">Jurisdiction or Severability</h4>
              <p className="content_p mb-5">
                If an act against our mentioned terms and conditions is found,
                then we can take legal action in this condition.
              </p>

              <hr />
              <h4 className="mb-6">Refund and cancellation</h4>
              <p className="content_p mb-5">
                According to the IRDAI rules, you can cancel your insurance
                policy, but you will have to do this process within 15 days. On
                the other side, you can also process for the refund against the
                premium that you have paid, but this will be done according to
                the procedure of your insurer. We always encourage all our
                customers to read and understand the insurance policy document
                thoroughly.
              </p>

              <hr />
              <h4 className="mb-6">Due diligence requirements</h4>
              <p className="content_p">
                You agree and acknowledge that our company may ask for mandatory
                information required for KYC purposes and you as a customer are
                obliged to share.
              </p>
              <p className="content_p mt-6">
                The decision to take insurance from a particular insurance
                company is indeed a personal one, and individuals have the
                freedom to choose the insurance provider that best suits their
                needs and preferences.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </Box>
  );
}

export default TermsConditions;
