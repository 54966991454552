import CloseIcon from "@mui/icons-material/Close";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { COLORS } from "../../../../SupportingFiles/colors";
import "./../MNavbar.scss";
import CustomButton from "../../../InputFields/CustomButton/CustomButton";
import RKTextField from "../../../InputFields/RKTextField/RKTextField";

const MLoginPopup = ({ open, setOpen }: { open: boolean; setOpen: any }) => {
  const [loginStep, setLoginStep] = useState<"1" | "2">("1");
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [open]);
  return (
    <>
      {open ? (
        <Box
          sx={{
            position: "fixed",
            backgroundColor: "#ffffff",
            top: "0px",
            left: "0px",
            width: "100%",
            height: "100%",
            overflowY: "auto",
            zIndex: "999",
          }}
        >
          <Box position={"relative"}>
            <Button
              onClick={() => {
                setOpen(false);
              }}
              sx={{
                position: "absolute",
                right: "12px",
                top: "12px",
                maxWidth: "32px",
                minWidth: "32px",
                height: "32px",
                color: "rgba(235, 87, 87, 1)",
                backgroundColor: "transparent",
                borderRadius: "60px",
                border: "1.5px solid rgba(235, 87, 87, 1)",
                transition: "all ease-in-out 0.2s",
                "&:hover": {
                  backgroundColor: "transparent",
                  transform: "rotate(-90deg)",
                },
              }}
            >
              <CloseIcon style={{ height: "24px", width: "24px" }} />
            </Button>
            <Box className="bg_box_mobile">
              <img src="./images/login_mobile_bg.svg" alt="" />
            </Box>
            <Box className="formbox_mb" marginTop={"20px"}>
              {loginStep === "1" ? (
                <Box className="form">
                  <h2 style={{ marginBottom: "16px" }}>Login</h2>
                  <p
                    style={{
                      fontSize: "14px",
                      lineHeight: "20px",
                      color: "#606060",
                      marginBottom: "20px",
                    }}
                  >
                    {" "}
                    Please sign in to Continue
                  </p>
                  <Box padding={"0 30px"} marginBottom={"20px"}>
                    <Box
                      maxWidth={"256px"}
                      marginLeft={"auto"}
                      marginRight={"auto"}
                    >
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"Mobile"}
                        value={""}
                        attrName={[]}
                        value_update={() => {}}
                        warn_status={false}
                      />
                    </Box>
                  </Box>
                  <CustomButton
                    className="primaryBtn"
                    text={"Continue"}
                    onClick={() => setLoginStep("2")}
                    fullWidth={false}
                    variant="contained"
                  />
                </Box>
              ) : (
                <Box className="form">
                  <h2 style={{ marginBottom: "16px" }}>Verify Details</h2>
                  <Box
                    display={"flex"}
                    alignItems={"center"}
                    gap={"8px"}
                    marginBottom={"20px"}
                    justifyContent={"center"}
                  >
                    <p
                      style={{
                        fontSize: "14px",
                        lineHeight: "20px",
                        color: "#606060",
                      }}
                    >
                      {" "}
                      OTP sent successfully on{" "}
                      <span
                        style={{
                          fontSize: "14px",
                          lineHeight: "20px",
                          fontWeight: 700,
                          display: "block",
                        }}
                      >
                        98745 85635
                      </span>
                    </p>
                    <CustomButton
                      className="primaryBtn small"
                      text={"Edit"}
                      onClick={() => setLoginStep("1")}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Box>
                  <Box padding={"0 30px"} marginBottom={"20px"}>
                    <Box
                      maxWidth={"256px"}
                      marginLeft={"auto"}
                      marginRight={"auto"}
                    >
                      <RKTextField
                        class_name="inputField mb-6"
                        title={"OTP"}
                        value={""}
                        attrName={[]}
                        value_update={() => {}}
                        warn_status={false}
                      />
                    </Box>
                  </Box>
                  <Box display={"flex"} justifyContent={"center"} gap={"24px"}>
                    <CustomButton
                      className="primaryBtn"
                      text={"Verify OTP"}
                      onClick={() => {}}
                      fullWidth={false}
                      variant="contained"
                    />
                    <CustomButton
                      className="secondaryBtn"
                      text={"Resend"}
                      onClick={() => {}}
                      fullWidth={false}
                      variant="contained"
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default MLoginPopup;
