import React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../../Components/Mobile/Navbar/MNavbar";
import "./../../../SCSS/ProductPage.scss";
import FAQ from "../../../Components/FAQ/FAQ";
import MFooter from "../../../Components/Mobile/Footer/MFooter";
import Footer from "../../../Components/Desktop/Footer/Footer";
import CarProductContainer from "../../../Containers/CarProductContainer/CarProductContainer";

const CarProductPage = () => {
  const isMobile = useIsMobile();
  const hifaqs = [
    {
      title: "What is motor insurance?",
      desc: `Motor insurance, also known as auto insurance or car
                        insurance, is a policy that provides financial
                        protection in the event of accidents, theft, or damage
                        to your vehicle. It is a contract between you and an
                        insurance company.`,
    },
    {
      title: "Is motor insurance mandatory?",
      desc: `In many countries, motor insurance is mandatory. It is
                        typically required to cover third-party liability,
                        ensuring that if you cause an accident, the other
                        party's losses are covered. The level of coverage
                        required varies by jurisdiction.`,
    },
    {
      title: "What are the types of motor insurance coverage?",
      desc: (
        <>
          <p className="mb-2">
            Motor insurance typically offers three main types of coverage:
          </p>
          <ul style={{ paddingLeft: "20px" }}>
            <li style={{ listStyle: "disc" }}>
              <span style={{ fontWeight: 600 }}>Third-party liability: </span>
              Covers damage or injury caused to others by your vehicle.
            </li>
            <li style={{ listStyle: "disc" }}>
              <span style={{ fontWeight: 600 }}>Collision coverage: </span>
              Pays for repairs to your own vehicle in case of an accident.
            </li>
            <li style={{ listStyle: "disc" }}>
              <span style={{ fontWeight: 600 }}>Comprehensive coverage: </span>{" "}
              Provides coverage for theft, vandalism, and non-collision damage
              to your vehicle.
            </li>
          </ul>
        </>
      ),
    },
    {
      title: "How do insurance companies determine car insurance premiums?",
      desc: `Premiums are determined based on factors such as your
                        driving history, age, gender, location, the make and
                        model of your vehicle, and the coverage options you
                        choose. Safer drivers with a clean history generally pay
                        lower premiums.`,
    },
    {
      title: "What is a deductible?",
      desc: `A deductible is the amount you agree to pay out of
                        pocket before your insurance coverage kicks in. Higher
                        deductibles often result in lower premiums, but you'll
                        have to pay more if you make a claim.`,
    },
    {
      title: "Can I customize my motor insurance policy?",
      desc: `Yes, you can customize your policy by choosing the
                        coverage limits, deductible amount, and additional
                        options such as roadside assistance or rental car
                        coverage to suit your needs.`,
    },
  ];
  return (
    <Box className="ProductPageWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Box>
        <Box className="header-box">
          <Grid container columnSpacing={3}>
            <Grid xs={12} md={6}>
              <h3 className="mb-2">
                Car <span>Insurance</span>
              </h3>
              <p className="mb-6">
                Motor insurance is a vital component of responsible vehicle
                ownership. Whether you're a seasoned driver or a new car owner,
                understanding the ins and outs of motor insurance is crucial.
              </p>
              <img
                src="./images/carproduct-header-img.svg"
                alt=""
                className="car-img mb-6"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <CarProductContainer />
            </Grid>
          </Grid>
        </Box>

        <Box className="vehicleinfo-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>What Is Car Insurance?</h4>
              <p>
                Motor insurance is a crucial financial safeguard that helps
                individuals and businesses protect themselves against the
                potential financial burdens of accidents, theft, or damage to
                their vehicles, as well as legal liabilities associated with
                these events. It is often required by law in many countries to
                ensure that drivers have a minimum level of coverage to protect
                others on the road.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Box className="cartypes-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12} md={6}>
              <h4>Types of Car Insurance</h4>
              <ul className="types-list">
                <li>
                  <h6>Third Party Insurance</h6>
                  <p>
                    Third-party insurance only covers damages to a third-party
                    vehicle or property and injuries to/death of a third-party
                    person.
                  </p>
                </li>
                <li>
                  <h6>Standalone OD Cover</h6>
                  <p>
                    Standalone own damage insurance covers the damages only to
                    the insured car.
                  </p>
                </li>
                <li>
                  <h6>Comprehensive Insurance</h6>
                  <p>
                    Comprehensive car insurance covers third-party liabilities
                    as well as own damage under a single policy premium.
                  </p>
                </li>
              </ul>
            </Grid>
            <Grid xs={12} md={6}>
              <img
                src="./images/ci_type_sec_img.svg"
                alt=""
                className="typeimg"
              />
            </Grid>
          </Grid>
        </Box>

        <Box className="types-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>Add - ons/ Riders</h4>
            </Grid>
            <Grid xs={12}>
              <ul className="types-list">
                <li>
                  <h6>Personal Accident Cover for Passengers</h6>
                  <p>
                    This rider provides coverage for passengers in the insured
                    vehicle in case of accidental injuries or death. It is
                    usually offered as a lump sum amount.
                  </p>
                </li>
                <li>
                  <h6>Zero Depreciation Cover</h6>
                  <p>
                    Also known as Nil Depreciation or Bumper-to-Bumper cover,
                    this rider ensures that you receive the full claim amount
                    without any deduction for depreciation on vehicle parts
                    during repairs or replacements.
                  </p>
                </li>
                <li>
                  <h6>Engine Protector Cover</h6>
                  <p>
                    This rider provides coverage for damages to the engine and
                    its parts, which may not be covered under the standard
                    policy. It is especially useful for areas prone to flooding.
                  </p>
                </li>
                <li>
                  <h6>Roadside Assistance</h6>
                  <p>
                    Roadside assistance riders offer services like towing, fuel
                    delivery, flat tire repair, and more in case of breakdowns
                    or emergencies while on the road.
                  </p>
                </li>
                <li>
                  <h6>No Claim Bonus (NCB) Protector</h6>
                  <p>
                    This rider helps you retain your NCB even if you make a
                    claim during the policy term. NCB can lead to significant
                    premium discounts over time.
                  </p>
                </li>
                <li>
                  <h6>Consumables Cover</h6>
                  <p>
                    This rider covers the cost of consumable items such as
                    engine oil, nuts and bolts, lubricants, etc., which are
                    typically not covered in a standard policy.
                  </p>
                </li>
                <li>
                  <h6>Return to Invoice (RTI) Cover</h6>
                  <p>
                    In case of a total loss or theft of the insured vehicle,
                    this rider ensures that you receive the original invoice
                    price of the vehicle rather than the current market value.
                  </p>
                </li>
                <li>
                  <h6>Daily Allowance Benefit</h6>
                  <p>
                    It provides a daily allowance to cover the cost of
                    alternative transportation (like renting a vehicle) while
                    your insured vehicle is under repair due to an accident or
                    other covered perils.
                  </p>
                </li>
                <li>
                  <h6>Key Replacement Cover</h6>
                  <p>
                    If you lose or damage your vehicle keys, this rider covers
                    the cost of replacing them, including the electronic
                    components.
                  </p>
                </li>
                <li>
                  <h6>Accessories Cover</h6>
                  <p>
                    This rider extends coverage to vehicle accessories that are
                    not factory-fitted. It includes items like music systems,
                    GPS devices, and custom fittings.
                  </p>
                </li>
                <li>
                  <h6>Loss of Personal Belongings</h6>
                  <p>
                    This rider provides coverage for personal belongings that
                    may be damaged or stolen from the insured vehicle during an
                    accident or theft.
                  </p>
                </li>
                <li>
                  <h6>Medical Expenses Cover</h6>
                  <p>
                    It covers medical expenses for injuries sustained by the
                    insured or passengers while traveling in the insured vehicle
                    during an accident.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="faq-section">
          <Grid container columnSpacing={3}>
            <Grid md={6}>
              <h4 className="mb-6">FAQ's</h4>
              <img src="./images/faqs_img.svg" alt="" />
            </Grid>
            <Grid md={6}>
              <FAQ data={hifaqs} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default CarProductPage;
