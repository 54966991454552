import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import "./MFooter.scss";
import { useNavigate } from "react-router-dom";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function MFooter() {
  const navigate = useNavigate();
  return (
    <Box className="footer">
      <Grid container columnSpacing={3} className="footer-top">
        <Grid xs={12} className="text-center">
          <img
            src="./images/unison_white_logo_mobile.svg"
            alt=""
            height="27"
          ></img>
          <p>
            CIN: U67200GJ1998PTC034882 <br />
            IRDAI Comp. Broker Registration Cert. No. 149 <br /> Valid up to
            14/04/2027
          </p>
        </Grid>
        <Grid xs={6}>
          <h6>Products</h6>
          <ul className="product_list">
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                }}
              >
                Health insurance
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_CAR);
                }}
              >
                Car insurance
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_TW);
                }}
              >
                Bike insurance
              </Link>
            </li>
            {/* <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRODUCT_TERM);
                }}
              >
                Term insurance
              </Link>
            </li> */}
            {/* 
            <li>
              <Link>Investment Plans</Link>
            </li>
            <li>
              <Link>Travel insurance</Link>
            </li> */}
          </ul>
        </Grid>
        <Grid xs={6}>
          <h6>Services</h6>
          <ul className="product_list">
            <li>
              <a
                href=""
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claim
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate(COMMON_ROUTES.CONTACT);
                }}
              >
                Contact
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate(COMMON_ROUTES.COMPLAINT);
                }}
              >
                Complaint
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate(COMMON_ROUTES.CAREER);
                }}
              >
                Career
              </a>
            </li>
            <li>
              <a
                href=""
                onClick={() => {
                  navigate(COMMON_ROUTES.FAQ);
                }}
              >
                FAQ
              </a>
            </li>
          </ul>
        </Grid>
        <Grid xs={6}>
          <h6>Company</h6>
          <ul className="product_list pb-0">
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                }}
              >
                Terms and Conditions
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.PRIVACY_POLICY);
                }}
              >
                Privacy Policy
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.DISCLAIMER);
                }}
              >
                Disclaimer
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  navigate(COMMON_ROUTES.REFUND_CANCELLATION);
                }}
              >
                Refund & Cancellation
              </Link>
            </li>
          </ul>
        </Grid>
        <Grid xs={6}>
          <h6>Contact Us</h6>
          <ul className="product_list pb-5">
            <li>
              <a href="mailto:info@unisoninsurance.net">
                info@unisoninsurance.net
              </a>
            </li>
            <li>
              <a href="tel:18006785676" target="_blank">
                Sales: 18006785676
              </a>
            </li>
            <li>
              <a href="tel:18006789876" target="_blank">
                Claim: 18006789876
              </a>
            </li>
          </ul>
          <ul className="social_sites">
            {/* <li>
              <a href="javascript:void(0);">
                <img src="./images/twitter_icon.svg" alt=""></img>
              </a>
            </li> */}
            <li>
              <a
                href="https://www.facebook.com/unisoninsurance.net"
                target="_blank"
              >
                <img src="./images/fb_icon.svg" alt=""></img>
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/company/unison-insurance-broking-services-pvt-ltd"
                target="_blank"
              >
                <img src="./images/linkedin_icon.svg" alt=""></img>
              </a>
            </li>
            <li>
              <a
                href="https://youtube.com/@unisoninsurancebrokingservices?si=q4RiVgsmeEoNx3-M"
                target="_blank"
              >
                <img src="./images/youtube_icon.svg" alt=""></img>
              </a>
            </li>
          </ul>
        </Grid>
      </Grid>
      <Grid container columnSpacing={3} className="footer-bottom">
        <Grid xs={12}>
          <p className="copyrighjt_p">
            © Copyright {new Date().getFullYear()} UNISON Insurance Broking
            Services Pvt. Ltd. All Rights Reserved.
          </p>
        </Grid>
      </Grid>
    </Box>
  );
}

export default MFooter;
