export enum CAR_ROUTES {
  QUOTE = "/quote/car-insurance/car-quote",
  // POLICY_DETAILS = "/car-insurance/policy-details",
  // PROPOSAL_PAGE = "/car-insurance/proposal-page",
  // OWNER_DETAILS = "owner-details",
  // VEHICLE_DETAILS = "vehicle-details",
  // NOMINEE_DETAILS = "nominee-details",
  // ADDRESS_DETAILS = "address-details",
  // PREVIEW_DEATILS = "preview-details",
  // POLICY_CONFIRMATION = "/tw-insurance/payment-response",
}
