import { PayloadAction } from "@reduxjs/toolkit";
import { TAddressDetails } from "../../../Type/Common/TAddressDetails";
// import { TDropdown } from "../../../Type/Common/TDropdown";
// import { TDropdownData } from "../../../Type/DropdownData/DropdownData";
import {
  IDV_VALUE,
  TCkycDetails,
  TNomineeDetails,
  TOwnerDetails,
  TTWAddForm,
  TTWForm,
  TTWSlice,
  TVehicleDetails,
  TWDropdown,
} from "../../../Type/TW/TTwSlice";
import { TTWProduct } from "./TTWProduct";
import { TDropdownData } from "../../../Type/DropdownData/DropdownData";
import { TDropdown } from "../../../Type/Common/TDropdown";

function SET_TW_SLICE(state: TTWSlice, action: PayloadAction<TTWSlice>) {
  let data: TTWSlice = action.payload;
  return data;
}

function BULK_UPLOAD(state: TTWSlice, action: PayloadAction<TTWSlice>) {
  const data: TTWSlice = { ...state, ...action.payload };

  return data;
}

function ADD_FORM_DATA(state: TTWSlice, action: PayloadAction<TTWForm>) {
  let data: TTWSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, ...action.payload } };

  return data;
}

type keyTTWAddForm = keyof TTWAddForm;
function UPDATE_FORM_DATA(
  state: TTWSlice,
  action: PayloadAction<{ key: keyTTWAddForm; value: any }>
) {
  const { key, value } = action.payload;
  let data: TTWSlice = { ...state };

  data = { ...data, ADD_FORM: { ...data.ADD_FORM, [key]: value } };

  return data;
}

function ADD_FORM_RESPONSE(state: TTWSlice, action: PayloadAction<TTWAddForm>) {
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    ADD_FORM_RESPONSE: { ...data.ADD_FORM_RESPONSE, ...action.payload },
  };

  return data;
}

type valueTypeDropdown = keyof TWDropdown;
function SET_DROPDOWN_DATA(
  state: TTWSlice,
  action: PayloadAction<{ key: valueTypeDropdown; value: TDropdownData[] }>
) {
  const { key, value } = action.payload;
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    DROPDOWN_DATA: { ...data.DROPDOWN_DATA, [key]: value },
  };

  return data;
}

function UPDATE_QUOTE_LOADER(state: TTWSlice, action: PayloadAction<boolean>) {
  let data: TTWSlice = { ...state };

  data = { ...data, QUOTE_LOADER: action.payload };

  return data;
}

type KEY_DROPDOWN_MASTER = keyof TWDropdown;
const UPDATE_DROPDOWN_MASTER = (
  state: TTWSlice,
  action: PayloadAction<{ key: KEY_DROPDOWN_MASTER; value: TDropdown[] }>
) => {
  const { key, value } = action.payload;
  const data = {
    ...state,
    DROPDOWN_DATA: {
      ...state.DROPDOWN_DATA,
      [key]: value,
    },
  };

  return data;
};

const UPDATE_PLAN_TYPE = (
  state: TTWSlice,
  action: PayloadAction<
    "Third Party" | "Comprehensive" | "Bundle Policy" | "SAOD"
  >
) => {
  const data = {
    ...state,
    PLAN_TYPE: action.payload,
  };

  return data;
};

function OWNER_DETAILS(state: TTWSlice, action: PayloadAction<TOwnerDetails>) {
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    OWNER_DETAILS: { ...data.OWNER_DETAILS, ...action.payload },
  };

  return data;
}

function VEHICLE_DETAILS(
  state: TTWSlice,
  action: PayloadAction<TVehicleDetails>
) {
  let data: TTWSlice = { ...state };

  data = {
    ...data,
    VEHICLE_DETAILS: { ...data.VEHICLE_DETAILS, ...action.payload },
  };

  return data;
}

type ValueTypes = keyof TVehicleDetails;

function UPDATE_VEHICLE_DEATILS_INDIVISUAL(
  state: TTWSlice,
  action: PayloadAction<TVehicleDetails>
) {
  const updatedState: TTWSlice = {
    ...state,
    VEHICLE_DETAILS: {
      ...state.VEHICLE_DETAILS,
      ...action.payload,
    },
  };

  return updatedState;
}

type CKYCValueTypes = keyof TCkycDetails;

function CKYC_DETAILS(state: TTWSlice, action: PayloadAction<TCkycDetails>) {
  const updatedState: TTWSlice = {
    ...state,
    CKYC_DETAILS: {
      ...state.CKYC_DETAILS,
      ...action.payload,
    },
  };

  return updatedState;
}
type NomineeValueTypes = keyof TNomineeDetails;

function NOMINEE_DETAILS(
  state: TTWSlice,
  action: PayloadAction<{ key: NomineeValueTypes; value: any }[]>
) {
  const updatedState: TTWSlice = {
    ...state,
    NOMINEE_DETAILS: {
      ...state.NOMINEE_DETAILS,
      ...action.payload,
    },
  };

  return updatedState;
}

function ADD_FORM_RESPONSE_INDIVISUALLY(
  state: TTWSlice,
  action: PayloadAction<Partial<TTWAddForm>>
) {
  const updatedAddFormResponse = {
    ...state.ADD_FORM_RESPONSE,
    ...action.payload,
  };

  return {
    ...state,
    ADD_FORM_RESPONSE: updatedAddFormResponse,
  };
}

function SET_PRODUCT_LIST(
  state: TTWSlice,
  action: PayloadAction<TTWProduct[]>
) {
  return {
    ...state,
    PRODUCT_LIST: action.payload,
  };
}

function ADDRESS_DETAILS(
  state: TTWSlice,
  action: PayloadAction<TAddressDetails>
) {
  const updatedState: TTWSlice = {
    ...state,
    ADDRESS_DETAILS: {
      ...state.ADDRESS_DETAILS,
      ...action.payload,
    },
  };
  return updatedState;
}
const CHANGE_PAGE_STATUS = (
  state: TTWSlice,
  action: PayloadAction<boolean>
) => {
  const data = {
    ...state,

    PAGE_STATUS: action.payload,
  };

  return data;
};
const UPDATE_PREMIUM_DETAILS_IN_SELECTED_QUOTE = (
  state: TTWSlice,
  action: PayloadAction<any>
) => {
  const {} = action.payload;
  let data: TTWSlice = {
    ...state,
  };

  if (data.SELECTED_QUOTE_DATA.quotationDetail?.CompanyDetails) {
    data = {
      ...data,
      SELECTED_QUOTE_DATA: {
        ...state.SELECTED_QUOTE_DATA,

        quotationDetail: {
          ...data.SELECTED_QUOTE_DATA.quotationDetail,
          premiumDetails: {
            ...state.SELECTED_QUOTE_DATA.quotationDetail?.premiumDetails,
            ...action.payload,
          },
        },
      },
    };
  }
  return data;
};

function updateMultipleKeysInAddForm(
  state: TTWSlice,
  action: PayloadAction<Partial<TTWSlice["ADD_FORM"]>>
) {
  state.ADD_FORM = {
    ...state.ADD_FORM,
    ...action.payload,
  };
}

function updateMultipleKeysInAddFormResponse(
  state: TTWSlice,
  action: PayloadAction<Partial<TTWSlice["ADD_FORM_RESPONSE"]>>
) {
  state.ADD_FORM_RESPONSE = {
    ...state.ADD_FORM_RESPONSE,
    ...action.payload,
  };
}
const IDVAPPLY_STATUS = (state: TTWSlice, action: PayloadAction<boolean>) => {
  const data = {
    ...state,
    IDVApply_Status: action.payload,
  };
  return data;
};
const IDV_UPDATE = (state: TTWSlice, action: PayloadAction<IDV_VALUE>) => {
  console.log("action.payload ", action.payload);
  const data = {
    ...state,
    IDV_VALUE: {
      ...action.payload,
    },
  };

  return data;
};
const Home_loader = (state: TTWSlice, action: PayloadAction<boolean>) => {
  console.log("action.payload ", action.payload);
  const data = {
    ...state,
    Home_Loader: action.payload,
  };

  return data;
};
export const TW_REDUCERS = {
  BULK_UPLOAD,
  SET_TW_SLICE,
  ADD_FORM_DATA,
  UPDATE_FORM_DATA,
  IDVAPPLY_STATUS,
  IDV_UPDATE,
  ADD_FORM_RESPONSE,
  SET_DROPDOWN_DATA,
  UPDATE_QUOTE_LOADER,
  UPDATE_DROPDOWN_MASTER,
  UPDATE_PLAN_TYPE,
  OWNER_DETAILS,
  VEHICLE_DETAILS,
  Home_loader,
  UPDATE_VEHICLE_DEATILS_INDIVISUAL,
  CKYC_DETAILS,
  ADDRESS_DETAILS,
  NOMINEE_DETAILS,
  CHANGE_PAGE_STATUS,
  ADD_FORM_RESPONSE_INDIVISUALLY,
  UPDATE_PREMIUM_DETAILS_IN_SELECTED_QUOTE,
  updateMultipleKeysInAddForm,
  updateMultipleKeysInAddFormResponse,
  SET_PRODUCT_LIST,
};

export type TWReducers = typeof TW_REDUCERS;
