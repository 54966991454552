import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useRef, useState } from "react";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Components/InputFields/TextArea/TextArea";
import Footer from "../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../SupportingFiles/colors";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";

function Career() {
  const [contactFields, setContactFields] = useState<{
    name: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    apply: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    apply: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("Upload CV");

  const handleFileSelect = () => {
    if(fileInputRef.current){
      fileInputRef.current.click();
    }
  }

  const handleFileChange =(event:any) =>{
     const selectedFile = event.target.files && event.target.files[0]
     if(selectedFile){
      setFile(selectedFile.name)
     }else{
      setFile("Upload your CV in .jpg, .png, or .pdf format")
     }
  }
  
  return (
    <Box className="CmsPagesWrapper">
      <Navbar />

      <div className="cms_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="cms_header text-center mb-12">
              <h2 className="mb-5">Welcome to Unison Careers</h2>
              <p>Do you have it in you? We are looking for you!</p>
              <p>
                Become a part of growing team that values your opinion, ideas,
                and intellectual courage.
              </p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} justifyContent={"center"}>
          <Grid sm={12} lg={6} xl={6}>
            <div className="mb-6">
              <img src="./images/join_team.svg" alt="" className="img-fluid" />
            </div>
            <p className="mb-4">
              Contribute to our growth by using your skills, grow your career in
              insurance domain and increase your industry impact.
            </p>
            <p>We value hard workers and reward with unlimited opportunity.</p>
          </Grid>
          <Grid sm={12} lg={6} xl={6}>
            <div className="details_input_section" style={{ margin: "auto" }}>
              <Grid container>
                <Grid xs={12} marginBottom={"20px"} textAlign={"center"}>
                  <h5>
                    Tell us Your Dream Role{" "}
                    <a
                      href="mailto:info@unisoninsurance.net"
                      target="_blank"
                      style={{ color: COLORS.secondary }}
                    >
                      info@unisoninsurance.net
                    </a>
                  </h5>
                </Grid>
                <Grid xs={12}>
                  <Box className="upload_section mb-6">
                    <CloudUploadIcon />
                    <Box className="fileUpload">
                      <label className="custom-file-upload">
                        <span>{file}</span>
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          onClick={handleFileSelect}
                        />
                      </label>
                    </Box>
                  </Box>
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Full Name"}
                    value={contactFields.name.value}
                    attrName={[
                      "name",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={contactFields.name.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Mobile"}
                    value={contactFields.mobile.value}
                    attrName={[
                      "mobile",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={contactFields.mobile.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"I want to apply for"}
                    value={contactFields.apply.value}
                    attrName={[
                      "apply",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={contactFields.apply.warning}
                  />
                </Grid>
                <Grid xs={12} textAlign={"center"}>
                  <CustomButton
                    className="primaryBtn disabled"
                    text={"Submit Details"}
                    variant={"text"}
                    fullWidth={false}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </Box>
  );
}

export default Career;
