import React, { useRef, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Components/InputFields/TextArea/TextArea";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { COLORS } from "../../../SupportingFiles/colors";

function MCareer() {
  const [contactFields, setContactFields] = useState<{
    name: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    apply: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    apply: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState("Upload CV");

  const handleFileSelect =() =>{
    if(fileInputRef.current){
        fileInputRef.current.click();
    }
  }
  const handleFileChange = (event:any) => {
    const selectedFile = event.target.files && event.target.files[0];
    if(selectedFile){
        setFile(selectedFile.name);
    }else{
        setFile("Upload your CV in .jpg, .png, or .pdf format");
    }
  }
  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12} className="mb-6">
          <div className="cms_header text-center">
            <h2 className="mb-3">Welcome to Unison Careers</h2>
            <p>Do you have it in you? We are looking for you!</p>
            <p>
              Become a part of growing team that values your opinion, ideas, and
              intellectual courage.
            </p>
          </div>
        </Grid>
        <Grid xs={12} className="mb-4 text-center">
          <img
            src="./images/join_team.svg"
            alt=""
            className="img-fluid"
            style={{ maxHeight: "173px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12} textAlign={"center"}>
          <h5 style={{ fontSize: "14px", lineHeight: "21px" }}>
            Tell us Your Dream Role{" "}
            <a
              href="mailto:info@unisoninsurance.net"
              target="_blank"
              style={{ color: COLORS.secondary }}
            >
              info@unisoninsurance.net
            </a>
          </h5>
        </Grid>
        <Grid xs={12}>
          <Box className="mupload_section">
            <CloudUploadIcon />
            <Box className="fileUpload">
              <label className="custom-file-upload">
                <span>{file}</span>
                <input
                  type="file"
                  ref={fileInputRef}
                  onChange={handleFileChange}
                  onClick={handleFileSelect}
                />
              </label>
            </Box>
          </Box>
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={contactFields.name.value}
            attrName={["name", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.name.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            value={contactFields.mobile.value}
            attrName={["mobile", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.mobile.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"I want to apply for"}
            value={contactFields.apply.value}
            attrName={["apply", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.apply.warning}
          />
        </Grid>
        <Grid xs={12} textAlign={"center"}>
          <CustomButton
            className="primaryBtn disabled"
            text={"Submit Details"}
            variant={"text"}
            fullWidth={false}
          />
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MCareer;
