import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Aboutus from "../../Pages/Desktop/CmsPage/Aboutus";
import MAboutus from "../../Pages/Mobile/CmsPagesMobile/MAboutus";

const AboutusContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MAboutus /> : <Aboutus />}</>;
};

export default AboutusContainer;
