import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import Aboutus from "../../Pages/Desktop/CmsPage/Aboutus";
import MAboutus from "../../Pages/Mobile/CmsPagesMobile/MAboutus";
import MRefundCancellations from "../../Pages/Mobile/CmsPagesMobile/MRefundCancellations";
import RefundCancellations from "../../Pages/Desktop/CmsPage/RefundCancellations";

const RefundCancellationContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MRefundCancellations /> : <RefundCancellations />}</>;
};

export default RefundCancellationContainer;
