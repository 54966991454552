import React from "react";
import useIsMobile from "../../SupportingFiles/MobileProvider";
import TermsConditions from "../../Pages/Desktop/CmsPage/TermsConditions";
import MTermsConditions from "../../Pages/Mobile/CmsPagesMobile/MTermsConditions";

const TermsConditionsContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MTermsConditions /> : <TermsConditions />}</>;
};

export default TermsConditionsContainer;
