import React from "react";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import useIsMobile from "../../../SupportingFiles/MobileProvider";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import MNavbar from "../../../Components/Mobile/Navbar/MNavbar";
import "./../../../SCSS/ProductPage.scss";
import FAQ from "../../../Components/FAQ/FAQ";
import MFooter from "../../../Components/Mobile/Footer/MFooter";
import Footer from "../../../Components/Desktop/Footer/Footer";
import HealthProductContainer from "../../../Containers/HealthProductContainer/HealthProductContainer";

const HealthProductPage = () => {
  const isMobile = useIsMobile();
  const hifaqs = [
    {
      title: "What is health insurance?",
      desc: ` Health insurance is a contract between you and an
                        insurance company that helps cover the cost of your
                        medical expenses. In exchange for regular premium
                        payments, the insurance company provides financial
                        protection when you need medical care.`,
    },
    {
      title: "Why do I need health insurance?",
      desc: `Health insurance provides a safety net by covering
                        medical costs that can be expensive and unexpected. It
                        ensures that you can access quality healthcare without
                        worrying about the financial burden.`,
    },
    {
      title: "How do I choose the right health insurance plan?",
      desc: `To choose the right plan, consider your healthcare
                        needs, budget, and preferred healthcare providers.
                        Compare the coverage, premiums, deductibles, and
                        out-of-pocket costs of different plans.`,
    },
    {
      title: "When can I enroll in a health insurance plan?",
      desc: `Enrollment periods vary, but typically include an annual
                        open enrollment period and special enrollment periods
                        triggered by life events like marriage, birth, or loss
                        of other coverage.`,
    },
    {
      title: " What is a premium, deductible, and copayment?",
      desc: `A premium is the amount you pay for your health
                        insurance every month. A deductible is the amount you
                        pay for covered health care services before your
                        insurance plan starts to pay. A copayment is a fixed
                        amount you pay for each medical service or prescription.`,
    },
    {
      title: " Can I keep my current doctor with my health insurance plan?",
      desc: `The ability to keep your current doctor depends on the
                        network of providers associated with your plan. Some
                        plans require you to choose from a specific network of
                        doctors and hospitals.`,
    },
    {
      title: "How can I lower my health insurance premiums?",
      desc: `You can lower your premiums by choosing a plan with a
                        higher deductible, maintaining a healthy lifestyle, and
                        taking advantage of subsidies if you qualify.`,
    },
    {
      title:
        "What should I do if I have a problem with my health insurance coverage or a claim?",
      desc: `If you have issues with your coverage or a claim,
                        contact your insurance company's customer service. You
                        can also reach out to your state's insurance department
                        for assistance.`,
    },
    {
      title: "What happens if I miss a premium payment?",
      desc: `Missing premium payments can lead to a lapse in
                        coverage. Some plans offer a grace period, but it's
                        crucial to pay premiums on time to maintain continuous
                        coverage.`,
    },
  ];
  return (
    <Box className="ProductPageWrapper">
      {isMobile ? <MNavbar /> : <Navbar />}

      <Box>
        <Box className="header-box">
          <Grid container columnSpacing={3}>
            <Grid xs={12} md={6}>
              <h3 className="mb-2">
                Health <span>Insurance</span>
              </h3>
              <p className="mb-6">
                Health insurance is an essential aspect of safeguarding your
                well-being and financial stability. In an era where healthcare
                costs are constantly rising, having the right health insurance
                plan can make all the difference in accessing quality medical
                care without breaking the bank.
              </p>
              <img
                src="./images/healthproduct-header-img.svg"
                alt=""
                className="mb-6"
              />
            </Grid>
            <Grid xs={12} md={6}>
              <HealthProductContainer />
            </Grid>
          </Grid>
        </Box>

        <Box className="info-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>What is Health Insurance?</h4>
              <p>
                Health insurance is a contract between you and an insurance
                company that helps cover the cost of your medical expenses. In
                exchange for regular premium payments, the insurance company
                provides financial protection when you need medical care.
              </p>
            </Grid>
          </Grid>
        </Box>

        <Box className="types-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>How to select a health insurance plan</h4>
              <p className="mb-4">
                Selecting a health insurance plan can be a crucial decision that
                impacts your healthcare costs and access to medical services.
                Here's a step-by-step guide to help you choose the right health
                insurance plan:
              </p>
            </Grid>
            <Grid xs={12}>
              <ul className="types-list">
                <li>
                  <h6>Assess Your Needs:</h6>
                  <p>
                    Consider your current health status, any ongoing medical
                    conditions, and the healthcare needs of your family members.
                  </p>
                </li>
                <li>
                  <h6>Understand Health Insurance Basics:</h6>
                  <p>
                    Familiarize yourself with common health insurance
                    terminology, including premiums, deductibles, copayments,
                    coinsurance, and out-of-pocket maximums.
                  </p>
                </li>
                <li>
                  <h6>Determine Your Budget:</h6>
                  <p>
                    Set a budget for your health insurance premiums and
                    out-of-pocket expenses. Consider what you can comfortably
                    afford to pay and how much you're willing to pay for medical
                    services when needed.
                  </p>
                </li>
                <li>
                  <h6>Explore Plan Types:</h6>
                  <p>
                    Research the different types of health insurance plans
                    available.
                  </p>
                </li>
                <li>
                  <h6>Check Network- Hospitals:</h6>
                  <p>
                    Verify if your preferred healthcare providers, doctors,
                    hospitals, and specialists are in-network for the plans
                    you're considering.
                  </p>
                </li>
                <li>
                  <h6>Evaluate Benefits and Coverage</h6>
                  <p>
                    Review the benefits offered by each plan, including coverage
                    for doctor visits, hospital stays, prescription drugs,
                    preventive care, and mental health services.
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="types-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>Types of Health Insurance</h4>
            </Grid>
            <Grid xs={12}>
              <ul className="types-list">
                <li>
                  <h6>Individual Health Insurance</h6>
                  <p>
                    This type of insurance covers an individual's medical
                    expenses, including hospitalization, surgeries, and other
                    medical treatments.
                  </p>
                </li>
                <li>
                  <h6>Family Floater Health Insurance</h6>
                  <p>
                    Family floater plans cover the entire family under a single
                    policy. The sum insured can be used by any family member
                    when needed.
                  </p>
                </li>
                <li>
                  <h6>Senior Citizen Health Insurance</h6>
                  <p>
                    These policies are designed for individuals above a certain
                    age (usually 60 or 65 years) and provide coverage for
                    age-related health issues
                  </p>
                </li>
                <li>
                  <h6>Group Health Insurance</h6>
                  <p>
                    Group health insurance is offered by employers to cover
                    their employees' medical expenses. It can also be extended
                    to employees' family members.
                  </p>
                </li>
                <li>
                  <h6>Maternity Health Insurance</h6>
                  <p>
                    Maternity policies cover expenses related to pregnancy,
                    childbirth, and postnatal care. They may include coverage
                    for pre-and post-natal check-ups, hospitalization, and
                    newborn baby care.
                  </p>
                </li>
                <li>
                  <h6>Critical Illness Insurance</h6>
                  <p>
                    This type of insurance provides a lump sum payout if the
                    policyholder is diagnosed with a critical illness listed in
                    the policy, such as cancer, heart attack, or stroke. The
                    payout can be used to cover treatment costs or other
                    expenses.
                  </p>
                </li>
                <li>
                  <h6>Top-Up and Super Top-Up Plans</h6>
                  <p>
                    These plans provide additional coverage when the sum insured
                    under the primary health insurance policy is exhausted.
                    Top-up plans kick in after a certain threshold is reached,
                    while super top-up plans cover expenses once the aggregate
                    of multiple claims crosses a defined limit.
                  </p>
                </li>
                <li>
                  <h6>Government Health Insurance Schemes</h6>
                  <p>
                    The Indian government offers various health insurance
                    schemes, such as Ayushman Bharat Pradhan Mantri Jan Arogya
                    Yojana (AB-PMJAY) and various state-specific health
                    insurance programs.
                  </p>
                </li>
                <li>
                  <h6>Personal Accident Insurance</h6>
                  <p>
                    While not strictly health insurance, personal accident
                    policies cover accidental injuries, disability, and death.
                    They can complement a health insurance plan by providing
                    additional coverage
                  </p>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="types-sec">
          <Grid container columnSpacing={3}>
            <Grid xs={12}>
              <h4>Key Terms in Health Insurance</h4>
            </Grid>
            <Grid xs={12}>
              <ul className="keyterms-list">
                <li>
                  <h6>Waiting period</h6>
                  <p>
                    Waiting period in health insurance plans is the period for
                    which you need to wait before getting the insurance
                    benefits. It begins from the date of policy commencement,
                    and the insured cannot claim health insurance benefits
                    during this time.
                  </p>
                </li>
                <li>
                  <h6>Co Pay</h6>
                  <p>
                    Co-pay in health insurance is a clause, wherein the insured
                    agrees to bear a specific portion of the incurred medical
                    expenses. It is usually a fixed percentage of the claim
                    amount, which the policyholder pays at the time of a claim.
                  </p>
                </li>
                <li>
                  <h6>Preventive health check-up</h6>
                  <p>
                    A preventive health check-up comprises various tests and
                    diagnoses that help an individual to understand their health
                    at a microscopic level allowing them to make amends in their
                    lifestyle or habits to live a healthy life.
                  </p>
                </li>
                <li>
                  <h6>Grace period</h6>
                  <p>
                    Grace period is the time during which you can pay your
                    health insurance premium after the renewal due date. You
                    should buy a health insurance policy that offers the maximum
                    grace period as you get more time to renew your policy even
                    after the due date.
                  </p>
                </li>
                <li>
                  <h6>Restore benefit</h6>
                  <p>
                    The restoration benefit helps the sum insured of your health
                    insurance policy to be restored up to the maximum limit as
                    soon as it gets exhausted after a claim.
                  </p>
                </li>
                <li>
                  <h6>NCB</h6>
                  <p>
                    NCB or No claim bonus in health insurance is the bonus your
                    insurer rewards you on a claim-free year.
                  </p>
                </li>
                <li>
                  <h6>Sub Limits</h6>
                  <p>
                    A sub-limit is a predetermined cap that is placed on parts
                    of the claim amount by the insurer. These limits can be
                    placed on hospital room rent, treatment of certain diseases,
                    post-hospitalization charges, etc.
                  </p>
                </li>
                <li>
                  <h6>Network Hospitals</h6>
                  <p>
                    A Network Hospital is a hospital that enters into an
                    agreement with a health insurance company to offer cashless
                    medical treatment to the insured
                  </p>
                </li>
                <li>
                  <h6>Riders</h6>
                  <p>
                    Riders are extra coverage or benefits that a policyholder
                    can buy over and above the base health insurance coverage on
                    the payment of an additional premium.
                  </p>
                  <ul>
                    <li>
                      <p>
                        <span>Critical Illness Rider</span>
                        The critical illness rider provides a lump sum benefit
                        to the policyholder equal to the sum insured amount on
                        the diagnosis of a listed critical illness
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>OPD Rider</span>
                        The OPD rider pays for the medical expenses incurred in
                        the outpatient department, such as doctor’s
                        consultations, diagnostic tests, medicines, etc.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Maternity Rider</span>
                        The maternity rider pays for the medical expenses
                        arising out of pregnancy and childbirth, including
                        delivery expenses, pre & post-natal expenses & newborn
                        baby expenses. The coverage is usually available after a
                        waiting.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Room Rent Waiver</span>
                        The room rent waiver rider removes the coverage limit on
                        the hospital room rent allowing policyholders to opt for
                        a room with higher room rent.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Unlimited Restoration of Sum Insured</span>
                        The unlimited restoration of sum insured rider restores
                        the sum insured of the base policy unlimited times in a
                        policy year in case the original amount gets exhausted
                        on raising single or multiple claims.
                      </p>
                    </li>
                    <li>
                      <p>
                        <span>Personal Accident Rider</span>
                        The personal accident rider provides a lump sum benefit
                        to the policyholder if he/she suffers from a
                        total/partial disability or dies in an accident. In case
                        of accidental death, the lump sum benefit is paid to the
                        family member/nominee of the policyholder.
                      </p>
                    </li>
                  </ul>
                </li>
              </ul>
            </Grid>
          </Grid>
        </Box>

        <Box className="faq-section">
          <Grid container columnSpacing={3}>
            <Grid md={6}>
              <h4 className="mb-6">FAQ's</h4>
              <img src="./images/faqs_img.svg" alt="" />
            </Grid>
            <Grid md={6}>
              <FAQ data={hifaqs} />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {isMobile ? <MFooter /> : <Footer />}
    </Box>
  );
};

export default HealthProductPage;
