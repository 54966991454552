import Claim from "../../Pages/Desktop/CmsPage/Claim";
import Complaint from "../../Pages/Desktop/CmsPage/Complaint";
import MClaim from "../../Pages/Mobile/CmsPagesMobile/MClaim";
import MComplaint from "../../Pages/Mobile/CmsPagesMobile/MComplaint";
import useIsMobile from "../../SupportingFiles/MobileProvider";

const ClaimContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MClaim /> : <Claim />}</>;
};

export default ClaimContainer;
