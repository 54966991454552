import Contact from "../../Pages/Desktop/CmsPage/Contact";
import MContact from "../../Pages/Mobile/CmsPagesMobile/MContact";
import useIsMobile from "../../SupportingFiles/MobileProvider";

const ContactContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MContact /> : <Contact />}</>;
};

export default ContactContainer;
