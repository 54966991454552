import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Components/InputFields/TextArea/TextArea";
import Footer from "../../../Components/Desktop/Footer/Footer";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";

function Complaint() {
    const [contactFields, setContactFields] = useState<{
        name: { value: string, warning: boolean };
        mobile: { value: string, warning: boolean };
        email: { value: string, warning: boolean };
        description: { value: string, warning: boolean };
    }>({
        name: { value: '', warning: false },
        mobile: { value: '', warning: false },
        email: { value: '', warning: false },
        description: { value: '', warning: false },
    })

    const fieldsUpdateState = (attrName: any, value: any) => {
        attrName[3]({ ...attrName[2], [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value } });
    };
    return (
      <Box className="CmsPagesWrapper">
        <Navbar />

        <div className="cms_wrapper">
          <Grid container spacing={3}>
            <Grid xs={12}>
              <div className="cms_header text-center mb-12">
                <h2 className="mb-5">File a Complaint</h2>
                <p>Please enter details. We want to hear from you!</p>
              </div>
            </Grid>
          </Grid>
          <Grid container spacing={3} justifyContent={"center"}>
            <Grid sm={12} lg={6} xl={6}>
              <div className="text-center">
                <img
                  src="./images/complaint.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Grid>
            <Grid sm={12} lg={6} xl={6}>
              <div className="details_input_section">
                <Grid container>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Full Name"}
                      value={contactFields.name.value}
                      attrName={[
                        "name",
                        "value",
                        contactFields,
                        setContactFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={contactFields.name.warning}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Mobile"}
                      value={contactFields.mobile.value}
                      attrName={[
                        "mobile",
                        "value",
                        contactFields,
                        setContactFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={contactFields.mobile.warning}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <RKTextField
                      class_name="inputField mb-6"
                      title={"Email"}
                      value={contactFields.email.value}
                      attrName={[
                        "email",
                        "value",
                        contactFields,
                        setContactFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={contactFields.email.warning}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextArea
                      class_name="inputField mb-6"
                      title={"What are you facing problems?"}
                      value={contactFields.description.value}
                      placeholder={"Enter description here"}
                      attrName={[
                        "description",
                        "value",
                        contactFields,
                        setContactFields,
                      ]}
                      value_update={fieldsUpdateState}
                      warn_status={contactFields.description.warning}
                    />
                  </Grid>
                  <Grid xs={12}>
                    <CustomButton
                      className="primaryBtn disabled"
                      text={"Submit Details"}
                      variant={"text"}
                      fullWidth={false}
                    />
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </div>

        <Footer />
      </Box>
    );
}

export default Complaint;
