import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

function MFaqPage() {
  const [expanded, setExpanded] = React.useState<string | false>("panel0");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const generalQuestions = [
    {
      ques: "What does unison do?",
      ans: `We are an insurance broking company, licensed by the IRDA. This gives us the authority to sell insurance policies across all insurance companies in India.
            Through our website stgbima.unisoninsurance.net, we help you find the right insurance policy for you from a choice of plans available in the market. You can research about insurance and which policy should you buy, compare from various plans, shortlist and buy the plans instantly at the click of a button.
            Now insurance buying is as simple and quick as buying plane tickets, books and clothes online.`,
    },
    {
      ques: "How are we different from other insurance comparison websites?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            There are 3 main reasons how we are different from other websites.
          </p>
          <ol type="1" style={{ paddingLeft: "16px" }}>
            <li style={{ marginBottom: "6px" }}>
              SMART COMPARISON ENGINE: We’ve developed a smart comparison engine
              that uses business inputs from our insurance experts with years of
              experience in the industry and algorithms designed by our
              technology experts. When you put in your details, it processes
              that information and shows you the shortlisted and recommended
              plans best for you in terms of both price as well as features.
            </li>
            <li style={{ marginBottom: "6px" }}>
              KNOWLEDGEABLE SUPPORT TEAM: We’ve built a team of experienced,
              knowledgeable and friendly people. Our team is equipped and
              motivated to help you solve all your queries and issues. Have
              trouble with claims? Need to understand your policy features.
              Looking for help on your paperwork? Your dedicated relationship
              manager is always there for you. Since we have a broking license,
              in the rare chance that the need should arise, we can also help to
              resolve disputes.
            </li>
            <li style={{ marginBottom: "6px" }}>
              EASY POLICY MANAGEMENT: Do you need a tax certificate? Can't trace
              your policy copy? Need to change your contact details? Login and
              find everything you need. Or simply call your relationship manager
            </li>
          </ol>
        </>
      ),
    },
    {
      ques: "How do we make money?",
      ans: `We make money through a fixed commission that the insurance company pays us when you buy a policy through us. Like all industries, we get a standard commission from all the companies we work with. This helps us remain unbiased in our choice and get the best plan for you. We strongly believe that it make good business sense if we root for our customers, remain transparent, clean and unbiased.`,
    },
    {
      ques: "What kind of insurance can I buy from unison?",
      ans: `Currently you can buy all general insurance products. This includes motor insurance, health insurance, home insurance and travel insurance. In the near future, you would also be able to buy life insurance from us.`,
    },
    {
      ques: " How long does it take to buy insurance on unison?",
      ans: `Once you input your details, it takes you less than 5 minutes to buy your policy. In case of any issues, our support team is available on call or chat to assist you.`,
    },
    {
      ques: "How can I pay for the policy I buy?",
      ans: `You can pay online through credit card, debit card or net-banking. If this doesn’t work, you can also send us a cheque. You will need to get in touch with our support team for cheque payments.`,
    },
    {
      ques: "I need to understand features on the policy I wish to buy. Who can I talk to?",
      ans: `At unison, we have worked hard to ensure you understand all the features of the plan while shortlisting on the website itself. If you still need help, our support team is available to chat with you on the website. Alternatively, if you want to talk to someone, feel free to call us on 1800 123 624276`,
    },
    {
      ques: "How secure is the transaction while buying insurance online?",
      ans: `Your transaction is completely secure with us. We work with the best payment gateway available in the country. Not only that, we also strive to ensure your information is completely safe and private.`,
    },
    {
      ques: "What should you consider while buying Mediclaim Policy?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            A number of members and relations to be covered- Because this will
            be the basis of buying the policy.
          </p>
          <ol type="1" style={{ paddingLeft: "16px" }}>
            <li>Type of coverage – Individual, family floater or group.</li>
            <li>
              Sum insured or coverage amount – Ensure that you consider future
              expenses too.
            </li>
            <li>
              Room rent – Check for the capping amount or criteria defined, if
              applicable.
            </li>
            <li>
              Room rent – Check for the capping amount or criteria defined, if
              applicable.
            </li>
            <li>
              Sub-limits or co-pay applicable – Check for the sub-limits
              applicable for some specific list of ailments or package
              treatments. Also, check if the plan has co-pay feature.
            </li>
            <li>
              Network hospital list – Check for the list of network hospitals
              and try buying a policy which covers your regular hospitals.
            </li>
            <li>
              The Policy wording – Most important thing to check before buying a
              policy. In case you are not sure about certain terms or conditions
              you can always contact the insurer for clarification.
            </li>
          </ol>
        </>
      ),
    },
    {
      ques: "How to make a claim?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Claims can be registered in two ways:
          </p>
          <ol type="1" style={{ paddingLeft: "16px" }}>
            <li style={{ marginBottom: "6px" }}>
              Cashless claims: You must contact TPA help desk at the time of
              hospital admission. Submit the claim form along with the doctor’s
              reports to seek approval. Once the request is approved, TPA
              settles the bill directly with the hospital.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Reimbursement claim: Once hospitalization is completed and the
              hospital bill is settled, you need to send the claim form,
              discharge form along with the doctor’s prescriptions and reports
              to the insurer. The insurer investigates the documents submitted
              and then revert backs with the decision on claim settlement.
            </li>
          </ol>
        </>
      ),
    },
  ];

  const [mqexpanded, setmqExpanded] = React.useState<string | false>(
    "mqpanel0"
  );

  const handleChangemq =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setmqExpanded(isExpanded ? panel : false);
    };

  const mqQuestions = [
    {
      ques: "Can I cancel my policy? If yes, will I get my premium back?",
      ans: (
        <>
          <p className="mb-3">
            Yes you can cancel a policy after you buy it. A free look period of
            15 days is provided to you after buying a policy to understand the
            terms and conditions. In case there is any objectionable clause, you
            can cancel the policy and get a refund. Stamp duty, expenses on
            medical check-up and proportionate risk premium (the number of days
            that the insurance company was at a risk of bearing your health
            expenses) would be calculated while the premium amount is refunded.
            Refer the policy termination or policy cancellation section in your
            policy wording to know the amount that would be refunded.
          </p>
          <p>
            Note: For a refund, there shouldn't have been any claim during the
            policy period.
          </p>
        </>
      ),
    },
    {
      ques: "How many general insurance companies are there in India?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Any insurance policy insuring anything other than a person's life is
            called as general insurance. General insurance policies are
            classified as follows:
          </p>
          <ol type="1" style={{ paddingLeft: "16px", marginBottom: "12px" }}>
            <li style={{ marginBottom: "6px" }}>Health Insurance</li>
            <li style={{ marginBottom: "6px" }}>Motor Insurance</li>
            <li style={{ marginBottom: "6px" }}>Home Insurance</li>
            <li style={{ marginBottom: "6px" }}>Travel Insurance</li>
            <li style={{ marginBottom: "6px" }}>Commercial Insurance</li>
            <li style={{ marginBottom: "6px" }}>Marine Insurance</li>
          </ol>
          <p style={{ marginBottom: "12px" }}>
            Any insurance policy insuring anything other than a person's life is
            called as general insurance. General insurance policies are
            classified as follows:
          </p>
          <ul style={{ paddingLeft: "16px", listStyleType: "circle" }}>
            <li style={{ marginBottom: "6px" }}>Acko General Insurance Ltd.</li>
            <li style={{ marginBottom: "6px" }}>
              Aditya Birla Health Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Agriculture Insurance Company of India Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              HDFC ERGO Health Insurance Limited (Formerly Apollo Munich Health
              Insurance Company Limited)
            </li>
            <li style={{ marginBottom: "6px" }}>
              Bajaj Allianz General Insurance Co. Ltd
            </li>
            <li style={{ marginBottom: "6px" }}>
              Bharti AXA General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Cholamandalam MS General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Manipal Cigna Health Insurance Company Limited
            </li>
            <li style={{ marginBottom: "6px" }}>Navi General Insurance Ltd.</li>
            <li style={{ marginBottom: "6px" }}>
              Edelweiss General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>ECGC Ltd.</li>
            <li style={{ marginBottom: "6px" }}>
              Future Generali India Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Go Digit General Insurance Ltd
            </li>
            <li style={{ marginBottom: "6px" }}>
              HDFC ERGO General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              ICICI LOMBARD General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              IFFCO TOKIO General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Kotak Mahindra General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Liberty General Insurance Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Magma HDI General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Max Bupa Health Insurance Co. Ltd
            </li>
            <li style={{ marginBottom: "6px" }}>National Insurance Co. Ltd.</li>
            <li style={{ marginBottom: "6px" }}>
              Raheja QBE General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Reliance General Insurance Co. Ltd
            </li>
            <li style={{ marginBottom: "6px" }}>
              Reliance Health Insurance Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Care Health Insurance Ltd (formerly known as Religare Health
              Insurance Co. Ltd.)
            </li>
            <li style={{ marginBottom: "6px" }}>
              Royal Sundaram General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              SBI General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Shriram General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Star Health & Allied Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Tata AIG General Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              The New India Assurance Co. Ltd
            </li>
            <li style={{ marginBottom: "6px" }}>
              The Oriental Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              United India Insurance Co. Ltd.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Universal Sompo General Insurance Co. Ltd.
            </li>
          </ul>
        </>
      ),
    },
    {
      ques: "How to calculate insurance premium?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Let us understand the factors that affect insurance premiums. We
            will now put light on the following:
          </p>
          <h4 style={{ marginBottom: "12px" }}>
            Two Wheeler Insurance Calculator:
          </h4>
          <table className="mb-5">
            <tr>
              <th>TWO WHEELER AGE </th>
              <th>IDV</th>
            </tr>
            <tr>
              <td>Between 0 to 6 months </td>
              <td>95% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 6 months to 1 year </td>
              <td>85% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 1 year to 2 years </td>
              <td>80% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 2 years to 3 years </td>
              <td>70% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 3 years to 4 years </td>
              <td>60% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 4 years to 5 years </td>
              <td>50% of the Ex-showroom price</td>
            </tr>
          </table>
          <p style={{ marginBottom: "12px" }}>
            The IRDAI decides the third-party insurance premium for your two
            wheeler insurance. The current rates depending on the cubic capacity
            are mentioned below:
          </p>
          <table className="mb-3">
            <tr>
              <th>TWO WHEELER CUBIC CAPACITY </th>
              <th>PREMIUM (RS.)</th>
            </tr>
            <tr>
              <td>Not exceeding 75cc </td>
              <td>Rs.569</td>
            </tr>
            <tr>
              <td>Exceeding 75cc but not exceeding 150cc </td>
              <td>Rs.720</td>
            </tr>
            <tr>
              <td>Exceeding 150cc but not exceeding 350cc </td>
              <td>Rs.887</td>
            </tr>
            <tr>
              <td>Exceeding 350cc </td>
              <td>Rs.1,019</td>
            </tr>
          </table>
        </>
      ),
    },
    {
      ques: "Car Insurance Calculator",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            A car insurance premium calculator is a vibrant online tool to
            figure out the quotes of different insurance policies in India.
          </p>
          <p style={{ marginBottom: "12px" }}>
            The car insurance premium is calculated based on the below mentioned
            formula.
          </p>
          <p style={{ marginBottom: "12px" }}>
            Own damage premium – (depreciation + NCB) + Liability premium
          </p>
          <p style={{ marginBottom: "12px" }}>
            The premium for your car insurance depends on the below mentioned
            factors:
          </p>
          <ol type="1" style={{ paddingLeft: "16px", marginBottom: "12px" }}>
            <li style={{ marginBottom: "6px" }}>IDV</li>
            <li style={{ marginBottom: "6px" }}>Cubic capacity</li>
            <li style={{ marginBottom: "6px" }}>Manufacturing year</li>
            <li style={{ marginBottom: "6px" }}>Geographical location</li>
            <li style={{ marginBottom: "6px" }}>No claim bonus (NCB)</li>
            <li style={{ marginBottom: "6px" }}>
              Remember that a car that is new will always have the maximum IDV,
              but will gradually lower down due to depreciation.
            </li>
          </ol>
          <p style={{ marginBottom: "12px" }}>
            Any insurance policy insuring anything other than a person's life is
            called as general insurance. General insurance policies are
            classified as follows:
          </p>
          <table className="mb-3">
            <tr>
              <th>YOUR CAR’S AGE </th>
              <th>DEPRECIATION %</th>
            </tr>
            <tr>
              <td>Up to 6 months </td>
              <td>5%</td>
            </tr>
            <tr>
              <td>6 months to 1 year </td>
              <td>15%</td>
            </tr>
            <tr>
              <td>1-2 years </td>
              <td>20%</td>
            </tr>
            <tr>
              <td>2-3 years </td>
              <td>30%</td>
            </tr>
            <tr>
              <td>3-4 years </td>
              <td>40%</td>
            </tr>
            <tr>
              <td>4-5 years </td>
              <td>50%</td>
            </tr>
          </table>
        </>
      ),
    },
    {
      ques: "Health Insurance Premium Calculator",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            There are many factors that decide the health insurance premium.
            Mentioned below are the generic factors that are taken into
            consideration while deriving the premium amount for your health
            insurance:
          </p>
          <ol type="1" style={{ paddingLeft: "16px", marginBottom: "12px" }}>
            <li style={{ marginBottom: "6px" }}>
              Marketing and Management Costs: These expenses are none other than
              advertisement costs, marketing costs, broker's commission and
              other operational expenses.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Mortality: In case of any uncertainty to the insured, the
              insurance company bears this cost. This however fluctuates
              depending upon the age and the income of the insured person.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Personal history: Your habits like consuming alcohol, drugs,
              smoking and the age is all taken into consideration by the
              insurance company based on which your premium is calculated.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Medical underwriting: In order to refrain the insurance company
              from suffering any losses, the medical underwriting process is
              taken into consideration. This is the reason insurance products
              are always underwritten first to maintain a balance between
              individual and group insurance policies.
            </li>
          </ol>
        </>
      ),
    },
    {
      ques: "Term Insurance Premium Calculator",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Term insurance premium calculator are the most vibrant tools that
            will help you to compare and analyze your insurance requirements.
            The below mentioned factors are used to calculate your term
            insurance premium:
          </p>
          <ol type="1" style={{ paddingLeft: "16px", marginBottom: "12px" }}>
            <li style={{ marginBottom: "6px" }}>
              Age of the insured: Age is the most important deciding factor to
              derive your term insurance premium. At a younger age, you are less
              likely to fall prey to lifestyle diseases or death. Insurance
              company would thereby save money in the near future since the
              possibilities of making a claim are less.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Personal habits: If you are addicted to alcohol, drugs or smoking
              habits, your insurance company will charge you a bomb for your
              premium. At times, there are changes of a policy denial too.
              People who aren’t addicted to these habits pay almost 30 to 70%
              premium lower than the ones addicted to it.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Health record: You will enjoy a lower premium if you have not had
              a family history of cancer, Alzheimer's etc. This survey is done
              so that your chances of falling prey to these diseases
              automatically reduces.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Tenure of the insurance policy: The term insurance premium
              calculator will be affected adversely if you buy a term plan that
              covers your retirement age as well as till you pay off all your
              liabilities.
            </li>
            <li style={{ marginBottom: "6px" }}>
              Coverage/sum assured: It is advisable to opt for a coverage as per
              your requirement. The higher the sum assured, higher would be the
              insurance premium.
            </li>
          </ol>
        </>
      ),
    },
    {
      ques: "What is IDV in vehicle insurance?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Insured Declared Value IDV is the sum assured value in the vehicle
            insurance policy. IDV in vehicle insurance gets calculated basis the
            manufacturer's listed price of a particular vehicle and adjusting
            current selling price of the vehicle with depreciation percentage as
            prescribed in the rate grid. It is the maximum sum insured
            compensated on theft, loss or complete damage to the vehicle by the
            insurer.
          </p>
          <p style={{ marginBottom: "12px" }}>
            IDV is calculated depending on factors such as age, make and brand
            of the vehicle. IDV decreases for each year at the time of renewal
            of the vehicle insurance. Insurance Regulatory & Development
            Authority (IRDA) which regulates the insurance industry in India,
            has set the IDV of the vehicle as 95% of its ex-showroom price. With
            an increase in the age of the vehicle the depreciation also
            increases. IDV of the new vehicle will be high and as the vehicle
            gets older, IDV will go down.
          </p>
          <table className="mb-5">
            <tr>
              <th>VEHICLE AGE </th>
              <th>IDV</th>
            </tr>
            <tr>
              <td>Between 0 to 6 months </td>
              <td>95% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 6 months to 1 year </td>
              <td>85% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 1 year to 2 years </td>
              <td>80% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 2 years to 3 years </td>
              <td>70% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 3 years to 4 years </td>
              <td>60% of the Ex-showroom price</td>
            </tr>
            <tr>
              <td>Between 4 years to 5 years </td>
              <td>50% of the Ex-showroom price</td>
            </tr>
          </table>
        </>
      ),
    },
    {
      ques: "What is NCB in Insurance?",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            NCB is abbreviation of No Claim Bonus. It is a reward given to the
            policy holder by the insurer for not making a claim in the preceding
            years. The reward is in the form of discount on premium payable
            which ranges from 20% to 50%.
          </p>
          <table className="mb-5">
            <tr>
              <th>NCB Rate Grid</th>
              <th></th>
            </tr>
            <tr>
              <td>After 1 claim free year </td>
              <td>20%</td>
            </tr>
            <tr>
              <td>After 2 consecutive claim free year </td>
              <td>25%</td>
            </tr>
            <tr>
              <td>After 3 consecutive claim free year </td>
              <td>35%</td>
            </tr>
            <tr>
              <td>After 4 consecutive claim free year </td>
              <td>45%</td>
            </tr>
            <tr>
              <td>After 5 consecutive claim free year </td>
              <td>50%</td>
            </tr>
          </table>
          <p style={{ marginBottom: "12px" }}>
            NCB can be claimed on the first renewal of the policy on the
            condition that there was no claim made in the past year. On the
            first-year renewal, you can claim a discount on the premium payable
            by 20% which will gradually increase with every claim free year up
            to a maximum of 50% at the end of five claim free years.
          </p>
          <p style={{ marginBottom: "12px" }}>
            NCB reward is attached to the policy holder and not the car. This
            means you get to retain your NCB if you sell your car and buy a new
            one or if you want to switch to another insurer at the time of
            renewal of the policy.
          </p>
        </>
      ),
    },
    {
      ques: " What is Zero Dep insurance? ",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            Zero depreciation or ‘zero dep’ policies are getting popular among
            vehicle owners. These policies offer full claim payment without any
            deduction for depreciation on the value of parts replaced. This also
            covers the repairing costs of fibre, glass, rubber parts and
            plastic. A standard rate of depreciation for a vehicle ranges from 0
            to 50% depending on the age of vehicle and the type of material,
            thereby making you pay money from your pocket at the time of a claim
            in a standard policy. But in the case of a zero-depreciation policy,
            no depreciation is charged, thus a 100% reimbursement on replaced or
            depreciated parts can be availed leading to maximum benefit under
            the cover.
          </p>
          <p style={{ marginBottom: "12px" }}>
            Some insurers which provide zero dep cover in India are Tata AIG
            General, Bharti AXA General, ICICI Lombard General, Reliance General
            and HDFC Ergo General.
          </p>
          <p style={{ marginBottom: "12px" }}>
            Zero dep is useful for new cars or cars up to the age of 5 years.
            The premium for Zero dep policies is slightly dearer than a standard
            car insurance policy. There is also a cap on the number of claims
            that can be made without depreciation during the term of the policy.
            Normal wear and tear or mechanical breakdowns are not covered in a
            Zero dep insurance.
          </p>
        </>
      ),
    },
    {
      ques: " How to claim insurance? ",
      ans: (
        <>
          <p style={{ marginBottom: "12px" }}>
            The primary thing to do is intimate the insurance company and lodge
            the claim. Depending on the event, one may follow different
            procedure. However, one must keep the following things ready at the
            time of intimation –
          </p>
          <ol type="1" style={{ paddingLeft: "16px", marginBottom: "12px" }}>
            <li>Name of the insured.</li>
            <li>Contact details of the insured/Claimant.</li>
            <li>Policy document.</li>
            <li>
              In case of accident, note down the details of the people and
              vehicle involved in the accident.
            </li>
            <li>Date and time of accident along with its location</li>
            <li>
              Extend of loss with the basic details one must immediately file
              the claim by contacting the insurance company on their toll-free
              number.
            </li>
          </ol>
        </>
      ),
    },
  ];

  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12} className="mb-6">
          <div className="cms_header text-center">
            <h2 className="mb-2">FAQ</h2>
            <p>Commonly asked Questions and responses.</p>
            <img
              src="./images/faq.svg"
              alt=""
              className="img-fluid my-5"
              style={{ maxHeight: "106px" }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid xs={12} padding={"12px"}>
          <div className="cms_content_sec">
            <Grid container spacing={0}>
              <Grid xs={12} textAlign={"center"}>
                <h5>General Questions</h5>
                <p className="content_p mb-5 text-center">
                  You will find answers.
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="faq_section">
            <Grid container spacing={0}>
              <Grid xs={12}>
                {generalQuestions?.map((data, index) => (
                  <Accordion
                    className="accordion mb-3"
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${index}` ? (
                          <RemoveIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      aria-controls={`panel${index}a-content`}
                      id={`panel${index}a-header`}
                      className="accordion-header"
                    >
                      <p>{data.ques}</p>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-detail">
                      <p>{data.ans}</p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid xs={12} padding={"12px"}>
          <div className="cms_content_sec">
            <Grid container spacing={0}>
              <Grid xs={12} textAlign={"center"}>
                <h5>More Questions</h5>
                <p className="content_p mb-5 text-center">
                  Let us understand the factors that affect insurance premiums.
                  We will now put light on the following:
                </p>
              </Grid>
            </Grid>
          </div>
          <div className="faq_section">
            <Grid container spacing={0}>
              <Grid xs={12}>
                {mqQuestions?.map((data, index) => (
                  <Accordion
                    className="accordion mb-3"
                    expanded={expanded === `panel${index}`}
                    onChange={handleChange(`panel${index}`)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === `panel${index}` ? (
                          <RemoveIcon />
                        ) : (
                          <AddIcon />
                        )
                      }
                      aria-controls={`panel${index}a-content`}
                      id={`panel${index}a-header`}
                      className="accordion-header"
                    >
                      <p>{data.ques}</p>
                    </AccordionSummary>
                    <AccordionDetails className="accordion-detail">
                      <p>{data.ans}</p>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MFaqPage;
