import Contact from "../../Pages/Desktop/CmsPage/Contact";
import Disclaimer from "../../Pages/Desktop/CmsPage/Disclaimer";
import MContact from "../../Pages/Mobile/CmsPagesMobile/MContact";
import MDisclaimer from "../../Pages/Mobile/CmsPagesMobile/MDisclaimer";
import useIsMobile from "../../SupportingFiles/MobileProvider";

const DisclaimerContainer = () => {
  const isMobile = useIsMobile();
  return <>{isMobile ? <MDisclaimer /> : <Disclaimer />}</>;
};

export default DisclaimerContainer;
