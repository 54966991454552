import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { useState } from "react";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../Components/InputFields/DatePicker/DatePicker";
import NativeSelectDropdown from "../../../../Components/InputFields/NativeSelectDropdown/NativeSelectDropdown";
import RKTextField from "../../../../Components/InputFields/RKTextField/RKTextField";
import "../MMyAccount.scss";

const MProfile = () => {
  const [profileFields, setProfileFields] = useState<{
    fullName: { value: string; warn_status: boolean };
    dob: { value: Date | null; warn_status: boolean };
    gender: { value: string; warn_status: boolean };
    mobile: { value: string; warn_status: boolean };
    email: { value: string; warn_status: boolean };
    pincode: { value: string; warn_status: boolean };
    address: { value: string; warn_status: boolean };
  }>({
    fullName: { value: "", warn_status: false },
    dob: { value: null, warn_status: false },
    gender: { value: "", warn_status: false },
    mobile: { value: "", warn_status: false },
    email: { value: "", warn_status: false },
    pincode: { value: "", warn_status: false },
    address: { value: "", warn_status: false },
  });

  const genderdata = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    },
  ];
  const updateFieldsData = (value: string, attrName: string) => {
    setProfileFields((prev) => ({ ...prev, [attrName]: { value: value } }));
  };
  return (
    <Box className="mprofileTab">
      <Grid container columnSpacing={3}>
        <Grid xs={12}>
          <Box className="profile_sec_box">
            <h4 className="mb-5">Add Your Information</h4>
            <RKTextField
              class_name="inputField mb-5"
              title={"Full Name"}
              value={profileFields.fullName.value}
              attrName={"fullName"}
              value_update={updateFieldsData}
              warn_status={profileFields.fullName.warn_status}
              error_message={"Enter Full Name"}
            />
            <DatePicker
              class_name="inputField mb-5"
              title={"DOB"}
              value={profileFields.dob.value}
              attrName={"dob"}
              value_update={updateFieldsData}
              warn_status={profileFields.dob.warn_status}
              error_message={"Select Date of Birth"}
            />
            <NativeSelectDropdown
              class_name="inputField mb-5"
              title={"Gender"}
              attrName={"gender"}
              value={profileFields.gender.value}
              value_update={updateFieldsData}
              data={genderdata}
              warn_status={profileFields.gender.warn_status}
              error_message={"Select Gender"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Mobile"}
              value={profileFields.mobile.value}
              attrName={"mobile"}
              value_update={updateFieldsData}
              warn_status={profileFields.mobile.warn_status}
              error_message={"Enter Mobile"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Email"}
              value={profileFields.email.value}
              attrName={"email"}
              value_update={updateFieldsData}
              warn_status={profileFields.email.warn_status}
              error_message={"Enter Email"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Address1"}
              value={profileFields.address.value}
              attrName={"address"}
              value_update={updateFieldsData}
              warn_status={profileFields.address.warn_status}
              error_message={"Enter Address"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Address2"}
              value={profileFields.address.value}
              attrName={"address"}
              value_update={updateFieldsData}
              warn_status={profileFields.address.warn_status}
              error_message={"Enter Address"}
            />
            <RKTextField
              class_name="inputField mb-5"
              title={"Pincode"}
              value={profileFields.pincode.value}
              attrName={"pincode"}
              value_update={updateFieldsData}
              warn_status={profileFields.pincode.warn_status}
              error_message={"Enter Pincode"}
            />
            <Box className="city_box mb-5">
              <p style={{ fontSize: "14px" }}>City, Name</p>
            </Box>
            <CustomButton
              className="primaryBtn"
              text="Update Details"
              onClick={() => {}}
              fullWidth={false}
              variant="contained"
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default MProfile;
