import React, { useState } from "react";
import { Box, Button, IconButton, Modal, Rating } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2"; // Grid version 2
import SearchDropdown from "../../../../../Components/InputFields/SearchDropdown/SearchDropdown";
import SelectDropdown from "../../../../../Components/InputFields/SelectDropdown/SelectDropdown";
import RKTextField from "../../../../../Components/InputFields/RKTextField/RKTextField";
import CustomButton from "../../../../../Components/InputFields/CustomButton/CustomButton";
import DatePicker from "../../../../../Components/InputFields/DatePicker/DatePicker";
import { COLORS } from "../../../../../SupportingFiles/colors";

const MPolicyDetailPopup = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: Function;
}) => {
  return (
    <Modal
      open={open}
      aria-labelledby="Health Insurance Add New Policy"
      className="modalWrapper"
    >
      <div className="modalInner mobile_form_padding">
        <Box className="modalContent modalContent_mobile">
          <Grid
            container
            columnSpacing={3}
            alignItems="center"
            marginBottom={"16px"}
          >
            <Grid xs={12} className="textRight">
              <IconButton
                className="close-button"
                onClick={() => {
                  setOpen(false);
                }}
              ></IconButton>
            </Grid>
            <Grid xs={12} paddingTop={"20px"} textAlign={"center"}>
              <p style={{ color: "rgba(96, 96, 96, 1)" }}>
                Policy No.{" "}
                <span
                  style={{
                    fontSize: "16px",
                    lineHeight: "30px",
                    color: "#000",
                    fontWeight: 700,
                  }}
                >
                  #PROHLR063456789
                </span>
              </p>
              <p
                style={{
                  color: "#000",
                  fontWeight: 700,
                  fontSize: "16px",
                  lineHeight: "30px",
                }}
              >
                Premium
                <span
                  style={{
                    fontSize: "14px",
                    lineHeight: "30px",
                    color: "rgba(96, 96, 96, 1)",
                    fontWeight: 400,
                  }}
                >
                  (Incl. GST)
                </span>{" "}
                <span style={{ color: COLORS.secondary }}>₹13,097</span>
              </p>
            </Grid>
          </Grid>

          <Grid container columnSpacing={2} marginBottom={"30px"}>
            <Grid xs={12}>
              <Box
                sx={{
                  background: "rgba(245, 245, 245, 1)",
                  borderRadius: "15px",
                  padding: "20px 0px",
                }}
              >
                <Grid
                  container
                  columnSpacing={3}
                  alignItems={"center"}
                  marginBottom={"20px"}
                >
                  <Grid xs={6}>
                    <img
                      src="./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                      alt=""
                      height={"50px"}
                    />
                  </Grid>
                  <Grid xs={6} textAlign={"right"}>
                    <p style={{ color: "rgba(96, 96, 96, 1)" }}>
                      Vehicle IDV{" "}
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          display: "block",
                          marginTop: "8px",
                          color: COLORS.black,
                        }}
                      >
                        ₹ 6,45,987
                      </span>
                    </p>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} alignItems={"center"}>
                  <Grid xs={12} textAlign={"center"}>
                    <p style={{ color: "rgba(96, 96, 96, 1)" }}>
                      <span
                        style={{
                          fontWeight: 500,
                          color: "rgba(37, 37, 37, 1)",
                        }}
                      >
                        Hyundai Creta
                      </span>{" "}
                      Diesel, 1.6 SX(0) 1582 CC
                      <span
                        style={{
                          fontWeight: 700,
                          fontSize: "16px",
                          display: "block",
                          marginTop: "8px",
                          color: COLORS.black,
                        }}
                      >
                        HR01AB1234
                      </span>
                    </p>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          <Grid
            container
            columnSpacing={3}
            alignItems="center"
            marginBottom={"30px"}
          >
            <Grid xs={12} marginBottom={"20px"}>
              <Box
                className="mb-5"
                display={"flex"}
                alignItems={"center"}
                gap="15px"
              >
                <img src="./images/payment-icon.svg" alt="" />
                <p>
                  Payment Done
                  <span
                    style={{
                      display: "block",
                      fontWeight: 700,
                      marginTop: "5px",
                      color: COLORS.black,
                      fontSize: "16px",
                      lineHeight: "18px",
                    }}
                  >
                    12th Aug
                  </span>
                </p>
              </Box>
              <Box display={"flex"} alignItems={"center"} gap="15px">
                <img src="./images/duedate-icon.svg" alt="" />
                <p>
                  Policy Start Date
                  <span
                    style={{
                      display: "block",
                      fontWeight: 700,
                      marginTop: "5px",
                      color: COLORS.black,
                      fontSize: "16px",
                      lineHeight: "18px",
                    }}
                  >
                    12th Aug
                  </span>
                </p>
              </Box>
            </Grid>
            <Grid xs={12} marginBottom={"20px"}>
              <hr
                style={{
                  border: "none",
                  borderTop: "1px solid rgba(204, 204, 204, 1)",
                  marginBottom: "0px",
                }}
              />
            </Grid>
            <Grid xs={12}>
              <h5 style={{ fontSize: "16px", marginBottom: "15px" }}>
                This Policy Covers:
              </h5>
              <ul className="policyAddons" style={{ paddingLeft: "16px" }}>
                <li>PA Owner Cover</li>
                <li>Zero Depreciation</li>
                <li>24x7 Roadside Assistance</li>
                <li>24x7 Roadside Assistance</li>
                <li>24x7 Roadside Assistance</li>
              </ul>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3} marginBottom={"30px"}>
            <Grid xs={12} marginBottom={"15px"} textAlign={"center"}>
              <p style={{ fontSize: "16px", fontWeight: 500 }}>
                You were helped by Vishal with your purchase.
              </p>
            </Grid>
            <Grid xs={12} textAlign={"center"}>
              <Box>
                <h5 style={{ fontSize: "16px", marginBottom: "8px" }}>
                  Rate your experience.
                </h5>
                <Rating name="size-large" defaultValue={0} size="large" />
              </Box>
            </Grid>
          </Grid>

          <Grid container columnSpacing={3}>
            <Grid
              xs={12}
              textAlign="center"
              display={"flex"}
              gap={"15px"}
              justifyContent={"center"}
            >
              <CustomButton
                className="primaryBtn"
                text={"Download Policy"}
                variant={"text"}
                fullWidth={false}
                onClick={() => {}}
              />
              <CustomButton
                className="primaryBtn"
                text={"Claim"}
                variant={"text"}
                fullWidth={false}
                onClick={() => {}}
              />
            </Grid>
          </Grid>
        </Box>
      </div>
    </Modal>
  );
};

export default MPolicyDetailPopup;
