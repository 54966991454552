import React from 'react'
import useIsMobile from '../../SupportingFiles/MobileProvider'
import Homepage from '../../Pages/Desktop/Homepage/Homepage'
import MHomepage from '../../Pages/Mobile/Homepage/MHomepage'

const HomeContainer = () => {
    const isMobile = useIsMobile()
  return <>{isMobile ? <MHomepage /> : <Homepage />}</>;
}

export default HomeContainer