import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button } from "@mui/material";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import Footer from "../../../Components/Desktop/Footer/Footer";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import SelectDropdown from "../../../Components/InputFields/SelectDropdown/SelectDropdown";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";

function Contact() {
  const [contactFields, setContactFields] = useState<{
    name: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    interested: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    interested: { value: "", warning: false },
  });
  const interested_data = [
    { key: "Bike Insurance", value: "Bike Insurance" },
    { key: "Car Insurance", value: "Car Insurance" },
    { key: "Health Insurance", value: "Health Insurance" },
    { key: "Term Insurance", value: "Term Insurance" },
    { key: "Investment Plans", value: "Investment Plans" },
    { key: "Travel Insurance", value: "Travel Insurance" },
  ];

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Box className="CmsPagesWrapper">
      <Navbar />

      <div className="cms_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="cms_header text-center mb-12">
              <h2 className="mb-5">Get in Touch</h2>
              <p>Please enter details. We want to hear from you!</p>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid md={12} lg={6} xl={6}>
            <div className="text-center">
              <img src="./images/contact_us.svg" alt="" />
            </div>
            <div className="contact_details">
              <p>
                <span className="icon1"></span>0731-6644200
              </p>
              <p>
                <span className="icon2"></span>
                <a href="mailto:info@unisoninsurance.net">
                  info@unisoninsurance.net
                </a>
              </p>
              <p>
                <span className="icon3"></span> Unison Insurance Broking
                Services Pvt. Ltd. Off. No. 404, Glacier Complex, Jetalpur Road,
                Next to Pizza In, Jetalpur Road, Vadodara, Gujarat - 390007
              </p>
            </div>
          </Grid>
          <Grid md={12} lg={6} xl={6}>
            <div className="details_input_section">
              <Grid container>
                <Grid xs={12} marginBottom={"24px"}>
                  <h5>We would like to hear from you!</h5>
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Full Name"}
                    value={contactFields.name.value}
                    attrName={[
                      "name",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={contactFields.name.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <RKTextField
                    class_name="inputField mb-6"
                    title={"Mobile"}
                    value={contactFields.mobile.value}
                    attrName={[
                      "mobile",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    warn_status={contactFields.mobile.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <SelectDropdown
                    class_name="inputField mb-6 "
                    title="Interested in"
                    value={contactFields.interested.value}
                    attrName={[
                      "interested",
                      "value",
                      contactFields,
                      setContactFields,
                    ]}
                    value_update={fieldsUpdateState}
                    data={interested_data}
                    warn_status={contactFields.interested.warning}
                  />
                </Grid>
                <Grid xs={12}>
                  <CustomButton
                    className="primaryBtn disabled"
                    text={"Submit Details"}
                    variant={"text"}
                    fullWidth={false}
                  />
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </Box>
  );
}

export default Contact;
