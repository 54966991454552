export enum TW_ROUTES {
  QUOTE = "/quote/two-wheeler-insurance/two-wheeler-quote",
  // POLICY_DETAILS = "/two-wheeler/policy-details",
  // PROPOSAL_PAGE = "/two-wheeler/tw-proposal-page",
  // OWNER_DETAILS = "owner-details",
  // VEHICLE_DETAILS = "vehicle-details",
  // NOMINEE_DETAILS = "nominee-details",
  // ADDRESS_DETAILS = "address-details",
  // PREVIEW_DEATILS = "preview-details",
  // POLICY_CONFIRMATION = "/tw-insurance/payment-response",
}
