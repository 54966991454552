import React from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import "./Footer.scss";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function Footer() {
  const navigate = useNavigate();
  return (
    <Box className="footer_d">
      <Box className="footer-top">
        <Grid container spacing={3}>
          <Grid xs={3}>
            <img src="./images/unison_white_logo.svg" height="40px" />
            <p className="mt-4">
              CIN: U67200GJ1998PTC034882 <br />
              IRDAI Comp. Broker Registration
              <br /> Cert. No.149 | Valid up to 14/04/2027
            </p>
          </Grid>
          <Grid xs={2}>
            <h5>Products</h5>
            <ul className="product_list">
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_HEALTH);
                  }}
                >
                  Health insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_CAR);
                  }}
                >
                  Car insurance
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TW);
                  }}
                >
                  Bike insurance
                </Link>
              </li>
              {/* <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TERM);
                  }}
                >
                  Term insurance
                </Link>
              </li> */}
              {/* 
              <li>
                <Link>Investment Plans</Link>
              </li>
              <li>
                <Link>Travel insurance</Link>
              </li> */}
            </ul>
          </Grid>
          <Grid xs={2}>
            <h5>Services</h5>
            <ul className="product_list">
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate(COMMON_ROUTES.CLAIM);
                  }}
                >
                  Claim
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate(COMMON_ROUTES.CONTACT);
                  }}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate(COMMON_ROUTES.COMPLAINT);
                  }}
                >
                  Complaint
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate(COMMON_ROUTES.CAREER);
                  }}
                >
                  Career
                </a>
              </li>
              <li>
                <a
                  href=""
                  onClick={() => {
                    navigate(COMMON_ROUTES.FAQ);
                  }}
                >
                  FAQ
                </a>
              </li>
            </ul>
          </Grid>
          <Grid xs={2}>
            <h5>Company</h5>
            <ul className="product_list">
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.ABOUT_US);
                  }}
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.TERM_AND_CONDTION);
                  }}
                >
                  Terms & Conditions
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRIVACY_POLICY);
                  }}
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.DISCLAIMER);
                  }}
                >
                  Disclaimer
                </Link>
              </li>
              <li>
                <Link
                  onClick={() => {
                    navigate(COMMON_ROUTES.REFUND_CANCELLATION);
                  }}
                >
                  Refund & Cancellation
                </Link>
              </li>
            </ul>
          </Grid>
          <Grid xs={3}>
            <h5>Contact Us</h5>
            <ul className="product_list">
              <li>
                <Link href="mailto:info@unisoninsurance.net">
                  info@unisoninsurance.net
                </Link>
              </li>
              <li>
                <Link href="tel:18006785676" target="_blank">
                  Sales: 18006785676
                </Link>
              </li>
              <li>
                <Link href="tel:18006789876" target="_blank">
                  Claim: 18006789876
                </Link>
              </li>
            </ul>
            <ul className="social-sites">
              {/* <li>
                <Link href="javascript:void(0);">
                  <img src="./images/twitter_icon.svg" alt="" />
                </Link>
              </li> */}
              <li>
                <a
                  href="https://www.facebook.com/unisoninsurance.net"
                  target="_blank"
                >
                  <img src="./images/fb_icon.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/company/unison-insurance-broking-services-pvt-ltd"
                  target="_blank"
                >
                  <img src="./images/linkedin_icon.svg" alt="" />
                </a>
              </li>
              <li>
                <a
                  href="https://youtube.com/@unisoninsurancebrokingservices?si=q4RiVgsmeEoNx3-M"
                  target="_blank"
                >
                  <img src="./images/youtube_icon.svg" alt="" />
                </a>
              </li>
            </ul>
          </Grid>
        </Grid>
      </Box>
      <Box className="footer-bottom">
        <Grid container columnSpacing={3}>
          <Grid xs={12}>
            <p>
              © Copyright {new Date().getFullYear()} UNISON Insurance Broking
              Services Pvt. Ltd. All Rights Reserved.
            </p>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default Footer;
