import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Collapse, Link, MenuItem } from "@mui/material";
import SlidingPanel from "react-sliding-side-panel";
import Menu, { MenuProps } from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "./MNavbar.scss";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import MLoginPopup from "./MLoginPopup/MLoginPopup";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

const MNavbar = ({}) => {
  const [slideOpen, setSlideOpen] = React.useState(false);
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const [unusedFields, setUnusedFields] = React.useState(false);
  const openUnusedFields = () => {
    setUnusedFields((expanded) => !expanded);
  };

  return (
    <Box className="navbar_m">
      <MLoginPopup open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container columnSpacing={3} alignItems={"center"}>
        <Grid xs={6}>
          <Link
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <img
              src="./images/unison_logo_mobile.svg"
              alt=""
              className="logo"
            />
          </Link>
        </Grid>
        <Grid xs={6} className="text-end">
          <Link
            className="menu-btn"
            onClick={() => {
              setSlideOpen(true);
            }}
          >
            <span className="iconbars"></span>
          </Link>
        </Grid>
      </Grid>
      <SlidingPanel
        type={"right"}
        isOpen={slideOpen}
        size={100}
        panelClassName="slidingPanel"
        backdropClicked={() => setSlideOpen(false)}
      >
        <Box className="slide_popup">
          <Grid container spacing={3} alignItems="center">
            <Grid xs={12}>
              <Link
                href="javascript:void(0);"
                className="close"
                onClick={() => {
                  setSlideOpen(false);
                }}
              ></Link>
            </Grid>
            <Grid xs={12}>
              <ul>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate("/aboutus_m");
                    }}
                  >
                    About
                  </Link>
                </li>
                <li className="nav-item ">
                  <Link
                    className="nav-link dropdown"
                    href="javascript:void(0);"
                    onClick={openUnusedFields}
                  >
                    Products
                  </Link>
                  <Collapse in={unusedFields}>
                    <ul className="dropdown-menu">
                      <li className="dropdown-item">
                        <Link
                          onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                        >
                          Health Insurance
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <Link
                          onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}
                        >
                          Car Insurance
                        </Link>
                      </li>
                      <li className="dropdown-item">
                        <Link
                          onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}
                        >
                          Bike Insurance
                        </Link>
                      </li>

                      {/* <li className="dropdown-item">
                        <Link
                          onClick={() => navigate(COMMON_ROUTES.PRODUCT_TERM)}
                        >
                          Term Insurance
                        </Link>
                      </li> */}
                      {/* <li className="dropdown-item">
                        <a href="javascript:void(0)">Invest Insurance</a>
                      </li>
                      <li className="dropdown-item">
                        <a href="javascript:void(0)">Travel Insurance</a>
                      </li> */}
                    </ul>
                  </Collapse>
                </li>

                <li className="nav-item">
                  <Link
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate(COMMON_ROUTES.CLAIM);
                    }}
                  >
                    Claim
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    href="javascript:void(0);"
                    onClick={() => {
                      navigate(COMMON_ROUTES.CONTACT);
                    }}
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              <div className="mt-4 navbar_login">
                <Link
                  className="login_btn"
                  onClick={() => {
                    setOpenLoginPopup(true);
                    setSlideOpen(false);
                  }}
                >
                  Login
                </Link>
              </div>
            </Grid>
          </Grid>
        </Box>
      </SlidingPanel>
    </Box>
  );
};

export default MNavbar;
