import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Components/InputFields/TextArea/TextArea";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";

function MClaim() {
  const [claimFields, setClaimFields] = useState<{
    name: { value: string; warning: boolean };
    policyNumber: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    description: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    policyNumber: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    description: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12} className="mb-6">
          <div className="cms_header text-center">
            <h2 className="mb-5">Submit a claim ticket</h2>
            <p>
              Please enter your policy details. We will assist you with your
              calim.
            </p>
          </div>
        </Grid>
        <Grid xs={12} className="mb-6 text-center">
          <img
            src="./images/complaint.svg"
            alt=""
            className="img-fluid"
            style={{ maxHeight: "173px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={claimFields.name.value}
            attrName={["name", "value", claimFields, setClaimFields]}
            value_update={fieldsUpdateState}
            warn_status={claimFields.name.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Policy Number"}
            value={claimFields.policyNumber.value}
            attrName={["policyNumber", "value", claimFields, setClaimFields]}
            value_update={fieldsUpdateState}
            warn_status={claimFields.policyNumber.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            value={claimFields.mobile.value}
            attrName={["mobile", "value", claimFields, setClaimFields]}
            value_update={fieldsUpdateState}
            warn_status={claimFields.mobile.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={claimFields.email.value}
            attrName={["email", "value", claimFields, setClaimFields]}
            value_update={fieldsUpdateState}
            warn_status={claimFields.email.warning}
          />
        </Grid>
        <Grid xs={12}>
          <TextArea
            class_name="inputField"
            title={"What are you facing problems?"}
            value={claimFields.description.value}
            placeholder={"Enter description here"}
            attrName={["description", "value", claimFields, setClaimFields]}
            value_update={fieldsUpdateState}
            warn_status={claimFields.description.warning}
          />
        </Grid>
        <Grid xs={12} className="mb-5">
          <CustomButton
            className="primaryBtn disabled"
            text={"Submit Details"}
            variant={"text"}
            fullWidth={false}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3} justifyContent={"center"}>
        <Grid xs={12}>
          <p className="claim_p text-center">
            For immediate assistance please feel free to contact our customer
            support.
          </p>
        </Grid>
        <Grid xs={12}>
          <div className="claim_details">
            <span>
              <img src="./images/email_icon.svg" alt="" />
            </span>
            <div className="inner_detail">
              <h6>Email</h6>
              <a href="mailto:info@unisoninsurance.net">
                info@unisoninsurance.net
              </a>
            </div>
          </div>
        </Grid>
        <Grid xs={12}>
          <div className="claim_details">
            <span>
              <img src="./images/call_icon.svg" alt="" />
            </span>
            <div className="inner_detail">
              <h6>Call Us</h6>
              <a href="tel:+91 731-6644200">0731-6644200</a>
            </div>
          </div>
        </Grid>
        <Grid xs={12}>
          <div className="claim_details">
            <span>
              <img src="./images/clock_icon.svg" alt="" />
            </span>
            <div className="inner_detail">
              <h6>Timing</h6>
              <p>10 a.m to 8 p.m on all working days.</p>
            </div>
          </div>
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MClaim;
