import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import Footer from "../../../Components/Desktop/Footer/Footer";
import { COLORS } from "../../../SupportingFiles/colors";

function Disclaimer() {
  return (
    <Box className="CmsPagesWrapper">
      <Navbar />

      <div className="cms_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="cms_header">
              <h2 className="mb-10">Disclaimer</h2>
              <p className="content_p mb-10">
                Insurance is the subject matter of solicitation. Details shared
                by visitors on our website may be shared with our partner
                insurance companies. The product information displayed for
                comparison is sourced from the respective insurance company with
                whom we have an agreement. We assure you that the product
                information shared is authentic to the best of our knowledge and
                as provided by the insurance company.For any further queries or
                assistance, please feel free to write to us at{" "}
                <a
                  href="mailto:retailhub@unisoninsurance.net"
                  className="mail-link"
                >
                  retailhub@unisoninsurance.net
                </a>
              </p>
              <p className="content_p mb-5">
                IRDA Statutory Requirement: Beware of Spurious Phone Calls and
                Fictitious/Fraudulent Offers. The Insurance Regulatory and
                Development Authority of India (IRDAI) clarifies to the public
                that neither IRDAI nor its officials are involved in activities
                such as the sale of any kind of insurance or financial products,
                nor do they invest premiums. IRDAI does not announce any bonus.
                If you receive any suspicious phone calls, we urge you to lodge
                a police complaint along with the details of the phone call and
                number.
              </p>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </Box>
  );
}

export default Disclaimer;
