import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link, Button, Menu, MenuItem } from "@mui/material";
import "./Navbar.scss";
import { useNavigate } from "react-router-dom";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import { COLORS } from "../../../SupportingFiles/colors";
import LoginIcon from "@mui/icons-material/Login";
import CustomButton from "../../InputFields/CustomButton/CustomButton";
import LoginPopup from "./LoginPopup/LoginPopup";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";

function Navbar() {
  const navigate = useNavigate();
  const [openLoginPopup, setOpenLoginPopup] = useState(false);
  // product dropdown
  const [anchorE2, setAnchorE2] = React.useState<null | HTMLElement>(null);
  const open2 = Boolean(anchorE2);
  const producthandleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorE2(event.currentTarget);
  };
  const producthandleClose = () => {
    setAnchorE2(null);
  };
  // useEffect(() => {
  //   if (open2) {
  //     document.body.style.overflow = "scroll";
  //   } else {
  //     document.body.style.overflow = "scroll";
  //   }
  // }, [open2]);
  return (
    <Box className="navbar">
      <LoginPopup open={openLoginPopup} setOpen={setOpenLoginPopup} />
      <Grid container spacing={3}>
        <Grid xs={4}>
          <Link
            onClick={() => {
              window.location.replace("/");
            }}
          >
            <img src="./images/unison_logo.svg" height="40px" />
          </Link>
        </Grid>
        <Grid xs={8} display="flex" justifyContent="end" alignItems="center">
          <ul className="navLinks">
            <li>
              <Link
                className="navitems"
                onClick={() => {
                  navigate(COMMON_ROUTES.ABOUT_US);
                }}
              >
                About Us
              </Link>
            </li>
            <li>
              <Button
                className="nav-link product-link"
                id="basic-link"
                aria-controls={open2 ? "basic-menu2" : undefined}
                aria-haspopup="true"
                aria-expanded={open2 ? "true" : undefined}
                onClick={producthandleClick}
                disableRipple
                sx={{
                  padding: 0,
                  color: COLORS.black,
                  fontWeight: 400,
                  textTransform: "capitalize",
                  fontSize: "14px",
                  lineHeight: "21px",
                  fontFamily: "Gotham",
                  display: "inline-flex",
                  alignItems: "center",
                  "&:hover": {
                    background: "none",
                    color: COLORS.primary,
                  },
                }}
              >
                Products{" "}
                <KeyboardArrowDownRoundedIcon sx={{ marginLeft: "4px" }} />
              </Button>
              <Menu
                className="popoverMenu"
                id="basic-menu2"
                anchorEl={anchorE2}
                open={open2}
                onClose={producthandleClose}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.2))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                MenuListProps={{
                  "aria-labelledby": "basic-link",
                  sx: {
                    fontSize: "14px",
                    lineHeight: "20px",
                  },
                }}
              >
                <MenuItem
                  onClick={() => navigate(COMMON_ROUTES.PRODUCT_HEALTH)}
                >
                  Health Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_CAR)}>
                  Car Insurance
                </MenuItem>
                <MenuItem onClick={() => navigate(COMMON_ROUTES.PRODUCT_TW)}>
                  Two-Wheeler Insurance
                </MenuItem>
                {/* <MenuItem
                  onClick={() => {
                    navigate(COMMON_ROUTES.PRODUCT_TERM);
                  }}
                >
                  Term Insurance
                </MenuItem> */}
                {/* 
                <MenuItem>Non-Term Insurance</MenuItem>
                <MenuItem>Travel Insurance</MenuItem> */}
              </Menu>
            </li>
            <li>
              <Link
                className="navitems"
                onClick={() => {
                  navigate(COMMON_ROUTES.CLAIM);
                }}
              >
                Claims
              </Link>
            </li>
            <li>
              <CustomButton
                className="primaryBtn"
                text={
                  <>
                    <LoginIcon sx={{ fontSize: "16px", marginRight: "4px" }} />{" "}
                    Login
                  </>
                }
                onClick={() => setOpenLoginPopup(true)}
                fullWidth={false}
                variant="contained"
              />
            </li>
            {/* <li>
              <CustomButton
                className="primaryBtn"
                text={"My Account"}
                onClick={() => navigate(COMMON_ROUTES.MY_ACCOUNT)}
                fullWidth={false}
                variant="contained"
              />
            </li> */}
          </ul>
          {/* <Button className='btn mr-4'>Email Quotes</Button>
          <h6 className='mb-0'><span>Toll Free:</span> 1800 123 624276</h6> */}
        </Grid>
      </Grid>
    </Box>
  );
}

export default Navbar;
