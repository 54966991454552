import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import "../MMyAccount.scss";
import CustomButton from "../../../../Components/InputFields/CustomButton/CustomButton";
import { COMMON_ROUTES } from "../../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../../../../SupportingFiles/colors";
import ReplayCircleFilledIcon from "@mui/icons-material/ReplayCircleFilled";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import VisibilityIcon from "@mui/icons-material/Visibility";
import MPolicyDetailPopup from "./MPolicyDetailPopup/MPolicyDetailPopup";

const MDashboard = () => {
  const navigate = useNavigate();
  const [openPolicyDetailPopup, setOpenPolicyDetailPopup] = useState(false);
  return (
    <Box className="mdashboardTab">
      <MPolicyDetailPopup
        open={openPolicyDetailPopup}
        setOpen={setOpenPolicyDetailPopup}
      />
      <Grid container columnSpacing={3}>
        {/* when there is no records or the user is new  this section will be visible */}
        <Grid xs={12}>
          <Box className="noDataBox_m">
            <img src="./images/girl_with_lens.svg" alt="" />
            <Box>
              <h4
                style={{
                  fontSize: "18px",
                  lineHeight: "26px",
                  marginBottom: "22px",
                  fontWeight: 500,
                }}
              >
                If You are still searching for a policy, go back to homepage
              </h4>
              <CustomButton
                className="primaryBtn"
                text={"Go To Home"}
                onClick={() => navigate(COMMON_ROUTES.HOME)}
                fullWidth={false}
                variant="contained"
              />
            </Box>
          </Box>
        </Grid>
        {/* end */}

        {/* pickup where u left sec */}
        <Grid xs={12}>
          <Box className="pickup_box_m">
            <img src="./images/pickupleft.svg" alt="" />
            <Box>
              <h4
                style={{
                  marginBottom: "8px",
                  fontWeight: 700,
                  color: COLORS.white,
                  lineHeight: "24px",
                }}
              >
                Pick up Where you left
              </h4>
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "18px",
                  color: COLORS.white,
                  fontWeight: 500,
                  marginBottom: "15px",
                }}
              >
                Viewing quotes for Health Insurance.
              </p>
              <CustomButton
                className="primaryBtn"
                text={"Continue"}
                onClick={() => {}}
                fullWidth={false}
                variant="contained"
              />
            </Box>
          </Box>
        </Grid>
        {/* end  */}

        {/* continue application  */}
        <Grid xs={12}>
          <Box className="continue_app_box_m">
            <h4 style={{ marginBottom: "20px", textAlign: "center" }}>
              Continue Application
            </h4>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              marginBottom={"20px"}
            >
              <p className="p1">
                Txn ID <span>56862310000</span>
              </p>
              <p className="p1" style={{ textAlign: "right" }}>
                Premium <span>₹ 5066</span>
              </p>
            </Box>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              marginBottom={"30px"}
            >
              <p className="p1">
                Sum Insured <span>₹ 10 Lakhs</span>
              </p>
              <img
                src="./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                alt=""
                className="ins_logo"
              />
            </Box>
            <Box style={{ textAlign: "center" }}>
              <CustomButton
                className="primaryBtn"
                text={"Continue"}
                onClick={() => navigate(COMMON_ROUTES.HOME)}
                fullWidth={false}
                variant="contained"
              />
            </Box>
          </Box>
        </Grid>
        {/* end  */}

        {/* application status  */}
        <Grid xs={12}>
          <Box className="application_status_box_m">
            <h4 style={{ marginBottom: "20px", textAlign: "center" }}>
              Application Status
            </h4>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              alignItems={"center"}
              marginBottom={"20px"}
              className="white_box"
            >
              <img
                src="./images/insurerlogo/Manipal-CignaTTK-Health-Insurance-Company-Limited.svg"
                alt=""
                className="ins_logo"
              />
              <p className="plan_name">Pro health Protect Plan</p>
            </Box>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              marginBottom={"20px"}
            >
              <p className="p1">
                Policy Number <span>#PROHLR063456789</span>
              </p>
            </Box>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              marginBottom={"20px"}
            >
              <p className="p1">
                Coverage for <span>2 Adults & 1 Child</span>
              </p>
              <p className="p1">
                Premium <span>₹ 5066</span>
              </p>
            </Box>
            <Box
              display={"flex"}
              gap={"12px"}
              justifyContent={"space-between"}
              marginBottom={"20px"}
            >
              <p className="p1">
                Payment On <span>21 Dec, 2021</span>
              </p>
              <p className="p1">
                Sum Insured <span>₹ 10 Lakhs</span>
              </p>
            </Box>
            <p className="status_p">
              Status <span>Underwriting</span>
            </p>
          </Box>
        </Grid>
        {/* end  */}

        {/* self inspection  */}
        <Grid xs={12}>
          <Box className="self_ins_box_m">
            <h4
              style={{
                marginBottom: "20px",
                textAlign: "center",
              }}
            >
              Self Inspection
            </h4>
            <p className="p2" style={{ marginBottom: "15px" }}>
              Hyundai Creta <span>Diesel, 1.6 SX (o) 1582 cc</span>
            </p>
            <p className="p1" style={{ marginBottom: "25px" }}>
              Date <span>29, June, 2024</span>
            </p>
            <CustomButton
              className="primaryBtn"
              text={"Start"}
              onClick={() => navigate(COMMON_ROUTES.HOME)}
              fullWidth={false}
              variant="contained"
            />
          </Box>
        </Grid>
        {/* end  */}

        {/* my policies section  */}
        <Grid xs={12} marginTop={"10px"}>
          <h4 className="mb-5" style={{ textAlign: "center" }}>
            My Policies
          </h4>
          <Box className="policiesSection">
            {/* health policy box */}
            <Box className="policy_box_m">
              <Grid container columnSpacing={3} alignItems={"center"}>
                <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
                  <img
                    src="./images/insurerlogo/kotak_general_insurance.svg"
                    alt=""
                    className="ins_logo"
                  />
                  <p className="planName">Pro health Protect Plan</p>
                </Grid>
                <Grid
                  xs={12}
                  marginBottom={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"12px"}
                >
                  <p className="p1">
                    Sum Insured <span>₹ 10 Lakhs</span>
                  </p>
                  <p className="p1" style={{ textAlign: "right" }}>
                    Coverage for <span>2 Adults and 1 Child</span>
                  </p>
                </Grid>
                <Grid xs textAlign={"center"}>
                  <CustomButton
                    className="primaryBtn small"
                    text={
                      <>
                        <ReplayCircleFilledIcon /> Renew Policy
                      </>
                    }
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                  <p className="policy_status">
                    Status <span className="expired">expired</span>
                  </p>
                  <Box
                    className="mt-4"
                    display={"flex"}
                    gap={"8px"}
                    justifyContent={"center"}
                  >
                    <Link className="grey_link">
                      {" "}
                      <CloudDownloadIcon />
                      Download
                    </Link>
                    <Link
                      className="grey_link"
                      onClick={() => setOpenPolicyDetailPopup(true)}
                    >
                      <VisibilityIcon />
                      View
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* end  */}

            {/* motor policy box  */}
            <Box className="policy_box_m">
              <Grid container columnSpacing={3} alignItems={"center"}>
                <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
                  <img
                    src="./images/insurerlogo/kotak_general_insurance.svg"
                    alt=""
                    className="ins_logo"
                  />
                </Grid>
                <Grid
                  xs={12}
                  marginBottom={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"12px"}
                >
                  <p className="p1">
                    Car Number <span>PB10BH4541</span>
                  </p>
                  <p className="p1" style={{ textAlign: "right" }}>
                    Hyundai Creta <span>Diesel, 1.6 SX (o) 1582 cc</span>
                  </p>
                </Grid>
                <Grid xs textAlign={"center"}>
                  <CustomButton
                    className="primaryBtn small"
                    text={
                      <>
                        <ReplayCircleFilledIcon /> Renew Policy
                      </>
                    }
                    onClick={() => {}}
                    fullWidth={false}
                    variant="contained"
                  />
                  <p className="policy_status">
                    Status <span className="active">Active</span>
                    (40 Days left)
                  </p>
                  <Box
                    className="mt-4"
                    display={"flex"}
                    gap={"8px"}
                    justifyContent={"center"}
                  >
                    <Link className="grey_link">
                      {" "}
                      <CloudDownloadIcon />
                      Download
                    </Link>
                    <Link
                      className="grey_link"
                      // onClick={() => setOpenPolicyDetailPopup(true)}
                    >
                      <VisibilityIcon />
                      View
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* end  */}

            {/* travel policy box */}
            <Box className="policy_box_m">
              <Grid container columnSpacing={3} alignItems={"center"}>
                <Grid xs={12} textAlign={"center"} marginBottom={"20px"}>
                  <img
                    src="./images/insurerlogo/kotak_general_insurance.svg"
                    alt=""
                    className="ins_logo"
                  />
                  <p className="planName">Travel Plan</p>
                </Grid>
                <Grid
                  xs={12}
                  marginBottom={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"12px"}
                >
                  <p className="p1">
                    Plan Type <span>Student</span>
                  </p>
                  <p className="p1" style={{ textAlign: "right" }}>
                    Frequency <span>Multi Trip</span>
                  </p>
                </Grid>
                <Grid
                  xs={12}
                  marginBottom={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"12px"}
                >
                  <p className="p1">
                    Start Date <span>15/03/2023</span>
                  </p>
                  <p className="p1" style={{ textAlign: "right" }}>
                    Duration <span>150 Days</span>
                  </p>
                </Grid>
                <Grid
                  xs={12}
                  marginBottom={"20px"}
                  display={"flex"}
                  justifyContent={"space-between"}
                  gap={"12px"}
                >
                  <p className="p1">
                    Travellers <span>4</span>
                  </p>
                </Grid>
                <Grid xs textAlign={"center"}>
                  <p className="policy_status">
                    Status <span className="active">Active</span>
                    (40 Days left)
                  </p>
                  <Box
                    className="mt-4"
                    display={"flex"}
                    gap={"8px"}
                    justifyContent={"center"}
                  >
                    <Link className="grey_link">
                      {" "}
                      <CloudDownloadIcon />
                      Download
                    </Link>
                    <Link
                      className="grey_link"
                      // onClick={() => setOpenPolicyDetailPopup(true)}
                    >
                      <VisibilityIcon />
                      View
                    </Link>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            {/* end  */}
          </Box>
        </Grid>
        {/* end  */}
      </Grid>
    </Box>
  );
};

export default MDashboard;
