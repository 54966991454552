import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Link } from "@mui/material";
import "./MMyAccount.scss";
import { COMMON_ROUTES } from "../../../Routing/Path/CommonRoutes";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
import MDashboard from "./MDashboard/MDashboard";
import MAddVehicle from "./MAddVehicle/MAddVehicle";
import MProfile from "./MProfile/MProfile";
import MFeedback from "./MFeedback/MFeedback";

const MMyAccount = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<
    "dashboard" | "addVehicle" | "profile" | "feedback"
  >("dashboard");
  const [transparentBg, setTransparentBg] = useState(true);
  const [scrollPosition, setScrollPosition] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const position = window.pageYOffset;
      setScrollPosition(position);
      if (position > 80) {
        setTransparentBg(false);
      } else {
        setTransparentBg(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  console.log("transparentBg", window);

  return (
    <Box className="myAccountWrapperMobile">
      <Box className={`header-sec ${transparentBg ? "" : "addShadow"}`}>
        <Grid container columnSpacing={3}>
          <Grid xs={8}>
            <Link
              onClick={() => {
                navigate(COMMON_ROUTES.HOME);
              }}
              sx={{ display: "inline-block", height: "30px" }}
            >
              <img src="./images/unison_logo.svg" height="30px" />
            </Link>
          </Grid>
          <Grid xs={4} textAlign={"right"}>
            <Link className="logout_btn">
              <svg
                width="17"
                height="14"
                viewBox="0 0 17 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.45388 14C3.43715 14 0.181152 10.8661 0.181152 7C0.181152 3.1339 3.43715 1.9728e-06 7.45388 1.9728e-06C8.58308 -0.00081455 9.69693 0.251843 10.7069 0.737901C11.7169 1.22396 12.5952 1.93002 13.2721 2.8H11.3012C10.4613 2.08723 9.42564 1.62285 8.3184 1.46258C7.21116 1.30231 6.07938 1.45296 5.05889 1.89645C4.0384 2.33994 3.17253 3.05743 2.5652 3.96283C1.95787 4.86823 1.63488 5.92307 1.63497 7.00078C1.63506 8.07848 1.95824 9.13327 2.56573 10.0386C3.17322 10.9439 4.0392 11.6612 5.05977 12.1046C6.08034 12.5479 7.21214 12.6983 8.31936 12.5379C9.42657 12.3774 10.4622 11.9129 11.3019 11.2H13.2728C12.5959 12.0701 11.7174 12.7762 10.7073 13.2623C9.6972 13.7483 8.58321 14.0009 7.45388 14ZM12.5448 9.8V7.7H6.72661V6.3H12.5448V4.2L16.1812 7L12.5448 9.8Z"
                  fill="white"
                />
              </svg>
            </Link>
          </Grid>
        </Grid>
      </Box>
      <Box className="tabContent-sec">
        {activeTab === "dashboard" ? (
          <MDashboard />
        ) : activeTab === "addVehicle" ? (
          <MAddVehicle />
        ) : activeTab === "profile" ? (
          <MProfile />
        ) : activeTab === "feedback" ? (
          <MFeedback />
        ) : null}
      </Box>
      <Box className="footer-nav">
        <ul>
          <li>
            <Link
              className={activeTab === "dashboard" ? "active" : ""}
              onClick={() => setActiveTab("dashboard")}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.83333 19.0417C5.32917 19.0417 4.89772 18.8624 4.539 18.5037C4.17967 18.1443 4 17.7126 4 17.2084V8.95841C4 8.66813 4.06508 8.39313 4.19525 8.13341C4.32481 7.87369 4.50417 7.6598 4.73333 7.49174L10.2333 3.36674C10.4014 3.24452 10.5771 3.15285 10.7604 3.09174C10.9437 3.03063 11.1347 3.00008 11.3333 3.00008C11.5319 3.00008 11.7229 3.03063 11.9062 3.09174C12.0896 3.15285 12.2653 3.24452 12.4333 3.36674L17.9333 7.49174C18.1625 7.6598 18.3422 7.87369 18.4723 8.13341C18.6019 8.39313 18.6667 8.66813 18.6667 8.95841V17.2084C18.6667 17.7126 18.4873 18.1443 18.1286 18.5037C17.7692 18.8624 17.3375 19.0417 16.8333 19.0417H13.1667V12.6251H9.5V19.0417H5.83333Z"
                  fill="#24488C"
                />
              </svg>
              Home
            </Link>
          </li>
          <li>
            <Link
              className={activeTab === "addVehicle" ? "active" : ""}
              onClick={() => setActiveTab("addVehicle")}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.6665 5.5L13.7498 6.1875L16.0415 9.16667H12.3748V7.79167H10.9998V9.16667H2.74984C1.73234 9.16667 0.916504 9.9825 0.916504 11V13.75H2.74984C2.74984 14.4793 3.03957 15.1788 3.55529 15.6945C4.07102 16.2103 4.77049 16.5 5.49984 16.5C6.22918 16.5 6.92866 16.2103 7.44438 15.6945C7.96011 15.1788 8.24984 14.4793 8.24984 13.75H13.7498C13.7498 14.4793 14.0396 15.1788 14.5553 15.6945C15.071 16.2103 15.7705 16.5 16.4998 16.5C17.2292 16.5 17.9287 16.2103 18.4444 15.6945C18.9601 15.1788 19.2498 14.4793 19.2498 13.75H21.0832V11C21.0832 9.9825 20.2673 9.16667 19.2498 9.16667H17.4165L14.6665 5.5ZM5.49984 12.375C5.86451 12.375 6.21425 12.5199 6.47211 12.7777C6.72997 13.0356 6.87484 13.3853 6.87484 13.75C6.87484 14.1147 6.72997 14.4644 6.47211 14.7223C6.21425 14.9801 5.86451 15.125 5.49984 15.125C5.13516 15.125 4.78543 14.9801 4.52757 14.7223C4.2697 14.4644 4.12484 14.1147 4.12484 13.75C4.12484 13.3853 4.2697 13.0356 4.52757 12.7777C4.78543 12.5199 5.13516 12.375 5.49984 12.375ZM16.4998 12.375C16.8645 12.375 17.2142 12.5199 17.4721 12.7777C17.73 13.0356 17.8748 13.3853 17.8748 13.75C17.8748 14.1147 17.73 14.4644 17.4721 14.7223C17.2142 14.9801 16.8645 15.125 16.4998 15.125C16.1352 15.125 15.7854 14.9801 15.5276 14.7223C15.2697 14.4644 15.1248 14.1147 15.1248 13.75C15.1248 13.3853 15.2697 13.0356 15.5276 12.7777C15.7854 12.5199 16.1352 12.375 16.4998 12.375Z"
                  fill="#24488C"
                />
              </svg>
              Add Vehicle
            </Link>
          </li>
          <li>
            <Link
              className={activeTab === "profile" ? "active" : ""}
              onClick={() => setActiveTab("profile")}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.1665 12.8333C8.40261 12.8333 7.75331 12.566 7.21859 12.0312C6.68386 11.4965 6.4165 10.8472 6.4165 10.0833C6.4165 9.74721 6.46998 9.43005 6.57692 9.13182C6.68387 8.83421 6.84428 8.56318 7.05817 8.31874C6.99706 8.16596 6.95123 8.00555 6.92067 7.83749C6.89012 7.66943 6.87484 7.50138 6.87484 7.33332C6.87484 6.75277 7.03128 6.23699 7.34417 5.78599C7.65767 5.3356 8.0665 5.00346 8.57067 4.78957C8.87623 4.43818 9.23525 4.16318 9.64775 3.96457C10.0603 3.76596 10.5109 3.66666 10.9998 3.66666C11.4887 3.66666 11.9394 3.76596 12.3519 3.96457C12.7644 4.16318 13.1234 4.43818 13.429 4.78957C13.9332 5.00346 14.342 5.3356 14.6555 5.78599C14.9684 6.23699 15.1248 6.75277 15.1248 7.33332C15.1248 7.50138 15.1096 7.66943 15.079 7.83749C15.0484 8.00555 15.0026 8.16596 14.9415 8.31874C15.1554 8.56318 15.3158 8.83421 15.4228 9.13182C15.5297 9.43005 15.5832 9.74721 15.5832 10.0833C15.5832 10.8472 15.3158 11.4965 14.7811 12.0312C14.2464 12.566 13.5971 12.8333 12.8332 12.8333H9.1665ZM5.49984 20.1667C4.99567 20.1667 4.56423 19.9873 4.2055 19.6286C3.84617 19.2692 3.6665 18.8375 3.6665 18.3333V17.6C3.6665 17.0805 3.80034 16.6033 4.068 16.1682C4.33506 15.7324 4.69011 15.4 5.13317 15.1708C6.08039 14.6972 7.04289 14.3419 8.02067 14.1047C8.99845 13.8682 9.9915 13.75 10.9998 13.75C12.0082 13.75 13.0012 13.8682 13.979 14.1047C14.9568 14.3419 15.9193 14.6972 16.8665 15.1708C17.3096 15.4 17.6646 15.7324 17.9317 16.1682C18.1993 16.6033 18.3332 17.0805 18.3332 17.6V18.3333C18.3332 18.8375 18.1538 19.2692 17.7951 19.6286C17.4358 19.9873 17.004 20.1667 16.4998 20.1667H5.49984Z"
                  fill="#24488C"
                />
              </svg>
              Profile
            </Link>
          </li>
          <li>
            <Link
              className={activeTab === "feedback" ? "active" : ""}
              onClick={() => setActiveTab("feedback")}
            >
              <svg
                width="22"
                height="22"
                viewBox="0 0 20 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 0H2C0.9 0 0.00999999 0.825 0.00999999 1.83333L0 18.3333L4 14.6667H18C19.1 14.6667 20 13.8417 20 12.8333V1.83333C20 0.825 19.1 0 18 0ZM11 11H9V9.16667H11V11ZM11 7.33333H9V3.66667H11V7.33333Z"
                  fill="#24488C"
                />
              </svg>
              Feedback
            </Link>
          </li>
        </ul>
      </Box>
    </Box>
  );
};

export default MMyAccount;
