import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box } from "@mui/material";
import "./CmsPages.scss";
import Navbar from "../../../Components/Desktop/Navbar/Navbar";
import Footer from "../../../Components/Desktop/Footer/Footer";

function RefundCancellations() {
  return (
    <Box className="CmsPagesWrapper">
      <Navbar />

      <div className="cms_wrapper">
        <Grid container spacing={3}>
          <Grid xs={12}>
            <div className="cms_header mb-20">
              <h2 className="mb-5">Refund & Cancellation Policy</h2>
              <p className="content_p mb-5">
                At Unison Insurance Brokers, our goal is to provide top-notch
                customer service and ensure your complete satisfaction with our
                insurance products. This Refund and Cancellation Policy details
                the procedures for requesting refunds and cancellations for
                insurance policies bought through our online platform,
                www.unisoninsurance.net. By using our platform and purchasing
                insurance, you agree to adhere to the terms and conditions
                described in this policy.
              </p>

              <ol className="olList">
                <li>
                  Policy Cancellation
                  <p>
                    <span>1.1 Cooling-Off Period:</span> Our insurance policies
                    typically include a cooling-off period, which allows you to
                    cancel your policy within a specified timeframe without
                    facing penalties. The length of this cooling-off period
                    depends on the insurance type and local regulations. For
                    exact details, please review your policy documentation or
                    contact our customer support team.
                  </p>
                  <p>
                    <span>1.2 Cancellation After Cooling-Off Period:</span> If
                    you decide to cancel your policy after the cooling-off
                    period has ended, please get in touch with our customer
                    support team. Cancellations beyond this period will be
                    processed according to the insurance provider’s terms and
                    may be subject to cancellation fees or other charges.
                  </p>
                </li>
                <li>
                  Refunds
                  <p>
                    <span>2.1 Refunds Within Cooling-Off Period:</span> If you
                    cancel your policy during the cooling-off period and qualify
                    for a refund, we will process the refund without delay.
                    Refunds will be made to the original payment method used at
                    the time of purchase.
                  </p>
                  <p>
                    <span>2.2 Refunds Post Cooling-Off Period:</span> Requests
                    for refunds made after the cooling-off period will be
                    handled based on the insurance provider’s policies. Refunds
                    may not be available or might be prorated according to the
                    duration of coverage.
                  </p>
                </li>
                <li>
                  How to Request a Refund or Cancellation
                  <p>
                    <span>3.1 Start the Process:</span> To request a refund or
                    cancel your policy, follow these steps:
                  </p>
                  <p>
                    <span>3.2 Contact Us:</span> Reach out to our customer
                    support team via phone or email, providing your policy
                    number and the reason for your cancellation or refund
                    request.
                  </p>
                  <p>
                    <span>3.3 Verification:</span> Our support team will review
                    your policy details and determine your eligibility for a
                    refund or cancellation based on the terms and conditions of
                    your policy.
                  </p>
                  <p>
                    <span>3.4 Processing:</span> If you are eligible, we will
                    proceed with the refund or cancellation in accordance with
                    the insurance provider’s guidelines and relevant
                    regulations.
                  </p>
                </li>
                <li>
                  Policy Changes
                  <p>
                    Changes to your policy, such as adjustments to coverage,
                    beneficiaries, or policy duration, may be possible depending
                    on the type of insurance and the provider’s rules. For
                    assistance with modifying your policy, please contact our
                    customer support team.
                  </p>
                </li>
                <li>
                  Contact Information
                  <p>
                    For any inquiries or assistance regarding our Refund and
                    Cancellation Policy or any other insurance-related matters,
                    please contact us at:
                  </p>
                  <p>
                    <span>Unison Insurance Brokers Pvt. Ltd.</span>
                  </p>
                  <p>
                    Corporate Office: Unison Insurance Broking Services Pvt.
                    Ltd. Off. No. 404, Glacier Complex, Jetalpur Road, Next to
                    Pizza In, Jetalpur Road, Vadodara, Gujarat - 390007
                  </p>
                  <p>Phone: 0731-6644200</p>
                  <p>
                    Thank you for choosing Unison Insurance Brokers. We are here
                    to offer exceptional service and support throughout your
                    insurance experience.
                  </p>
                </li>
              </ol>
            </div>
          </Grid>
        </Grid>
      </div>

      <Footer />
    </Box>
  );
}

export default RefundCancellations;
