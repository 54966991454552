import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";

function MAboutus() {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12}>
          <div className="cms_header text-center">
            <h2>Who we are</h2>
          </div>
        </Grid>
        <Grid xs={12} className="mb-3">
          <div className="cms_content_sec ">
            <p className="content_p mb-5">
              Established in 1998 when unorganised market used to thrive for
              insurance solutions a team of expert and experienced insurance
              professionals got together to solve the pertinent market gap of
              trustworthy & professional corporate insurance solutions, which
              lead to the birth of UNISON Insurance. Over the years, through our
              deep client relations & market wisdom, we have scaled business
              across 12 major cities & 350+ insurance Professionals.Today,
              Unison Insurance is one of the leading Direct Insurance and
              Reinsurance Broker & Risk Management Advisor providing insurance
              product and services to corporate customers across varied sectors.
              Our expert team acts as sourcing representative of clients, who
              seek varied insurance product requirements to protect their risk
              exposure. Unison prides on its ability to deliver best and unique
              products and services to multinationals, large corporates, small &
              medium enterprises.Along with young, energetic & experienced
              insurance professionals, UNISON offers the vibrancy & flexibility
              that is the need of the insurance industry. Our team comes with a
              "“can- do- it” attitude, which makes no task too difficult to
              achieve. The passion for utility based yet ingenious product
              design & unparalleled customer service is what significantly
              differentiates us from the competition. Our clientele spans across
              MNCs, Corporate Magnates, SMEs & more. And yes, we pride on timely
              delivery of quality services at optimum cost!
            </p>
            <div className="text-center">
              <img
                src="./images/aboutus_img_desktop.svg"
                alt=""
                className="img-fluid"
              />
            </div>
          </div>
        </Grid>
        <Grid xs={12} className="mb-3">
          <div className="vision_mission">
            <h4>Visions</h4>
            <p>
              To remain your consistent first choice of broker. After all, in
              our world, you are always our top priority.
            </p>
            <img
              src="./images/vision_desktop_img.svg"
              alt=""
              className="vision_img"
            />
          </div>
          <div className="vision_mission mission_section">
            <h4>Mission</h4>
            <ul>
              <li>
                To create a passionate, credible and creative service
                organisation, which will build long-term, mutually beneficial
                relations with business partners.
              </li>
              <li>
                Provide a platform for talented people to flourish and deliver
                expertise and personalised services to the clients.
              </li>
              <li>
                Provide unparalleled insurance solutions through qualitative and
                innovative products, and unmatched service solutions.
              </li>
            </ul>
            <img
              src="./images/mission_desktop_img.svg"
              alt=""
              className="mission_img"
            />
          </div>
          <div className="values_section">
            <h4>Values</h4>
            <ul>
              <li>
                <span>Customer Centric -</span> We believe in delivering the
                best with a more precise attention to achieve the highest levels
                of customer satisfaction and delight.
              </li>
              <li>
                <span> Innovative -</span> We strive and challenge the
                conventional methods of operations with a “can do approach”.
              </li>
              <li>
                <span>Teamwork -</span> We endeavour to bring together people
                from different experiences and expertise to provide seamless and
                exceptional services.
              </li>
              <li>
                <span> Transparency -</span> We conduct our business in an open,
                fair and honest way and take ownership of our results.
              </li>
              <li>
                <span> Integrity -</span> We don’t do what comes easy, we do
                what is right.
              </li>
            </ul>
            <div className="text-center">
              <img src="./images/values_desktop_img.svg" alt="" />
            </div>
          </div>
        </Grid>

        <Grid xs={12} className="mb-3">
          <div className="philosophy_section">
            <h4 className="text-center">Our Work Philosophy</h4>

            {/* triangle section start */}
            <div className="philosophy_triangle">
              <div className="philosophy_triangle_inner">
                <div className="upper_part cyan mr-3">
                  <h6>
                    Policy Stress <br /> Testing
                  </h6>
                </div>
                <div className="lower_triangle">
                  <span className="triangle1"></span>
                </div>
              </div>

              <div className="philosophy_triangle_inner">
                <div className="upper_part red mr-3">
                  <h6>
                    Risk Identification <br /> and Risk <br /> Assessment
                  </h6>
                </div>
                <div className="lower_triangle_red">
                  <span className="triangle2"></span>
                </div>
              </div>

              <div className="philosophy_triangle_inner">
                <div className="upper_part yellow">
                  <h6>
                    Design Customised <br /> Solutions
                  </h6>
                </div>
                <div className="lower_triangle_yellow">
                  <span className="triangle3"></span>
                </div>
              </div>
            </div>

            <div className="philosophy_triangle">
              <div className="philosophy_triangle_inner second_triangle">
                <div className="upper_triangle">
                  <span className="triangle4"></span>
                </div>
                <div className="lower_part orange mr-3">
                  <h6>
                    Placement of
                    <br /> Risk
                  </h6>
                </div>
              </div>
              <div className="philosophy_triangle_inner second_triangle">
                <div className="upper_triangle_blue">
                  <span className="triangle5"></span>
                </div>
                <div className="lower_part blue">
                  <h6>
                    Claim <br /> Servicing
                  </h6>
                </div>
              </div>
            </div>

            {/* triangle section end */}

            <h5>
              It's Not Just What We Do, It's How We Do It That Sets Us Apart.
            </h5>
            <ol>
              <li>
                <span>Policy Stress Testing -</span> We investigate Loss
                Scenarios, including Mitigation Options and Insurance Coverage,
                to identify your risk exposures and help you craft suitable
                policy options.
              </li>
              <li>
                <span>Risk Identification and Risk Assessment - </span> Our team
                of experts conduct a comprehensive Risk Assessment review to
                create an accurate risk profile and provide advice on risk
                retention and risk transfer solutions based on the client's Risk
                Management philosophy. We ensure that there are no gaps in the
                client's coverage.
              </li>
              <li>
                <span>Design Customised Solutions -</span> As each client
                differs, our priority is to understand your business, listen to
                your problems and solve them with Innovative and Customized
                Insurance Programmes.
              </li>
              <li>
                <span>Placement of Risk -</span> Based on our relationship and
                market experience, we can obtain ideal terms from
                domestic/international markets to suit our client's needs.
              </li>
              <li>
                <span>Claims Servicing -</span> We look at claims servicing as
                the essence of being the "Broker of Choice" for our clients. Our
                involvement begins from the appointment of the surveyor to
                Claims Settlement.
              </li>
            </ol>
          </div>
        </Grid>

        <Grid xs={12} className="mb-3">
          <div className="corporate_governance">
            <h4>Corporate Governance</h4>
            <p>
              Every company is based on certain robust principles which guides
              it towards its destination. Corporate Governance is one such
              exercise which helps in paving the way for an honest and ethical
              work environment. Also, it creates a sense of confidence among the
              working partners, collaborators and clients regarding the company
              and its practices. UNISON is one of the only Insurance Broking
              firms that has taken the initiative to appoint an international
              entity namely Touche Tohmatsu India LLP, as an internal auditor
              and BSR & Associates LLP as a statutory auditor. These steps are
              taken to enforce our customers’ faith in the working of UNISON and
              bring about the necessary streamlining in our processes.
            </p>
            <div className="board_director">
              <h6>Board of directors -</h6>
              <p>
                Any company is established with the intent to contribute and be
                an agent in the upliftment of the community it works in. Even
                after establishment, the company is guided by a set of
                individuals to steer in the right direction, the set of
                individuals being namely, the Board of Directors. UNISON has
                been navigated by this group, keeping in mind the objectives of
                the company.
              </p>
            </div>
            <div className="corporate_human">
              <div className="corporate_human_inner">
                <span className="human1">Biraj Kumar Sinha</span>
              </div>
              <div className="corporate_human_inner">
                <span className="human2">Amitesh Sinha</span>
              </div>
              <div className="corporate_human_inner">
                <span className="human1">Parveen Kumar</span>
              </div>
            </div>
            <div className="corporate_human">
              <div className="corporate_human_inner">
                <span className="human1">Naresh Kumar Singha</span>
              </div>
              <div className="corporate_human_inner">
                <span className="human3">Anuj Kishore Raizada</span>
              </div>
              <div className="corporate_human_inner">
                <span className="human4">Sneha Shah</span>
              </div>
            </div>
            <div className="stress_testing">
              <h6>Policy Stress testing</h6>
              <ul>
                <li>
                  Corporate Social Responsibility and Sustainability Policy
                </li>
                <li>Fraud Prevention Policy</li>
                <li>Fraud Prevention Procedure</li>
                <li>
                  Policy on Prevention of Sexual Harassment of Women at
                  workplace
                </li>
                <li>Whistle Blower Policy</li>
                <li>Risk Management Policy</li>
                <li>Audit Committee</li>
              </ul>
            </div>
          </div>
        </Grid>

        <Grid xs={12} className="mb-3">
          <div className="our_journey">
            <h4>Our Journey</h4>
            <p>
              UNISON was started with the belief and conviction, that there are
              always better options available in the market and the client
              should be aware of the same. UNISON has been working to create the
              right conditions for these changes to take place, right from
              inception of the firm till date.
            </p>
            <Grid container spacing={3} className="px-0">
              <Grid xs={6} className="our_journey_section border">
                <div className="our_journey_inner">
                  <h5>2003</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={6} className="our_journey_section">
                <div className="our_journey_inner">
                  <h5>2007</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={6} className="our_journey_section border">
                <div className="our_journey_inner">
                  <h5>2011</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={6} className="our_journey_section">
                <div className="our_journey_inner">
                  <h5>2012</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={6} className="our_journey_section border">
                <div className="our_journey_inner">
                  <h5>2013</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={6} className="our_journey_section">
                <div className="our_journey_inner">
                  <h5>2014</h5>
                  <p>
                    Opening of surat Branch & obtained License to operate as a
                    Direct Insurance Broker
                  </p>
                </div>
              </Grid>
              <Grid xs={12} className="textCenter">
                <Link className="view_more">View More</Link>
              </Grid>
            </Grid>
          </div>
        </Grid>

        <Grid xs={12} className="mb-3">
          <div className="corporate_social_sec">
            <h4>Corporate Social Responsibility</h4>
            <p>
              Corporate Social Responsibility & Sustainability are essential
              Components of Corporate Governance. The emerging concept of CSR &
              Sustainability goes beyond charity and requires the company to act
              beyond its legal obligations and to integrate social,
              environmental and ethical concerns into company’s business
              process. We at UNISON at large undertake social engagements useful
              for welfare & sustainable development of the community
              specifically the deprived, under privileged and differently abled
              persons. We firmly believe to give back to the society and
              contribute in their sustainable development. UNISON through its
              CSR & Sustainability initiatives will endeavour to achieve the
              below- mentioned key Value behind CSR & Sustainability Policy:
            </p>
          </div>
        </Grid>

        <Grid xs={12} className="mb-3">
          <div className="our_people">
            <h4>Our people</h4>
            <p>
              We know well that 'PEOPLE' constitute the absolute core of our
              business. Maybe that is the reason we have some of the best
              talents this industry has ever seen. We invest significantly in
              our resource training and skill enhancement, which again makes the
              company stand out in this competitive space.
            </p>
            <h6>Key Highligths</h6>
            <ul>
              <li>Average Age of Our Personnel: 38 Years</li>
              <li>55% Qualified Insurance Professionals</li>
              <li>30+ Engineers & 40+ Management Graduates</li>
              <li>Risk Management Division with 10 Qualified Risk Engineers</li>
              
            </ul>
            <p className="mb-0">
              This team has designed bespoke products for the most complex
              operational & construction risks with assistance from global A+
              rated reinsurance companies. Our customers are endowed with
              ever-expanding portfolio of innovative products, local
              underwriting expertise and unparalleled services.
            </p>
            <p className="mb-0">
              UNISON Insurance specialises in Risk Management, Insurance
              Placement Programme and Claims Management. At UNISON Insurance,
              on-job training to employees provides with itself ample
              opportunities under meticulous guidance to shape their skillsets,
              which yields enhanced performance and value creation for the
              organisation.
            </p>
            <p className="mb-0">
              Our belief is that we don't sell insurance 'products', but build
              long lasting customer relations; through passion, business
              understanding, innovation, quick turnaround time and most
              important of all, Customer Delight.
            </p>
          </div>
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MAboutus;
