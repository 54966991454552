import React, { useState } from "react";
import Grid from "@mui/material/Unstable_Grid2";
import { Box, Button, Link } from "@mui/material";
import "./CmsPagesMobile.scss";
import Footer from "../../../Components/Mobile/Footer/MFooter";
import Navbar from "../../../Components/Mobile/Navbar/MNavbar";
import RKTextField from "../../../Components/InputFields/RKTextField/RKTextField";
import TextArea from "../../../Components/InputFields/TextArea/TextArea";
import CustomButton from "../../../Components/InputFields/CustomButton/CustomButton";
function MComplaint() {
  const [contactFields, setContactFields] = useState<{
    name: { value: string; warning: boolean };
    mobile: { value: string; warning: boolean };
    email: { value: string; warning: boolean };
    description: { value: string; warning: boolean };
  }>({
    name: { value: "", warning: false },
    mobile: { value: "", warning: false },
    email: { value: "", warning: false },
    description: { value: "", warning: false },
  });

  const fieldsUpdateState = (attrName: any, value: any) => {
    attrName[3]({
      ...attrName[2],
      [attrName[0]]: { ...[attrName[0]], [attrName[1]]: value },
    });
  };
  return (
    <Box className="CmsPagesWrapper_M">
      <Navbar />

      {/* header  */}
      <Grid container spacing={3}>
        <Grid xs={12} className="mb-6">
          <div className="cms_header text-center">
            <h2 className="mb-2">File a Complaint</h2>
            <p>Please enter details. We want to hear from you!</p>
          </div>
        </Grid>
        <Grid xs={12} className="mb-6 text-center">
          <img
            src="./images/complaint.svg"
            alt=""
            className="img-fluid"
            style={{ maxHeight: "173px" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Full Name"}
            value={contactFields.name.value}
            attrName={["name", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.name.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Mobile"}
            value={contactFields.mobile.value}
            attrName={["mobile", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.mobile.warning}
          />
        </Grid>
        <Grid xs={12}>
          <RKTextField
            class_name="inputField"
            title={"Email"}
            value={contactFields.email.value}
            attrName={["email", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.email.warning}
          />
        </Grid>
        <Grid xs={12}>
          <TextArea
            class_name="inputField"
            title={"What are you facing problems?"}
            value={contactFields.description.value}
            placeholder={"Enter description here"}
            attrName={["description", "value", contactFields, setContactFields]}
            value_update={fieldsUpdateState}
            warn_status={contactFields.description.warning}
          />
        </Grid>
        <Grid xs={12}>
          <CustomButton
            className="primaryBtn disabled"
            text={"Submit Details"}
            variant={"text"}
            fullWidth={false}
          />
        </Grid>
      </Grid>

      {/* footer section */}
      <Footer />
    </Box>
  );
}

export default MComplaint;
